import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useRef,
} from "react";
import { useAxiosPrivate } from "../../Hooks/useAxiosPrivate";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import SomethingWentWrong from "../../Components/SomethingWentWrong";
import fino from "../../Assets/Images/fino.png";
import icici from "../../Assets/Images/icici.png";
import yesbankimg from "../../Assets/Images/yesbank.png";
import { dateFormated, formatCountValue, formatValue } from "../../Utils";
import DateRangePicker from "../../Components/DateRangePicker";
import { format, parse } from "date-fns";
import TextField from "@mui/material/TextField";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import InputAdornment from "@mui/material/InputAdornment";
// import DatePicker from "@mui/lab/DatePicker";
// import DatePicker from "react-datepicker";
import { useAuth } from "../../Hooks/useAuth";
import NoData from "../../Components/NoData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import DateIcon from "../../Assets/Images/date.png";
import Broadcast from "../../Components/Icons/BroadCast";
import Overview from "./overview";
import DevTable from "../../Components/DevTable";
const InfoBox = ({
  successCountTitle,
  successValCount,
  faildCountTitle,
  faildValCount,
  pendingCountTitle,
  pendingValCount,
  loading,
  img,
  successAmountTitle,
  successAmount,
  faildAmountTitle,
  faildAmount,
  pendingAmountTitle,
  pendingAmount,
  successRatio,
  timeoutAmount,
  timeoutCount,
}) => {
  return (
    <Box component="div" className="BBPDPInfoBox">
      <Box component="div" className="SuccessRatioContainer">
        <img src={img} className="BBPDPIBSValImg" />

        {/* {loading ? <Skeleton variant="rounded" height={32} />  : <p>Success Ratio :  %</p>} */}
        <p>Success Ratio : {successRatio}%</p>
      </Box>
      <Box component="div" className="BBPDPIB">
        <Box component="div" className="newBox" bgcolor={"#9BECAD"}>
          <Box component="div" className="newBoxTitle">
            <p>Successful </p>
            <p>Count/Amount </p>
          </Box>
          <Box component="div" className="newBoxValue">

            <p>{loading ? (
              <Skeleton variant="rounded" height={32} />
            ) : (
              successValCount
            )}</p>
            <p>{loading ? (
              <Skeleton variant="rounded" height={32} />
            ) : (
              successAmount
            )}</p>

          </Box>

        </Box>
        <Box component="div" className="newBox" bgcolor={"#FFB2B2"}>
          <Box component="div" className="newBoxTitle">
            <p>Failed </p>
            <p>Count/Amount </p>
          </Box>
          <Box component="div" className="newBoxValue">

            <p>{loading ? (
              <Skeleton variant="rounded" height={32} />
            ) : (
              faildValCount
            )}</p>
            <p>{loading ? (
              <Skeleton variant="rounded" height={32} />
            ) : (
              faildAmount
            )}</p>

          </Box>

        </Box>
        <Box component="div" className="newBox" bgcolor={"#F9FBAF"}>
          <Box component="div" className="newBoxTitle">
            <p>Pending</p>
            <p>Count/Amount</p>
          </Box>
          <Box component="div" className="newBoxValue">

            <p>{loading ? (
              <Skeleton variant="rounded" height={32} />
            ) : (
              pendingValCount
            )}</p>
            <p>{loading ? (
              <Skeleton variant="rounded" height={32} />
            ) : (
              pendingAmount
            )}</p>

          </Box>

        </Box>
        <Box component="div" className="newBox" bgcolor={"#FBFF5F"}>
          <Box component="div" className="newBoxTitle">
            <p>Timeout </p>
            <p>Count/Amount </p>
          </Box>
          <Box component="div" className="newBoxValue">

            <p>{loading ? (
              <Skeleton variant="rounded" height={32} />
            ) : (
              timeoutCount
            )}</p>
            <p>{loading ? (
              <Skeleton variant="rounded" height={32} />
            ) : (
              timeoutAmount
            )}</p>

          </Box>

        </Box>
      </Box>
    </Box>
  );
};

const InfoBoxMain = ({ type }) => {
  const axiosPrivate = useAxiosPrivate();
  const [selectedDate, setSelectedDate] = useState(dateFormated(new Date()));
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState({});
  const [apiParams] = useState({ tranDate: format(new Date(), "yyyy-MM-dd") });
  const { auth } = useAuth();
  const [showNoData, setShowNoData] = useState(false);
  const [merchantList, setMerchantList] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  function hasPartnerRole(roles) {
    return roles.includes("Partner");
  }
  // function transformData(data) {
  //   try {
  //     console.log(data);

  //     // Define the list of all possible banks
  //     // const banks = ["FINO", "PAYSPRINT", "ICICI", "YESBANK", "SBI", "RBL", "AXIS"];

  //     // Initialize the result array
  //     const result = [];

  //     // Iterate over each bank in the list
  //     for (const bank of data) {
  //       // Retrieve the details for the current bank from the data object
  //       const details = data[bank] || {
  //         TS: { amount: 0, count: 0 },
  //         TF: { amount: 0, count: 0 },
  //         TI: { amount: 0, count: 0 },
  //         TO: { amount: 0, count: 0 },
  //       };
  //       console.log(details)
  //       // Push the result for the current bank
  //       result.push({
  //         bank: bank,
  //         successAmount: details?.TS?.amount || 0,
  //         successCount: details?.TS?.count || 0,
  //         failedAmount: details?.TF?.amount || 0,
  //         failedCount: details?.TF?.count || 0,
  //         pendingAmount: details?.TI?.amount || 0,
  //         pendingCount: details?.TI?.count || 0,
  //         timeoutAmount: details?.TO?.amount || 0,
  //         timeoutCount: details?.TO?.count || 0,
  //         ratio: details?.ratio || 0
  //       });
  //     }

  //     console.log(result);
  //     return result;
  //   } catch (err) {
  //     console.error("Error transforming data:", err);
  //     return [];
  //   }
  // }
  function transformData(data) {
    try {
  
      // Check if data is an object
      if (typeof data !== 'object' || data === null) {
        throw new TypeError("Input data should be a non-null object");
      }
  
      // Initialize the result array
      const result = [];
  
      // Iterate over each bank in the data object
      for (const bank in data) {
        if (data.hasOwnProperty(bank)) {
          // Retrieve the details for the current bank from the data object
          const details = data[bank] || {
            TS: { amount: 0, count: 0 },
            TF: { amount: 0, count: 0 },
            TI: { amount: 0, count: 0 },
            TO: { amount: 0, count: 0 },
          };
  
          // Push the result for the current bank
          result.push({
            bank: bank,
            successAmount: details?.TS?.amount || 0,
            successCount: details?.TS?.count || 0,
            failedAmount: details?.TF?.amount || 0,
            failedCount: details?.TF?.count || 0,
            pendingAmount: details?.TI?.amount || 0,
            pendingCount: details?.TI?.count || 0,
            timeoutAmount: details?.TO?.amount || 0,
            timeoutCount: details?.TO?.count || 0,
            ratio: details.ratio || 0
          });
        }
      }
      return result;
    } catch (err) {
      console.error("Error transforming data:", err);
      return [];
    }
  }
  

  const fetchData = useCallback(async (params) => {
    try {
      setLoading(true);
      let urlParams = "";
      if (params) {
        Object.keys(params).forEach(function (key, index) {
          urlParams += (index === 0 ? "?" : "&") + key + "=" + params[key];
        });
      }
      // let url =
      //   type === "Merchant"
      //     ? `dashboard/get-merchant-fin-txn-stats${urlParams}`
      //     : `dashboard/get-partner-fin-txn-stats${urlParams}`;
      let url = hasPartnerRole(auth.role)
        ? `dashboard/get-partner-fin-txn-stats${urlParams}`
        : `/dashboard/get-merchant-fin-txn-stats${urlParams}`;
      let options = {
        method: "POST",
        url,
      };
      await axiosPrivate(options)
        .then((response) => {
          if (response.data.statusCode === 1) {
            setReportData(response.data.data);

            setShowNoData(false);

            setRowBankData(transformData(response.data.data))
            setTotalCount(calculateTotalAmounts(response.data.data))
          } else {
            setErrorDialog(true);
            // console.error("err.res", response.data);
            setShowNoData(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          if (err.response) {
            setErrorDialog(true);
            setLoading(false);
            setReportData({});
            console.error("err.res", err.response.data);
            setShowNoData(true);
          }
        });
    } catch (error) {
      setErrorDialog(true);
      setLoading(false);
      setShowNoData(true);
      console.error("error", error);
    }
  }, []);

  const fetchMerchantList = useCallback(async () => {
    try {
      setLoading(true);
      let url = "user/get-partner-payees";
      let options = {
        method: "GET",
        url,
      };
      await axiosPrivate(options)
        .then((response) => {
          if (response.data.statusCode === 1) {
            setMerchantList(response.data.data);
            setShowNoData(false);
          } else {
            setErrorDialog(true);
            console.error("err.res", response.data);
            // setShowNoData(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            setErrorDialog(true);
            setLoading(false);
            // setShowNoData(true);
            console.error("error", error);
          }
        });
    } catch (error) {
      setErrorDialog(true);
      setLoading(false);
      // setShowNoData(true);
      console.error("error", error);
    }
  }, []);

  useEffect(() => {
    fetchData(apiParams);
    handleGetBroadcast();
  }, [type]);

  const getImageKey = (key) => {
    if (key === "FINO") {
      return fino;
    } else if (key === "YESBANK") {
      return yesbankimg;
    } else if (key === "ICICI"){
      return icici;
    }
    else {
      return "";
    }
  };

  const handleSelectedDate = (e) => {
    setSelectedDate(format(new Date(e), "dd-MMM-yyyy"));
    let prm = apiParams;
    prm.tranDate = format(new Date(e), "yyyy-MM-dd");
    fetchData(prm);
  };

  const handleRefresh = () => {
    fetchData(apiParams);
  };

  const datePickerRef = useRef(null);

  const handleImageClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true); // Open the date picker programmatically
    }
  };

  const [showBroadCastMsg, setShowBroadcastMsg] = useState(false);
  const [broadcastMessage, setBroadcastMessage] = useState([]);

  const handleGetBroadcast = useCallback(async () => {
    try {
      setLoading(true);
      let url = "/broadcast/get-current-broadcasts";
      let options = {
        method: "POST",
        url,
      };
      await axiosPrivate(options)
        .then((response) => {
          if (response.data.statusCode === 1) {
            setShowBroadcastMsg(true);
            setBroadcastMessage(response.data.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log(error)
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      //console.log(error)
    }
  }, []);
  const [totalCount, setTotalCount] = useState();
  const [rowDataBank, setRowBankData] = useState();
  function calculateTotalAmounts(data) {
    let totalAmounts = {
      TS: 0,
      TF: 0,
      TI: 0,
      TO: 0
    };
    try {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          totalAmounts.TS += data[key]?.TS?.amount;
          totalAmounts.TF += data[key]?.TF?.amount;
          totalAmounts.TI += data[key]?.TI?.amount || 0;
          totalAmounts.TO += data[key]?.TO?.amount;
        }
      }
      return totalAmounts;
    } catch (err) {
      console.log(err)
    }
  }

  const [mainColumnsBank, setMainColumnsBank] = useState({
    columns: [
      {
        name: "bank",
        title: "Bank",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "successAmount",
        title: "Success Amount ",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "successCount",
        title: "Success Count",
        width: 50,
        sorting: false,
        direction: "",
      },
      {
        name: "failedAmount",
        title: "Failed Amount",
        width: 50,
        sorting: false,
        direction: "",
      },
      {
        name: "failedCount",
        title: "Failed Count",
        width: 50,
        sorting: false,
        direction: "",
      },
      {
        name: "pendingAmount",
        title: "Pending Amount ",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "pendingCount",
        title: "Pending Count",
        width: 50,
        sorting: false,
        direction: "",
      },
      {
        name: "timeoutAmount",
        title: "Timeout Amount",
        width: 50,
        sorting: false,
        direction: "",
      },
      {
        name: "timeoutCount",
        title: "TimeCount Count",
        width: 50,
        sorting: false,
        direction: "",
      },
      {
        name: "ratio",
        title: "Success Ratio",
        width: 50,
        sorting: false,
        direction: "",
      }

    ],
  });
  function generateRowsBank(tempArray) {
    const tempRowArray = [];
    if (tempArray) {
      tempArray.map((item) =>
        tempRowArray.push({
          bank: (
            <Box component="div" className="BBPDTSText" >
              <img className='bankIcon' src={getImageKey(item.bank)} />
            </Box>
          ),
          successAmount: (
            <Box component="div" className="BBPDTSText BBPDTSTextMultiple" >
              <span>{item.successAmount ? item.successAmount.toLocaleString() : "0"}</span>
              <span>{item.successAmount ? formatValue(item.successAmount) : "0"}</span>
            </Box>
          ),
          successCount: (
            <Box component="div" className="BBPDTSText BBPDTSTextMultiple">
              <span>{item.successCount ? item.successCount.toLocaleString() : "0"}</span>
              <span>{item.successCount ? formatCountValue(item.successCount) : "0"}</span>
            </Box>
          ),
          failedAmount: (
            <Box component="div" className="BBPDTSText BBPDTSTextMultiple">
              <span>{item.failedAmount ? item.failedAmount.toLocaleString() : "0"}</span>
              <span>{item.failedAmount ? formatValue(item.failedAmount) : "0"}</span>
            </Box>
          ),
          failedCount: (
            <Box component="div" className="BBPDTSText BBPDTSTextMultiple">
              <span>{item.failedCount ? item.failedCount.toLocaleString() : "0"}</span>
              <span>{item.failedCount ? formatCountValue(item.failedCount) : "0"}</span>
            </Box>
          ),
          pendingAmount: (
            <Box component="div" className="BBPDTSText BBPDTSTextMultiple">
              <span>{item.pendingAmount ? item.pendingAmount.toLocaleString() : "0"}</span>
              <span>{item.pendingAmount ? formatValue(item.pendingAmount) : "0"}</span>
            </Box>
          ),
          pendingCount: (
            <Box component="div" className="BBPDTSText BBPDTSTextMultiple">
              <span>{item.pendingCount ? item.pendingCount.toLocaleString() : "0"}</span>
              <span>{item.pendingCount ? formatCountValue(item.pendingCount) : "0"}</span>
            </Box>
          ),
          timeoutCount: (
            <Box component="div" className="BBPDTSText BBPDTSTextMultiple">
              <span>{item.timeoutCount ? item.timeoutCount.toLocaleString() : "0"}</span>
              <span>{item.timeoutCount ? formatCountValue(item.timeoutCount) : "0"}</span>
            </Box>
          ),
          timeoutAmount: (
            <Box component="div" className="BBPDTSText BBPDTSTextMultiple">
              <span> {item.timeoutAmount ? item.timeoutAmount.toLocaleString() : "0"}</span>
              <span> {item.timeoutAmount ? formatValue(item.timeoutAmount) : "0"}</span>
            </Box>
          ),
          ratio: (
            <Box component="div" className="BBPDTSText BBPDTSTextMultiple">
              <span> {item.ratio ? item.ratio + "%" : "0"}</span>

            </Box>
          ),

        })
      );
    }
    return tempRowArray;
  }

  return (
    <Fragment>
      {/* <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} /> */}

      {showBroadCastMsg &&
        broadcastMessage.map((item, index) => (
          <Box component="div" className="ContentHeading" >
            <Box className="BMContainer" key={index}>
              <Broadcast />
              <p>{item.message}</p>
            </Box>
          </Box>
        ))}

      <Box component="div" className="ContentHeading">
        <p style={{ marginBottom: "0rem", margin: "0rem" }}>
          Welcome, {auth.name} !
        </p>
        <Box component="div" className="dateHeadingContent">
          <p id="blueP">Data as of {selectedDate} </p>
          {/* <CalendarMonthOutlinedIcon className="calenderIcon"/> */}

          <Box className="calenderDiv">
            <DatePicker
              ref={datePickerRef}
              selected={selectedDate}
              onChange={handleSelectedDate}
              dateFormat="dd/MM/yyyy"
              className="calenderIcon"
            />
          </Box>
          <img
            src={DateIcon}
            className="imgDateIcon"
            onClick={handleImageClick}
          />
          <Box
            component="div"
            className="dateHeadingContent"
            onClick={handleRefresh}
          >
            <p id="bluePRefresh">Refresh</p>
            <AutorenewOutlinedIcon style={{ color: "#175783" }} />
          </Box>
        </Box>
      </Box>

      <Overview loading={loading} data={totalCount} />
      <Box component="div" className={'BBPDPTitle'} style={{ marginTop: "0.5rem" }}>
        Bank Transactions
      </Box>
      {/* <Box component="div" className={"BBPTransaction"}> */}
        <Box component="div" style={{ height: "100%"}}>
          <DevTable
            rows={generateRowsBank(rowDataBank)}
            columns={mainColumnsBank.columns}
            loading={loading}
          />
        {/* </Box> */}
      </Box>

      {/* {loading ? (
        <Skeleton variant="rounded" height={350} />
      ) : showNoData ? (
        <NoData title={"No Data"} description={"no data found"} />
      ) : (
        !showNoData && (
          <Box component="div" className="BBPDPInfoBoxMain">
            {Object.keys(reportData).map((key) => (
              <>
                <InfoBox
                  key={key}
                  successRatio={reportData[key]?.ratio}
                  successCountTitle={"Successful Count"}
                  successValCount={reportData[key]?.TS?.count.toLocaleString() || 0}
                  faildCountTitle={"Failed Count "}
                  faildValCount={reportData[key]?.TF?.count.toLocaleString() || 0}
                  pendingCountTitle={"Pending Count"}
                  pendingValCount={reportData[key]?.TI?.count.toLocaleString() || 0}
                  timeoutCount={reportData[key]?.TO?.count.toLocaleString() || 0}
                  loading={loading}
                  img={getImageKey(key)}
                  successAmountTitle={"Successful Amount"}
                  successAmount={reportData[key]?.TS?.amount.toLocaleString(
                    undefined,
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  ) || 0}
                  faildAmountTitle={"Failed Amount"}
                  faildAmount={reportData[key]?.TF?.amount.toLocaleString(
                    undefined,
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  ) || 0}
                  pendingAmountTitle={"Pending Amount"}
                  pendingAmount={reportData[key]?.TI?.amount.toLocaleString(
                    undefined,
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  ) || 0}
                  timeoutAmount={reportData[key]?.TO?.amount.toLocaleString(
                    undefined,
                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                  ) || 0}
                />
              </>
            ))}
          </Box>
        )
      )} */}
    </Fragment>
  );
};

export default InfoBoxMain;
