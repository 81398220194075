import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Logo from '../Logo';
import UserDropdown from '../UserDropdown';
import LogoImg from '../../Assets/Images/logopaymnt1.png';
import './style.css';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useAuth } from '../../Hooks/useAuth';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import { CheckPermissions } from '../../Utils/permissions';
const MenuBar = () => {
    const [loading, setLoading] = useState(true);
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [walletBalance, setWalletBalance] = useState();
    const fetchWalletBalance = useCallback(async () => {
        try {
            const data = {
                PHONE: auth.username,
                CHECKSUM: ""
            }
            setLoading(true);
            let url = `/payout/api/v1/balance/vacc`;
            let options = {
                method: "POST",
                url,
                data
            };
            await axiosPrivate(options).then((response) => {
                if (response.status == 200) {
                    if (response.data.STATUS == "SUCCESS") {
                        setWalletBalance(response.data.DATA);
                    }
                }
            }).catch((err) => {
                setLoading(false);
            })

        } catch (error) {

        }
    }, [])
    function hasPartnerRole(roles) {
        return roles.includes("Partner");
    }
    // console.log(CheckPermissions("payout_permissions", "Balance"))
    const isPayOut = CheckPermissions("payout_permissions", "Balance")
    useEffect(() => {
        console.log(auth.role)
        if (!hasPartnerRole(auth.role) ) {
            if (isPayOut) {
                fetchWalletBalance()
            }
        }
    }, [])
    return (
        <Box component="div" className={'BBPMenuBar'}>
            <Box component="div" className={'BBPMInner'}>
                <Logo logo={LogoImg} />
                <Box component="div" className={'BBPMNU'}>
                    <Box component="div" className={'BBPMNUInnner'}>
                        {CheckPermissions("payout_permissions", "Balance") &&
                            <Box component="div" className={'BBPWallet'}>
                                <AccountBalanceWalletIcon style={{ color: "#175783", fontSize: "medium" }} />
                                <p style={{ margin: "0px 0px", fontSize: "12px", color: "#175783" }}>₹ {walletBalance?.availableBalance}</p>
                                <RemoveRedEyeIcon style={{ color: "#175783", fontSize: "medium" }} />
                            </Box>}
                        <UserDropdown />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default MenuBar;