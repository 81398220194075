// import React, { Fragment, useState } from 'react';
// import Box from '@mui/material/Box';
// import { Button, FormControl, MenuItem, Select, TextField } from '@mui/material';

// const AddBeneficiary = () => {
//     const [loading, setLoading] = useState(false);
//     const [paymentMethod, setPaymentMethod] = useState("UPI");
//     const [formValues, setFormValues] = useState({
//         beneficiaryName: "",
//         transactionType: "",
//         payerVpa: "",
//         accountNumber: "",
//         ifscCode: "",
//         mobileNumber: "",
//         amount: "",
//         remarks: ""
//     });
//     const [formErrors, setFormErrors] = useState({});

//     const handlePaymentMethodChange = (e) => {
//         setPaymentMethod(e.target.value);
//         setFormErrors({});
//     }

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setFormValues({ ...formValues, [name]: value });
//         setFormErrors({ ...formErrors, [name]: "" });
//     };

//     const validate = () => {
//         let errors = {};
//         if (!formValues.beneficiaryName) {
//             errors.beneficiaryName = "Beneficiary Name is required";
//         }
//         if (paymentMethod !== "IMPS" && !formValues.transactionType) {
//             errors.transactionType = "Transaction Type is required";
//         }
//         if (paymentMethod === "UPI" && !formValues.payerVpa) {
//             errors.payerVpa = "Payer VPA is required for UPI";
//         }
//         if (paymentMethod !== "UPI") {
//             if (!formValues.accountNumber) {
//                 errors.accountNumber = "Account Number is required";
//             }
//             if (!formValues.ifscCode) {
//                 errors.ifscCode = "IFSC Code is required";
//             }
//         }
//         if (!formValues.mobileNumber) {
//             errors.mobileNumber = "Mobile Number is required";
//         }
//         if (!formValues.amount) {
//             errors.amount = "Amount is required";
//         }
//         if (paymentMethod === "RTGS" && !formValues.remarks) {
//             errors.remarks = "Remarks are required for RTGS";
//         }
//         return errors;
//     }

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         const errors = validate();
//         if (Object.keys(errors).length === 0) {
//             // Submit form data
//             console.log("Form submitted successfully", formValues);
//         } else {
//             setFormErrors(errors);
//         }
//     };
//     return (
//         <Fragment>
//             <Box component="div" className={"BBPSPTView"} style={{ background: "#fff" }}>
//                 <Box component="div" className={"BBPSPTVForm"}>
//                     <form onSubmit={handleSubmit}>
//                         <Box>
//                             {/* <Box style={{ width: "100%", padding: "0rem 1rem" }}>
//                                 <Box style={{ display: "flex", gap: "1rem" }}>
//                                     <Box className='BBPCPLFORMItem'>
//                                         <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
//                                             <label>Transfer Type</label>
//                                             <Select
//                                                 value={paymentMethod}
//                                                 sx={{ width: 200 }}
//                                                 onChange={handlePaymentMethodChange}
//                                             >
//                                                 <MenuItem value={"UPI"}>UPI</MenuItem>
//                                                 <MenuItem value={"IMPS"}>IMPS</MenuItem>
//                                                 <MenuItem value={"RTGS"}>RTGS</MenuItem>
//                                                 <MenuItem value={"NEFT"}>NEFT</MenuItem>
//                                             </Select>
//                                         </FormControl>
//                                     </Box>

//                                     <Box className='BBPCPLFORMItem'>
//                                         <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
//                                             <label>Transaction Type</label>
//                                             <Select
//                                                 name="transactionType"
//                                                 value={formValues.transactionType}
//                                                 onChange={handleInputChange}
//                                                 error={!!formErrors.transactionType}
//                                                 sx={{ width: 200 }}
//                                             >
//                                                 <MenuItem value={10}>Merchant to Person</MenuItem>
//                                             </Select>
//                                             {formErrors.transactionType && (
//                                                 <p style={{ color: 'red', margin: '0', fontSize: '0.75rem' }}>
//                                                     {formErrors.transactionType}
//                                                 </p>
//                                             )}
//                                         </FormControl>
//                                     </Box>

//                                 </Box>
//                             </Box> */}

//                             <Box className="BBPSPFGrid">
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label> Name</label>
//                                     <TextField
//                                         name="beneficiaryName"
//                                         variant="outlined"
//                                         value={formValues.beneficiaryName}
//                                         onChange={handleInputChange}
//                                         error={!!formErrors.beneficiaryName}
//                                         helperText={formErrors.beneficiaryName}
//                                         InputProps={{
//                                             style: { height: 40, width: 200 }
//                                         }}
//                                     />
//                                 </Box>
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>Mobile No</label>
//                                     <TextField
//                                         name="payerVpa"
//                                         variant="outlined"
//                                         value={formValues.payerVpa}
//                                         onChange={handleInputChange}
//                                         error={!!formErrors.payerVpa}
//                                         helperText={formErrors.payerVpa}
//                                         InputProps={{
//                                             style: { height: 40, width: 200 }
//                                         }}
//                                     />
//                                 </Box>
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>Account No</label>
//                                     <TextField
//                                         name="mobileNumber"
//                                         variant="outlined"
//                                         value={formValues.mobileNumber}
//                                         onChange={handleInputChange}
//                                         error={!!formErrors.mobileNumber}
//                                         helperText={formErrors.mobileNumber}
//                                         InputProps={{
//                                             style: { height: 40, width: 200 }
//                                         }}
//                                     />
//                                 </Box>
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>IFSC</label>
//                                     <TextField
//                                         name="amount"
//                                         variant="outlined"
//                                         value={formValues.amount}
//                                         onChange={handleInputChange}
//                                         error={!!formErrors.amount}
//                                         helperText={formErrors.amount}
//                                         InputProps={{
//                                             style: { height: 40, width: 200 }
//                                         }}
//                                     />
//                                 </Box>
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>Mobile Number</label>
//                                     <TextField
//                                         name="mobileNumber"
//                                         variant="outlined"
//                                         value={formValues.mobileNumber}
//                                         onChange={handleInputChange}
//                                         error={!!formErrors.mobileNumber}
//                                         helperText={formErrors.mobileNumber}
//                                         InputProps={{
//                                             style: { height: 40, width: 200 }
//                                         }}
//                                     />
//                                 </Box>
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>Email</label>
//                                     <TextField
//                                         name="amount"
//                                         variant="outlined"
//                                         value={formValues.amount}
//                                         onChange={handleInputChange}
//                                         error={!!formErrors.amount}
//                                         helperText={formErrors.amount}
//                                         InputProps={{
//                                             style: { height: 40, width: 200 }
//                                         }}
//                                     />
//                                 </Box>
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>City</label>
//                                     <TextField
//                                         name="mobileNumber"
//                                         variant="outlined"
//                                         value={formValues.mobileNumber}
//                                         onChange={handleInputChange}
//                                         error={!!formErrors.mobileNumber}
//                                         helperText={formErrors.mobileNumber}
//                                         InputProps={{
//                                             style: { height: 40, width: 200 }
//                                         }}
//                                     />
//                                 </Box>
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>State</label>
//                                     <TextField
//                                         name="amount"
//                                         variant="outlined"
//                                         value={formValues.amount}
//                                         onChange={handleInputChange}
//                                         error={!!formErrors.amount}
//                                         helperText={formErrors.amount}
//                                         InputProps={{
//                                             style: { height: 40, width: 200 }
//                                         }}
//                                     />
//                                 </Box>
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>Pincode</label>
//                                     <TextField
//                                         name="mobileNumber"
//                                         variant="outlined"
//                                         value={formValues.mobileNumber}
//                                         onChange={handleInputChange}
//                                         error={!!formErrors.mobileNumber}
//                                         helperText={formErrors.mobileNumber}
//                                         InputProps={{
//                                             style: { height: 40, width: 200 }
//                                         }}
//                                     />
//                                 </Box>
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>Phone</label>
//                                     <TextField
//                                         name="amount"
//                                         variant="outlined"
//                                         value={formValues.amount}
//                                         onChange={handleInputChange}
//                                         error={!!formErrors.amount}
//                                         helperText={formErrors.amount}
//                                         InputProps={{
//                                             style: { height: 40, width: 200 }
//                                         }}
//                                     />
//                                 </Box>
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>OTP</label>
//                                     <TextField
//                                         name="mobileNumber"
//                                         variant="outlined"
//                                         value={formValues.mobileNumber}
//                                         onChange={handleInputChange}
//                                         error={!!formErrors.mobileNumber}
//                                         helperText={formErrors.mobileNumber}
//                                         InputProps={{
//                                             style: { height: 40, width: 200 }
//                                         }}
//                                     />
//                                 </Box>
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>Checksum</label>
//                                     <TextField
//                                         name="amount"
//                                         variant="outlined"
//                                         value={formValues.amount}
//                                         onChange={handleInputChange}
//                                         error={!!formErrors.amount}
//                                         helperText={formErrors.amount}
//                                         InputProps={{
//                                             style: { height: 40, width: 200 }
//                                         }}
//                                     />
//                                 </Box>
//                             </Box>
//                         </Box>

//                         <Box className="BBPPayoutBtnCont">
//                             <Button variant="contained" className={""}>
//                                 Cancel
//                             </Button>
//                             <Button variant="contained" type="submit" className={""}>
//                                 Submit
//                             </Button>
//                         </Box>
//                     </form>
//                 </Box>
//             </Box>
//         </Fragment>
//     )
// }

// export default AddBeneficiary
import React, { useState, useCallback, useEffect, Fragment, useRef } from 'react';
import Box from '@mui/material/Box';
import validator from "@rjsf/validator-ajv6";
import Form from "@rjsf/core";
import Button from '@mui/material/Button';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import { useAuth } from '../../Hooks/useAuth';
import toast from 'react-hot-toast';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const AddBeneficiary = () => {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const schema = {
        "type": "object",
        "required": ["NAME", "MOBILE", "ACCOUNT", "IFSC", "EMAIL", "ADDRESS", "CITY", "STATE", "PINCODE"],
        "properties": {
            "NAME": {
                "title": "Name",
                "type": "string",
                "format": "string"
            },
            "MOBILE": {
                "title": "Mobile",
                "type": "string"
            },
            "ACCOUNT": {
                "title": "Account No",
                "type": "string",
                "format": "string"
            },
            "IFSC": {
                "title": "IFSC",
                "type": "string",
                "minLength": 11,
            },

            "EMAIL": {
                "title": "Email",
                "type": "string",
                "format": "string"
            },
            "ADDRESS": {
                "title": "Address",
                "type": "string",
                "format": "string"
            },
            "CITY": {
                "title": "City",
                "type": "string"
            },
            "STATE": {
                "title": "State",
                "type": "string",
                "format": "string"
            },
            "PINCODE": {
                "title": "Pin Code",
                "type": "string"
            },
            // "PHONE": {
            //     "title": "Phone",
            //     "type": "string"
            // },
            // "OTP": {
            //     "title": "Otp",
            //     "type": "string",
            //     "format": "string"
            // },
            // "CHECKSUM": {
            //     "title": "CHECKSUM",
            //     "type": "string"
            // }
        }
    };
    const uiSchema = {
        "ui:submitButtonOptions": {
            "props": {
                "className": "BBPFBtn"
            }
        },
        "NAME": {
            "ui:placeholder": "Enter Name"
        },
        "MOBILE": {
            "ui:placeholder": "Enter Mobile "
        },
        "ACCOUNT": {
            "ui:placeholder": "Enter Account No"
        },
        "IFSC": {
            "ui:placeholder": "Enter IFSC No "
        },
        "EMAIL": {
            "ui:placeholder": "Enter Email"
        },
        "CITY": {
            "ui:placeholder": "Enter City "
        },
        "STATE": {
            "ui:placeholder": "Enter State"
        },
        "PINCODE": {
            "ui:placeholder": "Enter Pincode"
        },
        // "PHONE": {
        //     "ui:placeholder": "Enter Phone"
        // },
        // "OTP": {
        //     "ui:placeholder": "Enter Otp"
        // },
        "ADDRESS": {
            "ui:placeholder": "Enter Address"
        }
    };
    const [formData, setFormData] = useState({});
    const [extraErrors, setExtraErrors] = useState({});
    let yourForm;
    const onSubmitNew = () => {
        yourForm.formElement.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    };

    const onFormSubmit = (formData) => {
        formData.OTP = ""
        formData.CHECKSUM = "";
        formData.PHONE = auth.username;
        console.log(formData);
        setFormData(formData);
        setDialogOpen(true)
        // handleAddBeneficiary(formData);
    };
    // function customValidate(formData, errors) {
    //     if (formData.IFSC.minLength < 11 ) {
    //         errors.IFSC.addError("Enter a valid IFSC code.");
    //     }
    //     return errors;
    // }
    // const handleAddBeneficiary = useCallback(async (data) => {
    //     try {
    //         console.log(data)
    //         let url = `/payout/api/v1/bene/add`;
    //         let options = {
    //             method: "POST",
    //             url,
    //             data
    //         };
    //         await axiosPrivate(options)
    //             .then((response) => {
    //                 console.log(response.data)
    //                 if (response.data.STATUS === "SUCCESS") {
    //                     toast.success("Beneficiary Added Successfully .")
    //                     setFormData({})
    //                 } else {
    //                     toast.error("Invalid Information .")
    //                     setFormData({})
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err)
    //                 toast.error("Invalid Information .")
    //             });
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }, [])

    const [dialogOpen, setDialogOpen] = useState(false);

    const onClose = () => {
        setDialogOpen(false)
    }

    const onSubmitForm = async () => {
        // handleAddBeneficiary(formData);
        try {
            console.log(formData)
            let url = `/payout/api/v1/bene/add`;
            let options = {
                method: "POST",
                url,
                data: formData
            };
            await axiosPrivate(options)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.STATUS === "SUCCESS") {
                        toast.success("Beneficiary Added Successfully .")
                        setFormData({})
                    } else {
                        toast.error("Invalid Information .")
                        setFormData({})
                    }
                    setDialogOpen(false)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error("Invalid Information .")
                    setDialogOpen(false)
                });
        } catch (error) {
            console.log(error)
            setDialogOpen(false)
        }
    }
    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={dialogOpen}
                onClose={onClose} >

                <Box component='div' className={'BBPAlertDialog'}>

                    <Box className="merchantDialogCont">
                        <p onClick={onClose}><CloseIcon /></p>

                    </Box>
                    <Box className="BBPMInfo">
                        <p> NAME : {formData.NAME}</p>
                        <p> MOBILE : {formData.MOBILE}</p>
                        <p> Account : {formData.ACCOUNT}</p>
                        <p> IFSC : {formData.IFSC}</p>
                        <p> EMAIL : {formData.EMAIL}</p>
                        <p> CITY : {formData.CITY}</p>
                        <p> STATE : {formData.STATE}</p>
                        <p> PINCODE : {formData.PINCODE}</p>
                        <p> ADDRESS : {formData.ADDRESS}</p>
                        <Box className="button-container"  >
                            <Button onClick={onSubmitForm} >Submit</Button>
                        </Box>
                    </Box>


                </Box>

            </Dialog>
            <Box component="div" className={"BBPSPTView"} style={{ background: "#fff" }}>
                <Box component="div" className={"BBPSPTVForm"}>
                    <Box component="div" className={'BBPSPTVForm'}>
                        <Box component='div' className={'BBPForm'}>
                            <Form
                                schema={schema}
                                uiSchema={uiSchema}
                                formData={formData}
                                validator={validator}
                                // customValidate={customValidate}
                                showErrorList={false}
                                omitExtraData={true}
                                onSubmit={({ formData }) => {
                                    onFormSubmit(formData);
                                }}
                                ref={(form) => { yourForm = form; }}
                            />
                        </Box>
                    </Box>
                    <Box component="div" className={'BBPSPTVBtn'}>
                        <Button variant="contained" className={'BBPButton'} onClick={onSubmitNew}>Submit</Button>
                    </Box>
                </Box>
            </Box>
        </>);
};

export default AddBeneficiary;
