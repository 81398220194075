import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import BBPAreaChart from './areaChart';
import { formatValue } from '../../Utils';



const Overview = ({ data, loading }) => {
    return (
        <Fragment>
            <Box component="div" className={'BBPDPTitle'}>
                Today’s Transaction
            </Box>
            <Box component="div" className={'BBPDPCharts'}>
                <BBPAreaChart
                    apiPrm={'getTotalSuccess'}
                    color={"#82ca9d"}
                    name={'getTotalSuccess'}
                    title={'Success'}
                    itemKey={'total'}
                    isAmt={true}
                    percentage_text={'Than Last 30 Days'}
                    loading={loading}
                    data={data}
                    amount={formatValue(data?.TS)}
                />
                <BBPAreaChart
                    apiPrm={'getTotalFailed'}
                    color={"#201EAA"}
                    name={'getTotalFailed'}
                    title={'Failed'}
                    itemKey={'total'}
                    isAmt={true}
                    percentage_text={'Than Last 30 Days'}
                    loading={loading}
                    data={data}
                    amount={formatValue(data?.TF)}

                />
                <BBPAreaChart
                    apiPrm={'getTotalPending'}
                    color={"#12e1e3"}
                    name={'getTotalPending'}
                    title={'Pending'}
                    itemKey={'total'}
                    isAmt={true}
                    percentage_text={'Than Last 30 Days'}
                    loading={loading}
                    data={data}
                    amount={formatValue(data?.TI)}
                />
                <BBPAreaChart
                    apiPrm={'getTotalRefunded'}
                    color={"#ff9800"}
                    name={'getTotalRefunded'}
                    title={'Timeout'}
                    itemKey={'total'}
                    isAmt={true}
                    percentage_text={'Than Last 30 Days'}
                    loading={loading}
                    data={data}
                    amount={formatValue(data?.TO)}
                />
            </Box>
        </Fragment>
    );
};

export default Overview;
