import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useRef,
} from "react";
import { usePapaParse } from "react-papaparse";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SearchBox from "../../Components/SearchBox";
import DevTable from "../../Components/DevTable";
import SomethingWentWrong from "../../Components/SomethingWentWrong";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import Backdrops from "../../Components/Backdrops";
import { format, sub, parse } from "date-fns";
import DatePicker from "react-datepicker";
import DateIcon from "../../Assets/Images/date.png";
import { useAuth } from "../../Hooks/useAuth";
import { useAxiosPrivate } from "../../Hooks/useAxiosPrivate";
import fileIcon from "../../Assets/Images/fileUpload.png";
import pdfIcon from "../../Assets/Images/pdfIcon.png";
import { Label } from "recharts";
import toast from "react-hot-toast";
const Settlement = () => {
  const { jsonToCSV } = usePapaParse();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [limitData] = useState(20);
  const [onDownloadLoading, setOnDownloadLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    format(new Date(), "dd-MMM-yyyy")
  );
  const [rowData, setRowData] = useState([]);
  const [selectedSearchCat, setSelectedSearchCat] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [merchantList, setMerchantList] = useState([]);
  const [chageBackFileUp, setChargeBackFileUp] = useState();
  const [selectedInvId, setSelectedInvId] = useState();
  const [selectedRemark, setSelectedRemarkValue] = useState("");
  const [apiParams] = useState({
    pageSize: limitData,
    // tranDate: format(new Date(), "dd-MM-yyyy"),
    // tranDate: "2024-03-13",
  });
  const datePickerRef = useRef(null);
  const handleRemarkChange = (e) => {
    setSelectedRemarkValue(e.target.value);
  };
  const [mainColumns, setMainColumns] = useState({
    columns: [
      {
        name: "date",
        title: "Settlement Date",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "partnerId",
        title: "Partner ID",
        width: 50,
        sorting: false,
        direction: "",
      },
      {
        name: "merchantName",
        title: "Merchant Name",
        width: 50,
        sorting: false,
        direction: "",
      },
      {
        name: "vpa",
        title: "Payee VPA",
        width: 50,
        sorting: false,
        direction: "",
      },
      {
        name: "cycle",
        title: "Cycle",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "timeoutCount",
        title: "Timeout Count",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "timeoutVolume",
        title: "Timeout Volume",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "successCount",
        title: "Success Count",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "successVolume",
        title: "Success Volume",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "totalCount",
        title: "Total Count",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "totalVolume",
        title: "Total Volume",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "charges",
        title: "Charges",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "chargeBack",
        title: "Chargeback",
        width: 50,
        sorting: false,
        direction: "",
      },
      {
        name: "previousDayCreditAdjustment",
        title: "Prev Day Credit Adj",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "netSettlement",
        title: "Net Settlement",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "bankTransfer",
        title: "Transferred",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "fundReleased",
        title: "Fund Released",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "cutOff",
        title: "Cut Off",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "difference",
        title: "Difference",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "utr",
        title: "UTR No",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "remark",
        title: "Remarks",
        width: 150,
        sorting: false,
        direction: "",
      },
    ],
  });

  function hasPartnerRole(roles) {
    return roles.includes("Partner");
  }

  const fetchTransactions = useCallback(async (params, payee) => {
    try {
      setLoading(true);
      let urlParams = "";
      if (params) {
        Object.keys(params).forEach(function (key, index) {
          urlParams += (index === 0 ? "?" : "&") + key + "=" + params[key];
        });
      }

      let url = hasPartnerRole(auth.role)
        ? `/txn/get-partner-settlement-stmt${urlParams}`
        : `txn/get-merchant-settlement-stmt${urlParams}`;
      // let url = `txn/get-merchant-fino-recon-report${urlParams}`;
      let options = {
        method: "POST",
        url,
      };
      await axiosPrivate(options)
        .then((response) => {
          if (response.data.statusCode === 1) {
            setRowData(response.data.data.txnList);
            setTotalData(response.data.data.totalCount);
            setLoading(false);
          } else {
            setRowData([]);
            setTotalData(0);
            setLoading(false);
          }
          //   setDataStartDate(new Date(response.data.firstDate));
        })
        .catch((err) => {
          if (err.response) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('err.res', err.response.data);
          }
        });
    } catch (error) {
      setLoading(false);
      setErrorDialog(true);
      // console.error('error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const fetchMerchantList = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     let url = "user/get-partner-payees";
  //     let options = {
  //       method: "GET",
  //       url,
  //     };

  //     await axiosPrivate(options)
  //       .then((response) => {
  //         if (response.data.statusCode === 1) {
  //           setMerchantList(response.data.data);
  //           // setShowNoData(false);
  //         } else {
  //           setErrorDialog(true);
  //           console.error("err.res", response.data);
  //           // setShowNoData(true);
  //         }
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         if (error.response) {
  //           setErrorDialog(true);
  //           setLoading(false);
  //           // setShowNoData(true);
  //           console.error("error", error);
  //         }
  //       });
  //   } catch (error) {
  //     setErrorDialog(true);
  //     setLoading(false);
  //     // setShowNoData(true);
  //     console.error("error", error);
  //   }
  // }, []);
  const handleCategorySearch = (event) => {
    setSelectedSearchCat(event.target.value);
    setShowSearchBox(true);
  };
  const handleSearch = useCallback(
    (value) => {
      if (hasPartnerRole(auth.role)) {
        setLoading(true);
        let prm = apiParams;
        setSelectedPage(1);
        prm.pageNumber = 1;
        if (value.length > 0) {
          prm[selectedSearchCat] = value;
        } else {
          delete prm[selectedSearchCat];
        }
        fetchTransactions(prm, selectedMerchant);
        // delete prm[selectedSearchCat];
        // setShowSearchBox(false)
      } else {
        setLoading(true);
        let prm = apiParams;
        setSelectedPage(1);
        prm.pageNumber = 1;
        if (value.length > 0) {
          prm[selectedSearchCat] = value;
        } else {
          delete prm[selectedSearchCat];
        }
        fetchTransactions(prm);
        // delete prm[selectedSearchCat];
        // setShowSearchBox(false)
      }
    },
    [apiParams, fetchTransactions, selectedSearchCat]
  );

  const handlePaginate = (value) => {
    if (hasPartnerRole(auth.role)) {
      let prm = apiParams;
      prm.pageNumber = value;
      setSelectedPage(value);
      setLoading(true);
      fetchTransactions(prm, selectedMerchant);
    } else {
      let prm = apiParams;
      prm.pageNumber = value;
      setSelectedPage(value);
      setLoading(true);
      fetchTransactions(prm);
    }
  };

  const handleSorting = (name, index) => {
    let prm = apiParams;
    let tempCol = mainColumns.columns;
    if (tempCol[index].direction === "") {
      tempCol.map((column) =>
        column.name === name
          ? (column.direction = "ASC")
          : (column.direction = "")
      );
      prm.sort_by = name;
      prm.order = 1;
      prm.page = 1;
    } else if (tempCol[index].direction === "ASC") {
      tempCol.map((column) =>
        column.name === name
          ? (column.direction = "DSC")
          : (column.direction = "")
      );
      prm.sort_by = name;
      prm.order = -1;
      prm.page = 1;
    } else if (tempCol[index].direction === "DSC") {
      tempCol.map((column) => (column.direction = ""));
      prm.page = 1;
      delete prm.sort_by;
      delete prm.order;
    }
    setLoading(true);
    setMainColumns({ columns: tempCol });
    setSelectedPage(1);
    fetchTransactions(prm);
  };

  function generateRows(tempArray) {
    const tempRowArray = [];
    if (tempArray) {
      tempArray.map((item) =>
        tempRowArray.push({
          date: (
            <Box component="div" className="BBPDTSText">
              {item.date}
            </Box>
          ),
          partnerId: (
            <Box component="div" className="BBPDTSText">
              {item.partnerId}
            </Box>
          ),
          vpa: (
            <Box component="div" className="BBPDTSText">
              {item.vpa}
            </Box>
          ),
          merchantName: (
            <Box component="div" className="BBPDTSText">
              {item.merchantName}
            </Box>
          ),
          cycle: (
            <Box component="div" className="BBPDTSText">
              {item.cycle}
            </Box>
          ),
          timeoutCount: (
            <Box component="div" className="BBPDTSText">
              {item.timeoutCount.toLocaleString()|| 0}
            </Box>
          ),
          timeoutVolume: (
            <Box component="div" className="BBPDTSText">
              {item.timeoutVolume.toLocaleString()|| 0}
            </Box>
          ),
          successCount: (
            <Box component="div" className="BBPDTSText">
              {item.successCount.toLocaleString()|| 0}
            </Box>
          ),
          successVolume: (
            <Box component="div" className="BBPDTSText">
              {item.successVolume.toLocaleString()|| 0}
            </Box>
          ),
          totalCount: (
            <Box component="div" className="BBPDTSText">
              {item.totalCount.toLocaleString()|| 0}
            </Box>
          ),
          totalVolume: (
            <Box component="div" className="BBPDTSText">
              {item.totalVolume.toLocaleString()|| 0}
            </Box>
          ),
          charges: (
            <Box component="div" className="BBPDTSText">
              {item.charges.toLocaleString()|| 0}
            </Box>
          ),
          chargeBack: (
            <Box component="div" className="BBPDTSText">
              {item.chargeBack.toLocaleString()|| 0}
            </Box>
          ),
          previousDayCreditAdjustment: (
            <Box component="div" className="BBPDTSText">
              {item.previousDayCreditAdjustment.toLocaleString()|| 0}
            </Box>
          ),
          bankTransfer: (
            <Box component="div" className="BBPDTSText">
              {item.bankTransfer.toLocaleString()|| 0}
            </Box>
          ),
          netSettlement: (
            <Box component="div" className="BBPDTSText">
              {item.netSettlement.toLocaleString()|| 0}
            </Box>
          ),
          fundReleased: (
            <Box component="div" className="BBPDTSText">
              {item.fundReleased.toLocaleString()|| 0}
            </Box>
          ),
          cutOff: (
            <Box component="div" className="BBPDTSText">
              {item.cutOff.toLocaleString()|| 0}
            </Box>
          ),
          difference: (
            <Box component="div" className="BBPDTSText">
              {item.difference.toLocaleString()|| 0}
            </Box>
          ),
          remark: (
            <Box component="div" className="BBPDTSText">
              {item.remark}
            </Box>
          ),
          utr: (
            <Box component="div" className="BBPDTSText">
              {item.utr}
            </Box>
          ),
        })
      );
    }
    return tempRowArray;
  }
  const handleDownloadInvoice = useCallback(async (fileName) => {
    try {
      setOnDownloadLoading(true);
      let url = `/general/get-file/${fileName}`;
      let options = {
        method: "GET",
        url,
        responseType: "arraybuffer",
      };
      await axiosPrivate(options)
        .then((response) => {
          if (response.status === 200) {
            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setOnDownloadLoading(false);
          }
        })
        .catch(() => {
          setOnDownloadLoading(false);
        });
    } catch (error) {
      setErrorDialog(true);
      setOnDownloadLoading(false);
    }
  }, []);
  const handleCSVDownload = useCallback(
    async (payee) => {
      try {
        setOnDownloadLoading(true);
        let urlParams = "";
        if (apiParams) {
          delete apiParams.page;
          Object.keys(apiParams).forEach(function (key, index) {
            urlParams +=
              (index === 0 ? "?" : "&") +
              key +
              "=" +
              (key === "pageSize" ? totalData : apiParams[key]);
          });
        }

        // let url = `txn/get-merchant-fino-recon-report${urlParams}`;
        let url = hasPartnerRole(auth.role)
          ? payee
            ? `/txn/get-partner-settlement-stmt${urlParams}`
            : `/txn/get-partner-settlement-stmt${urlParams}`
          : `txn/get-merchant-settlement-stmt${urlParams}`;
        let options = {
          method: "POST",
          url,
        };
        await axiosPrivate(options)
          .then((response) => {
            if (response.data.statusCode === 1) {
              // setRowData(response.data.data.txnList);
              // setTotalData(response.data.total);
              const tempDownloadArr = [];
              const tempHeader = {};
              mainColumns.columns.map(
                (item) => (tempHeader[item.name] = item.title)
              );
              response.data.data.txnList.map((item) =>
                tempDownloadArr.push({
                  date: item.date,
                  merchantName: item.merchantName,
                  cycle: item.cycle,
                  totalVolume: item.totalVolume,
                  chargeBack: item.chargeBack,
                  netSettlement: item.netSettlement,
                  utr: item.utr,
                  partnerId: item.partnerId,
                  vpa: item.vpa,
                  timeoutCount: item.timeoutCount,
                  timeoutVolume: item.timeoutVolume,
                  successCount: item.successCount,
                  successVolume: item.successVolume,
                  totalCount: item.totalCount,
                  charges: item.charges,
                  previousDayCreditAdjustment: item.previousDayCreditAdjustment,
                  bankTransfer: item.bankTransfer,
                  fundReleased: item.fundReleased,
                  cutOff: item.cutOff,
                  difference: item.difference,
                  remark: item.remark
                })
              );
              tempDownloadArr.unshift(tempHeader);

              const csv = jsonToCSV(tempDownloadArr, { header: false });
              let filename = `${"settlement"}-${format(
                new Date(),
                "yyyy-MM-dd-HH:mm:ss"
              )}.csv`;
              var csvData = new Blob([csv], {
                type: "text/csv;charset=utf-8;",
              });
              var csvURL = null;
              if (navigator.msSaveBlob) {
                csvURL = navigator.msSaveBlob(csvData, filename);
              } else {
                csvURL = window.URL.createObjectURL(csvData);
              }
              var tempLink = document.createElement("a");
              tempLink.href = csvURL;
              tempLink.setAttribute("download", filename);
              tempLink.click();
              setOnDownloadLoading(false);
            } else {
              setRowData([]);
              setTotalData(0);
              setOnDownloadLoading(false);
            }
          })
          .catch((err) => {
            if (err.response) {
              setErrorDialog(true);
              setOnDownloadLoading(false);
              // console.error('err.res', err.response.data);
            }
          });
      } catch (error) {
        setErrorDialog(true);
        setOnDownloadLoading(false);
        // console.error('error', error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [totalData]
  );

  useEffect(() => {
    // eslint-disable-next-line
    if (hasPartnerRole(auth.role)) {
      fetchTransactions(apiParams);
    } else {
      fetchTransactions(apiParams);
    }
  }, []);
  const handleFindDate = (e) => {
    if (hasPartnerRole(auth.role)) {
      setSelectedDate(format(e, "dd-MMM-yyyy"));
      setSelectedPage(1);
      let prm = apiParams;
      prm.tranDate = format(e, "dd-MM-yyyy");
      prm.pageNumber = 1;
      fetchTransactions(prm, selectedMerchant);
    } else {
      setSelectedDate(format(e, "dd-MMM-yyyy"));
      setSelectedPage(1);
      let prm = apiParams;
      prm.tranDate = format(e, "dd-MM-yyyy");
      prm.pageNumber = 1;
      fetchTransactions(prm);
    }
  };
  const handleImageClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true); // Open the date picker programmatically
    }
  };

  // const handleMerchantChange = async (e) => {
  //   setSelectedMerchant(e.target.value);
  //   const parsedDate = parse(selectedDate, "dd-MMM-yyyy", new Date());
  //   let prm = apiParams;
  //   setSelectedPage(1);
  //   prm.pageNumber = 1;
  //   prm.tranDate = format(parsedDate, "dd-MM-yyyy");
  //   fetchTransactions(prm, e.target.value);
  // };
  const handleRefresh = () => {
    if (hasPartnerRole(auth.role)) {
      fetchTransactions(apiParams, selectedMerchant);
    } else {
      fetchTransactions(apiParams);
    }
  };

  // const handleLabelClick = () => {
  //   const input = document.getElementById('cbFileUpload');
  //   if (input) {
  //     input.click();
  //   }
  // };

  // const handleInputCBFile = useCallback(

  //   async (e) => {
  //     console.log("callback")
  //     try {
  //       setLoading(true);
  //       const data = {
  //         file: e.target.files[0],
  //       };
  //       let url = "/txn/upload-chargeback-report";
  //       let options = {
  //         method: "POST",
  //         url,
  //         data,
  //       };
  //       await axiosPrivate(options)
  //         .then(() => {})
  //         .catch((err) => {
  //           if (err.response) {
  //             setLoading(false);
  //             // setErrorDialog(true);
  //             // console.error('err.res', err.response.data);
  //           }
  //         });
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   },
  //   [chageBackFileUp]
  // );

  const uploadInvoiceRef = useRef(null);
  const handleFileIconInv = (event, id) => {
    setSelectedInvId(id);
    event.preventDefault(); // Prevent default behavior of the label
    event.stopPropagation(); // Stop event propagation to prevent it from reaching the input field
    if (uploadInvoiceRef.current && !uploadInvoiceRef.current.files.length) {
      uploadInvoiceRef.current.click();
    }
  };
  // const handleInputInvFile = async (e) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     formData.append("remarks", selectedRemark);
  //     let url = `/txn/update-chargeback-remarks/${selectedInvId}`;
  //     let options = {
  //       method: "POST",
  //       url,
  //       data: formData,
  //     };
  //     await axiosPrivate(options)
  //       .then((response) => {
  //         if (response.data.statusCode === 1) {
  //           toast.success(response.data.message)
  //           let prm = apiParams;
  //           fetchTransactions(prm);
  //         } else {
  //           toast.error(response.data.message)
  //           let prm = apiParams;
  //           fetchTransactions(prm);
  //         }
  //       })
  //       .catch((err) => {
  //         if (err.response) {
  //           toast.error("Some error occured")
  //         }
  //       });
  //   } catch (err) {
  //     toast.error("Some error occured")
  //   }
  // };

  return (
    <Fragment>
      {/* <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} /> */}
      <Backdrops open={onDownloadLoading} title={"Downloading"} />
      <Box component="div" className={"BBPTransaction"}>
        <Box component="div" className={"BBPTHead"}>
          <Box component="div" className="BBPSearchMain">
            {/* <Box component="div" className="BBPSelectDiv">
              <select
                className="BBPSBInput"
                value={selectedSearchCat}
                onChange={handleCategorySearch}
              >
                <option value="">Search By</option>
                <option value="utr">UTR NO</option>
              </select>
            </Box> */}
          </Box>
          {/* {showSearchBox ? (
            <SearchBox
              onSearchChange={handleSearch}
              placeholder={"Search Transaction"}
              // searchTooltip={
              //   "Searching from Transaction ID, Bill Number and UPI ID"
              // }
            />
          ) : (
            ""
          )} */}

          {/* {hasPartnerRole(auth.role) ? (
            <Box component="div" className="BBPSearchMain">
              <Box
                component="div"
                className="BBPSelectDiv"
                style={{ width: "12rem" }}
              >
                <select
                  className="BBPSBInput"
                  value={selectedMerchant}
                  onChange={handleMerchantChange}
                >
                  <option>Search Merchant</option>
                  {merchantList.map((record, index) => (
                    <option key={index}>{record}</option>
                  ))}

                  
                </select>
              </Box>
            </Box>
          ) : (
            ""
          )} */}

          <Box
            component="div"
            className={"BBPTHBtn"}
            style={{ alignItems: "center" }}
          >
            {/* <Box component="div" className={"BBPTHBtn"}>
            <Box className="BBPSearchBox" style={{ width: "11rem" }}>
              <input
                type="date"
                id="txnDate"
                className={"BBPSBInput"}
                value={selectedDate}
                onChange={(event) => {
                  handleFindDate(event);
                }}
              />
            </Box>
          </Box> */}
            <AutorenewOutlinedIcon
              style={{ color: "#175783" }}
              onClick={handleRefresh}
            />
            <p id="blueP" style={{ margin: "0px 10px" }}>
              Data as of {selectedDate}{" "}
            </p>
            <Box className="calenderDiv">
              <DatePicker
                ref={datePickerRef}
                selected={selectedDate}
                onChange={handleFindDate}
                dateFormat="dd/MM/yyyy"
                className="calenderIcon"
                popperPlacement="top-start"
              />
            </Box>
            <img
              src={DateIcon}
              className="imgDateIcon"
              onClick={handleImageClick}
            />
            <Button
              variant="contained"
              className={"BBPTHBD"}
              onClick={() => {
                if (hasPartnerRole(auth.role)) {
                  handleCSVDownload(selectedMerchant);
                } else {
                  handleCSVDownload();
                }
              }}
              disabled={loading || totalData === 0}
            >
              Download
            </Button>
          </Box>
        </Box>
        <Box component="div" className={"BBPTBody"}>
          <DevTable
            rows={generateRows(rowData)}
            columns={mainColumns.columns}
            selectedPage={selectedPage}
            handlePagination={handlePaginate}
            loading={loading}
            handleSort={handleSorting}
            limitData={limitData}
            totalData={totalData}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Settlement;
