import { Box, Button } from '@mui/material';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import DevTable from '../../Components/DevTable';
import Backdrops from '../../Components/Backdrops';
import { usePapaParse } from 'react-papaparse';
import { useAuth } from '../../Hooks/useAuth';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import { format } from 'date-fns';
import SearchBox from '../../Components/SearchBox';
import DateIcon from "../../Assets/Images/date.png";
import { useNavigate } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import UploadCSVDialog from '../../Components/UploadCSV';
import RefundSampleFile from "../../Assets/csv/Refund.xlsx"
import { arrayEquals } from '../../Utils';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import Papaparse from 'papaparse';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx';
import fileIcon from "../../Assets/Images/uploadIcon.png";
const Refund = () => {

    const { jsonToCSV } = usePapaParse();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [errorDialog, setErrorDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [limitData] = useState(20);
    const [onDownloadLoading, setOnDownloadLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(
        format(new Date(), "dd-MMM-yyyy")
    );
    const [rowData, setRowData] = useState([]);
    const [selectedSearchCat, setSelectedSearchCat] = useState("");
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [selectedMerchant, setSelectedMerchant] = useState("");
    const [merchantList, setMerchantList] = useState([]);
    const [selectedInvId, setSelectedInvId] = useState();
    const [apiParams] = useState({
        pageSize: limitData,
        pageNumber: 1
        // tranDate: format(new Date(), "dd-MM-yyyy"),
        // tranDate: "2024-03-13",
    });
    const datePickerRef = useRef(null);
    const [selectedRemark, setSelectedRemarkValue] = useState("");
    const handleRemarkChange = (e) => {
        setSelectedRemarkValue(e.target.value);
    };

    const fetchTransactions = useCallback(async (params, payee) => {
        try {
            setLoading(true);
            let urlParams = "";
            if (params) {
                Object.keys(params).forEach(function (key, index) {
                    urlParams += (index === 0 ? "?" : "&") + key + "=" + params[key];
                });
            }

            // let url = `/get-partner-cyber-complaint-report${urlParams}`;
            let url = hasPartnerRole(auth.role)
                ? `/txn/get-partner-refund-txn-report${urlParams}`
                : `/txn/get-merchant-refund-txn-report${urlParams}`;

            let options = {
                method: "POST",
                url,
            };
            await axiosPrivate(options)
                .then((response) => {
                    if (response.data.statusCode === 1) {
                        setRowData(response.data.data.txnList);
                        setTotalData(response.data.data.totalCount);
                        setLoading(false);
                    } else {
                        setRowData([]);
                        setTotalData(0);
                        setLoading(false);
                    }
                    //   setDataStartDate(new Date(response.data.firstDate));
                })
                .catch((err) => {
                    if (err.response) {
                        setLoading(false);
                        setErrorDialog(true);
                        // console.error('err.res', err.response.data);
                    }
                });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePaginate = (value) => {
        let prm = apiParams;
        prm.pageNumber = value;
        setSelectedPage(value);
        // setLoading(true);
        fetchTransactions(prm, selectedMerchant);
    };
    const handleCategorySearch = (event) => {
        setSelectedSearchCat(event.target.value);
        setShowSearchBox(true);
    };
    const handleSearch = useCallback(
        (value) => {
            setLoading(true);
            let prm = apiParams;
            setSelectedPage(1);
            prm.pageNumber = 1;
            if (value.length > 0) {
                prm[selectedSearchCat] = value;
            } else {
                delete prm[selectedSearchCat];
            }
            fetchTransactions(prm, selectedMerchant);
            // delete prm[selectedSearchCat];
            // setShowSearchBox(false)
        },
        [apiParams, fetchTransactions, selectedSearchCat]
    );

    function hasPartnerRole(roles) {
        return roles.includes("Partner");
    }


    const [mainColumns, setMainColumns] = useState({
        columns: [
            {
                name: "txnDate",
                title: "Txn Date",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "upiTxnId",
                title: "UPI TXN ID",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "rrn",
                title: "RRN",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "merchantVpa",
                title: "Merchant VPA",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "amount",
                title: "Amount",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "txnStatus",
                title: "TXN STATUS",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "payerVpa",
                title: "Payer VPA",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "payerMobile",
                title: "Payer Mobile",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "payerName",
                title: "Payer Name",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "type",
                title: "Type",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "status",
                title: "Refund Status",
                width: 150,
                sorting: false,
                direction: "",
            },
        ],
    });

    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    txnDate: (
                        <Box component="div" className="BBPDTSText">
                            {item.txnDate}
                        </Box>
                    ),
                    upiTxnId: (
                        <Box component="div" className="BBPDTSText">
                            {item.upiTxnId}
                        </Box>
                    ),
                    rrn: (
                        <Box component="div" className="BBPDTSText">
                            {item.rrn}
                        </Box>
                    ),
                    merchantVpa: (
                        <Box component="div" className="BBPDTSText">
                            {item.merchantVpa}
                        </Box>
                    ),
                    amount: (
                        <Box component="div" className="BBPDTSText">
                            {item.amount}
                        </Box>
                    ),
                    txnStatus: (
                        <Box component="div" className="BBPDTSText">
                            {item.txnStatus}
                        </Box>
                    ),
                    payerVpa: (
                        <Box component="div" className="BBPDTSText">
                            {item.payerVpa}
                        </Box>
                    ),
                    payerMobile: (
                        <Box component="div" className="BBPDTSText">
                            {item.payerMobile}
                        </Box>
                    ),
                    payerName: (
                        <Box component="div" className="BBPDTSText">
                            {item.payerName}
                        </Box>
                    ),
                    type: (
                        <Box component="div" className="BBPDTSText">
                            {item.type}
                        </Box>
                    ),
                    status: (
                        <Box component="div" className="BBPDTSText">
                            {item.status}
                        </Box>
                    ),
                })
            );
        }
        return tempRowArray;
    }

    useEffect(() => {
        // eslint-disable-next-line
        // fetchMerchantList();
        fetchTransactions(apiParams);
    }, []);

    const handleRefresh = () => {
        fetchTransactions(apiParams, selectedMerchant);
    };
    const handleFindDate = (e) => {
        setSelectedDate(format(e, "dd-MMM-yyyy"));
        setSelectedPage(1);
        let prm = apiParams;
        prm.complaintDate = format(e, "yyyy-MM-dd");
        prm.pageNumber = 1;
        fetchTransactions(prm);
    };
    const handleImageClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(true); // Open the date picker programmatically
        }
    };

    const handleCSVDownload = useCallback(async () => {
        try {
            setOnDownloadLoading(true);
            let urlParams = "";
            if (apiParams) {
                apiParams.pageNumber = 1;
                Object.keys(apiParams).forEach(function (key, index) {
                    urlParams +=
                        (index === 0 ? "?" : "&") +
                        key +
                        "=" +
                        (key === "pageSize" ? totalData : apiParams[key]);
                });
            }

            let url = hasPartnerRole(auth.role)
                ? `/txn/get-partner-refund-txn-report${urlParams}`
                : `/txn/get-merchant-refund-txn-report${urlParams}`;

            let options = {
                method: "POST",
                url,
            };
            await axiosPrivate(options)
                .then((response) => {
                    if (response.data.statusCode === 1) {
                        // setRowData(response.data.data.complaintList);
                        setTotalData(response.data.totalCount);

                        const tempDownloadArr = [];
                        const tempHeader = {};
                        mainColumns.columns.map(
                            (item) => (tempHeader[item.name] = item.title)
                        );
                        response.data.data.txnList.map((item) =>
                            tempDownloadArr.push({
                                txnDate: item.txnDate,
                                upiTxnId: item.upiTxnId,
                                rrn: item.rrn,
                                merchantVpa: item.merchantVpa,
                                amount: item.amount,
                                txnStatus: item.txnStatus,
                                payerVpa: item.payerVpa,
                                payerMobile: item.payerMobile,
                                payerName: item.payerName,
                                type: item.type,
                                status: item.status,
                            })
                        );
                        tempDownloadArr.unshift(tempHeader);

                        const csv = jsonToCSV(tempDownloadArr, { header: false });
                        let filename = `${"complaint"}-${format(
                            new Date(),
                            "yyyy-MM-dd-HH:mm:ss"
                        )}.csv`;
                        var csvData = new Blob([csv], {
                            type: "text/csv;charset=utf-8;",
                        });
                        var csvURL = null;
                        if (navigator.msSaveBlob) {
                            csvURL = navigator.msSaveBlob(csvData, filename);
                        } else {
                            csvURL = window.URL.createObjectURL(csvData);
                        }
                        var tempLink = document.createElement("a");
                        tempLink.href = csvURL;
                        tempLink.setAttribute("download", filename);
                        tempLink.click();
                        setOnDownloadLoading(false);
                    } else {
                        setRowData([]);
                        setTotalData(0);
                        setOnDownloadLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        setErrorDialog(true);
                        setOnDownloadLoading(false);
                        // console.error('err.res', err.response.data);
                    }
                });
        } catch (error) {
            setErrorDialog(true);
            setOnDownloadLoading(false);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalData, selectedMerchant]);
    const navigate = useNavigate();


    const downloadCsv = () => {
        const url = RefundSampleFile;
        const a = document.createElement('a');
        a.href = url;
        a.download = `refund-sample${format(new Date(), 'yyyyMMdd_HHmmss')}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); // Clean up the URL object
    };


    const [fileProgress, setFileProgress] = useState(true);
    const [jsonHeaderError, setJsonHeaderError] = useState({ err: false, success: false, arr: [] });
    const [uploadFile, setUploadFile] = useState();
    const [nextButton, setNextButton] = useState(false);
    const [tableDataErr, setTableDataErr] = useState(0);
    const [tableHeader, setTableHeader] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [apiSendingData, setApiSendingData] = useState([]);
    const [duplicateData, setDuplicateData] = useState(0);
    const [loadingcsv, setLoadingcsv] = useState(false);
    const [apiResponse, setApiResponse] = useState({});
    // const jsonHeader = ['UPI Txn Id', 'RRN', 'Type'];
    const jsonHeader = ['UPI Txn Id', 'RRN', 'Type']
    const uploadJSON = useCallback(async (data) => {
        const updatedData = data.map(row => ({
            'UPI Txn Id': row.upitxnid,
            'RRN': row.rrn,
            'Type': row.type
        }));
        // const csv = Papaparse.unparse({
        //     fields: jsonHeader,
        //     data: updatedData
        // });
        // const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        // // Create a FormData object and append the Blob as a file
        // const formData = new FormData();
        // formData.append('file', blob, 'data.csv');

        const worksheet = XLSX.utils.json_to_sheet(updatedData);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        // Generate a blob from the workbook
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new File([wbout], "data.xslx", { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data12.xlsx';
        document.body.appendChild(a);
        a.click();

        const formData = new FormData();
        formData.append('file', blob);
        try {
            let url = "/txn/upload-refund-report";
            let options = {
                method: "POST",
                url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            };
            await axiosPrivate(options)
                .then((response) => {
                    if (response.data.statusCode === 1) {
                        toast.success(response.data.message);
                        setOpenUploadDialog(false);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        toast.error("Some error occured");
                    }
                });

        } catch (error) {
            toast.error("Some error occured");
        }

    })

    const handleUploadJson = () => {
        uploadJSON(apiSendingData);
    }

    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const openUploadCsv = () => {
        setOpenUploadDialog(true);
    }
    const closeUploadCsv = () => {
        setOpenUploadDialog(false);
    }

    const convertScientificNotation = (value) => {
        if (/^\d+\.?\d*e[+-]?\d+$/i.test(value)) {
            const [base, exponent] = value.toLowerCase().split('e');
            const [integerPart, fractionPart] = base.split('.');
            const exp = parseInt(exponent, 10);
            let result = integerPart + (fractionPart || '');

            if (exp < 0) {
                result = '0.' + '0'.repeat(Math.abs(exp) - 1) + result.replace('.', '');
            } else if (exp > integerPart.length - 1) {
                result = result + '0'.repeat(exp - integerPart.length + 1);
            } else {
                result = result.slice(0, exp + 1) + '.' + result.slice(exp + 1);
            }

            return result;
        }
        return value;
    };
    const handleValidJson = (json) => {
        setLoadingcsv(false);
        // console.log(json.data[0])
        // console.log(arrayEquals(jsonHeader, json.data[0]))
        if (json.data) {
            if (arrayEquals(jsonHeader, json.data[0])) {
                let tempData = [];
                let tempError = [];
                let tempHeader = [];
                let tempRowArray = [];
                jsonHeader.map((item) => tempHeader.push({
                    name: (item.toLowerCase()).replace(/\s/g, ''),
                    title: item,
                    width: 200,
                    sorting: false,
                    direction: ''
                }));
                setTableHeader(tempHeader);
                let allData = [];
                json.data.map((item, index) => {
                    if (index > 0) {
                        console.log("index section")
                        let tempObj = {};
                        let error = false;
                        tempObj[tempHeader[0].name] = item[0].trim();
                        tempObj[tempHeader[1].name] = convertScientificNotation(item[1].trim());
                        tempObj[tempHeader[2].name] = item[2].trim();
                        tempObj['error'] = error;
                        allData.push(tempObj);

                    }
                })
                let objectFound;
                const checkDuplicateData = allData.reduce((accumulator, object) => {
                    // eslint-disable-next-line
                    if (objectFound = accumulator.find(arrItem => arrItem.rrn === object.rrn && arrItem.error === object.error)) {

                        objectFound.times++;
                        accumulator.push(object);
                    } else {
                        object.times = 1;
                        object.dup = false;
                        accumulator.push(object);
                    }
                    return accumulator;
                }, []);

                checkDuplicateData.map(item => {
                    let temObj = {};
                    let temTableObj = {};
                    let error = false;

                    if (item.upitxnid.length > 0) {
                        temObj['upitxnid'] = item.upitxnid;
                        temTableObj[tempHeader[0].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.upitxnid}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[0].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.upitxnid.length === 0 ? `${tempHeader[0].title} Not Be Empty` : ''}
                        >
                            <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                <Box component="div" className="BBPDTSEText">{item.upitxnid.length > 0 ? item.upitxnid : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }

                    if (item.rrn.length > 0) {
                        temObj['rrn'] = item.rrn;
                        temTableObj[tempHeader[1].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.rrn}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[1].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.rrn.length === 0 ? `${tempHeader[1].title} Not Be Empty` : 'Only Enter "rrn" '}
                        >
                            <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                <Box component="div" className="BBPDTSEText">{item.rrn.length > 0 ? item.rrn : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }
                    if (item.type.length > 0) {
                        temObj['type'] = item.type;
                        temTableObj[tempHeader[2].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.type}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[2].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.type.length === 0 ? `${tempHeader[2].title} Not Be Empty` : 'Only Enter "rrn" '}
                        >
                            <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                <Box component="div" className="BBPDTSEText">{item.type.length > 0 ? item.type : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }

                    if (error || !item.hasOwnProperty('times')) {
                        temTableObj['err'] = true;
                    } else {
                        temTableObj['err'] = error;
                    }
                    tempRowArray.push(temTableObj);
                    if (error) {
                        tempError.push(error);
                    } else {
                        tempData.push(temObj);
                    }
                });
                const newArrayOfObjects =
                    tempData.reduce((accumulator, object) => {
                        if (!accumulator.find(arrItem => arrItem.upitxnid === object.upitxnid && arrItem.name === object.name)) {
                            accumulator.push(object);
                        }
                        return accumulator;
                    }, []);

                if (tempError.length > 0) {
                    setTableDataErr(tempError.length);
                } else {
                    setTableDataErr(0);
                }

                setDuplicateData(tempData.length - newArrayOfObjects.length);
                setApiSendingData(newArrayOfObjects);

                if (tempRowArray.length === 0) {
                    setNextButton(true);
                    setJsonHeaderError({ err: true, success: false, arr: ['Data not found'] });
                } else {
                    setNextButton(false);
                    setJsonHeaderError({ err: false, success: true, arr: [] });
                }
                console.log(tempRowArray)
                setTableData(tempRowArray);
                setFileProgress(false);

            }
            else {
                console.log("error block")
                let headerError = [];
                jsonHeader.map((item, index) => item === json.data[0][index] ? '' : headerError.push(item));
                setJsonHeaderError({ err: true, success: false, arr: headerError });
                setNextButton(true);
                setTableData([]);
            }

        } else {
            setJsonHeaderError({ error: false, success: false, arr: [] });
            setNextButton(true);
            setTableData([]);
        }
    }

    const inputFile = useRef(null);
    const handleLabelClick = (event) => {
        event.preventDefault(); // Prevent default behavior of the label
        event.stopPropagation(); // Stop event propagation to prevent it from reaching the input field

        if (inputFile.current && !inputFile.current.files.length) {
            inputFile.current.click();
        }
    };

    const handleInputCBFile = async (e) => {
        try {
            const formData = new FormData();
            formData.append("file", e.target.files[0]);
            let url = "/txn/upload-refund-report";
            let options = {
                method: "POST",
                url,
                data: formData,
            };
            await axiosPrivate(options)
                .then((response) => {
                    if (response.data.statusCode === 1) {
                        toast.success(response.data.message);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        toast.error("Some error occured");
                    }
                });
        } catch (error) {
            toast.error("Some error occured");
        }
    };

    return (
        <Fragment>
            {/* <UploadCSVDialog
                open={openUploadDialog}
                onClose={closeUploadCsv}
                jsonHeader={jsonHeader}
                handleValidJson={handleValidJson}
                fileProgress={fileProgress}
                setFileProgress={setFileProgress}
                jsonHeaderError={jsonHeaderError}
                uploadFile={uploadFile}
                setUploadFile={setUploadFile}
                nextButton={nextButton}
                setNextButton={setNextButton}
                tableDataErr={tableDataErr}
                tableHeader={tableHeader}
                tableData={tableData}
                duplicateData={duplicateData}
                loading={loadingcsv}
                apiResponse={apiResponse}
                handleUploadJson={handleUploadJson}
                backButtonTitle={'Back to Billers'}
                CSVFile={RefundSampleFile}

            /> */}

            {/* <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} /> */}
            <Backdrops open={onDownloadLoading} title={"Downloading"} />
            <Box component="div" className={"BBPTransaction"}>
                <Box component="div" className={"BBPTHead"}>
                    <Box component="div" className="BBPSearchMain">
                        <Box component="div" className="BBPSelectDiv">
                            <select
                                className="BBPSBInput"
                                value={selectedSearchCat}
                                onChange={handleCategorySearch}
                            >
                                <option value="">Search By</option>
                                <option value="rrn">RRN</option>
                            </select>
                        </Box>
                    </Box>
                    {showSearchBox ? (
                        <SearchBox
                            onSearchChange={handleSearch}
                            placeholder={"Search Transaction"}
                        // searchTooltip={
                        //   "Searching from Transaction ID, Bill Number and UPI ID"
                        // }
                        />
                    ) : (
                        ""
                    )}


                    {/* <Box component="div" className="uploadFileCont" onClick={openUploadCsv}>
                        <p style={{ margin: "0rem", fontSize: "14px" }}>UPLOAD</p>

                        <FileDownloadOutlinedIcon style={{ color: "#175781" }} />
                    </Box> */}

                    <Box component="div" className="uploadFileCont">
                        <label
                            htmlFor="cbFileUpload"
                            onClick={handleLabelClick}
                            className="labelContFu"
                        // style={{ display: "flex", cursor: "pointer", alignItems:"center" ,  }}
                        >
                            <p style={{ margin: "0rem" }}>UPLOAD</p>
                            <img src={fileIcon} alt="File icon" />
                        </label>
                        <input
                            type="file"
                            id="cbFileUpload"
                            ref={inputFile}
                            style={{ display: "none" }}
                            onChange={handleInputCBFile}
                        />
                    </Box>
                    <Box component="div" className="uploadFileCont" onClick={downloadCsv}>
                        <p style={{ margin: "0rem", fontSize: "14px" }}>Sample File</p>

                        <FileDownloadOutlinedIcon style={{ color: "#175781" }} />
                    </Box>
                    <Box
                        component="div"
                        className={"BBPTHBtn"}
                        style={{ alignItems: "center" }}
                    >
                        <AutorenewOutlinedIcon
                            style={{ color: "#175783" }}
                            onClick={handleRefresh}
                        />
                        <p id="blueP" style={{ margin: "0px 10px" }}>
                            Data as of {selectedDate}{" "}
                        </p>
                        <Box className="calenderDiv">
                            <ReactDatePicker
                                ref={datePickerRef}
                                selected={selectedDate}
                                onChange={handleFindDate}
                                dateFormat="dd/MM/yyyy"
                                className="calenderIcon"
                                popperPlacement="top-start"
                            />
                        </Box>
                        <img
                            src={DateIcon}
                            className="imgDateIcon"
                            onClick={handleImageClick}
                        />
                        <Button
                            variant="contained"
                            className={"BBPTHBD"}
                            onClick={handleCSVDownload}
                            disabled={loading || totalData === 0}
                        >
                            Download
                        </Button>
                    </Box>
                </Box>
                <Box component="div" className={"BBPTBody"}>
                    <DevTable
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        selectedPage={selectedPage}
                        handlePagination={handlePaginate}
                        loading={loading}
                        // handleSort={handleSorting}
                        limitData={limitData}
                        totalData={totalData}
                    />
                </Box>
            </Box>
        </Fragment>
    )
}

export default Refund