import { Box, Button } from '@mui/material'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import Backdrops from '../../Components/Backdrops'
import DevTable from '../../Components/DevTable'
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate'
import { usePapaParse } from 'react-papaparse'
import { useAuth } from '../../Hooks/useAuth'
import { format } from 'date-fns'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import SettingsCellOutlinedIcon from '@mui/icons-material/SettingsCellOutlined';
import InstallMobileOutlinedIcon from '@mui/icons-material/InstallMobileOutlined';
import pdfIcon from "../../Assets/Images/pdfIcon.png";
import SearchBox from '../../Components/SearchBox'
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import DatePicker from "react-datepicker";
import DateIcon from "../../Assets/Images/date.png";
import { useNavigate } from 'react-router-dom'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const Complaints = () => {
    const { jsonToCSV } = usePapaParse();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [errorDialog, setErrorDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [limitData] = useState(20);
    const [onDownloadLoading, setOnDownloadLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(
        format(new Date(), "dd-MMM-yyyy")
    );
    const [rowData, setRowData] = useState([]);
    const [selectedSearchCat, setSelectedSearchCat] = useState("");
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [selectedMerchant, setSelectedMerchant] = useState("");
    const [merchantList, setMerchantList] = useState([]);
    const [selectedInvId, setSelectedInvId] = useState();
    const [apiParams] = useState({
        pageSize: limitData,
        pageNumber: 1
        // tranDate: format(new Date(), "dd-MM-yyyy"),
        // tranDate: "2024-03-13",
    });
    const datePickerRef = useRef(null);
    const [selectedRemark, setSelectedRemarkValue] = useState("");
    const handleRemarkChange = (e) => {
        setSelectedRemarkValue(e.target.value);
    };

    const fetchTransactions = useCallback(async (params, payee) => {
        try {
            setLoading(true);
            let urlParams = "";
            if (params) {
                Object.keys(params).forEach(function (key, index) {
                    urlParams += (index === 0 ? "?" : "&") + key + "=" + params[key];
                });
            }

            // let url = `/get-partner-cyber-complaint-report${urlParams}`;
            let url = hasPartnerRole(auth.role)
                ? `/txn/get-partner-cyber-complaint-report${urlParams}`
                : `/txn/get-merchant-cyber-complaint-report${urlParams}`;

            let options = {
                method: "POST",
                url,
            };
            await axiosPrivate(options)
                .then((response) => {
                    if (response.data.statusCode === 1) {
                        setRowData(response.data.data.complaintList);
                        setTotalData(response.data.data.totalCount);
                        setLoading(false);
                    } else {
                        setRowData([]);
                        setTotalData(0);
                        setLoading(false);
                    }
                    //   setDataStartDate(new Date(response.data.firstDate));
                })
                .catch((err) => {
                    if (err.response) {
                        setLoading(false);
                        setErrorDialog(true);
                        // console.error('err.res', err.response.data);
                    }
                });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePaginate = (value) => {
        let prm = apiParams;
        prm.pageNumber = value;
        setSelectedPage(value);
        // setLoading(true);
        fetchTransactions(prm, selectedMerchant);
    };
    const handleCategorySearch = (event) => {
        setSelectedSearchCat(event.target.value);
        setShowSearchBox(true);
    };
    const handleSearch = useCallback(
        (value) => {
            setLoading(true);
            let prm = apiParams;
            setSelectedPage(1);
            prm.pageNumber = 1;
            if (value.length > 0) {
                prm[selectedSearchCat] = value;
            } else {
                delete prm[selectedSearchCat];
            }
            fetchTransactions(prm, selectedMerchant);
            // delete prm[selectedSearchCat];
            // setShowSearchBox(false)
        },
        [apiParams, fetchTransactions, selectedSearchCat]
    );

    function hasPartnerRole(roles) {
        return roles.includes("Partner");
    }



    const [mainColumns, setMainColumns] = useState({
        columns: [
            {
                name: "ackComplaintNo",
                title: "Complain Number",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "rrn",
                title: "RRN",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "payeeVpa",
                title: "PAYEE VPA",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "customerVpa",
                title: "CUSTOMER VPA",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "mobileNumber",
                title: "MOBILE NUMBER",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "transactionAmount",
                title: "TXN AMT",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "transactionDate",
                title: "TXN DATE",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "complaintDate",
                title: "COMPLAINT DATE",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "status",
                title: "STATUS",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "refId",
                title: "RefERENCE ID",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "remarks",
                title: "Action",
                width: 50,
                sorting: false,
                direction: "",
            }
        ],
    });

    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    ackComplaintNo: (
                        <Box component="div" className="BBPDTSText">
                            {item.ackComplaintNo}
                        </Box>
                    ),
                    rrn: (
                        <Box component="div" className="BBPDTSText">
                            {item.rrn}
                        </Box>
                    ),
                    customerVpa: (
                        <Box component="div" className="BBPDTSText">
                            {item.customerVpa}
                        </Box>
                    ),
                    payeeVpa: (
                        <Box component="div" className="BBPDTSText">
                            {item.payeeVpa}
                        </Box>
                    ),
                    mobileNumber: (
                        <Box component="div" className="BBPDTSText">
                            {item.mobileNumber}
                        </Box>
                    ),
                    transactionAmount: (
                        <Box component="div" className="BBPDTSText">
                            {item.transactionAmount}
                        </Box>
                    ),
                    transactionDate: (
                        <Box component="div" className="BBPDTSText">
                            {format(new Date(item.transactionDate), "dd-MMM-yyyy")}
                        </Box>
                    ),
                    complaintDate: (
                        <Box component="div" className="BBPDTSText">
                            {item.complaintDate}
                        </Box>
                    ),
                    status: (
                        <Box component="div" className="BBPDTSText">
                            {item.status}
                        </Box>
                    ),
                    refId: (
                        <Box component="div" className="BBPDTSText">
                            {item.refId}
                        </Box>
                    ),
                    remarks: (
                        <Box component="div" className="BBPDTSText">
                            <Box>
                                <Box style={{ display: "flex", gap: "0.5rem" }}>
                                    <span
                                        onClick={() => { navigateToDetail(item.id) }}
                                    >
                                        <RemoveRedEyeOutlinedIcon style={{ color: "#175783", fontSize: "medium" }} />
                                    </span>
                                </Box>
                            </Box>
                            {/* <Box>
                                <Box style={{ display: "flex", gap: "0.5rem" }}>
                                    <span
                                    //  onClick={() => { handleDialogOpen(item.payeeVpa, "") }}
                                    >
                                        <InstallMobileOutlinedIcon style={{ color: "#175783", fontSize: "medium" }} />
                                    </span>
                                    <span
                                    // onClick={() => { handleDialogOpen("", item.mobileNumber) }}
                                    >
                                        <PhoneIphoneOutlinedIcon style={{ color: "#175783", fontSize: "medium" }} />
                                    </span>
                                    <span
                                    // onClick={() => { navigateToDetail(item.id) }}
                                    >
                                        <RemoveRedEyeOutlinedIcon style={{ color: "#175783", fontSize: "medium" }} />
                                    </span>

                                    <span >
                                        <img src={pdfIcon} style={{ height: "16px" }} />
                                        <RemoveRedEyeOutlinedIcon style={{ color: "#175783", fontSize: "medium" }} />
                                    </span>

                                </Box>
                            </Box> */}
                        </Box>
                    ),

                })
            );
        }
        return tempRowArray;
    }
    useEffect(() => {
        // eslint-disable-next-line
        // fetchMerchantList();
        fetchTransactions(apiParams);
    }, []);

    const handleRefresh = () => {
        fetchTransactions(apiParams, selectedMerchant);
    };
    const handleFindDate = (e) => {
        setSelectedDate(format(e, "dd-MMM-yyyy"));
        setSelectedPage(1);
        let prm = apiParams;
        prm.complaintDate = format(e, "yyyy-MM-dd");
        prm.pageNumber = 1;
        fetchTransactions(prm);
    };
    const handleImageClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(true); // Open the date picker programmatically
        }
    };

    const handleCSVDownload = useCallback(async () => {
        try {
            setOnDownloadLoading(true);
            let urlParams = "";
            if (apiParams) {
                 apiParams.pageNumber=1;
                Object.keys(apiParams).forEach(function (key, index) {
                    urlParams +=
                        (index === 0 ? "?" : "&") +
                        key +
                        "=" +
                        (key === "pageSize" ? totalData : apiParams[key]);
                });
            }

            let url = hasPartnerRole(auth.role)
                ? `/txn/get-partner-cyber-complaint-report${urlParams}`
                : `/txn/get-merchant-cyber-complaint-report${urlParams}`;

            let options = {
                method: "POST",
                url,
            };
            await axiosPrivate(options)
                .then((response) => {
                    if (response.data.statusCode === 1) {
                        // setRowData(response.data.data.complaintList);
                        setTotalData(response.data.totalCount);

                        const tempDownloadArr = [];
                        const tempHeader = {};
                        mainColumns.columns.map(
                            (item) => (tempHeader[item.name] = item.title)
                        );
                        response.data.data.complaintList.map((item) =>
                            tempDownloadArr.push({
                                ackComplaintNo: item.ackComplaintNo,
                                rrn: item.rrn,
                                payeeVpa: item.payeeVpa,
                                customerVpa: item.customerVpa,
                                mobileNumber: item.mobileNumber,
                                transactionAmount: item.transactionAmount,
                                transactionDate: item.transactionDate,
                                complaintDate: item.complaintDate,
                                status: item.status,
                                refId: item.refId,
                            })
                        );
                        tempDownloadArr.unshift(tempHeader);

                        const csv = jsonToCSV(tempDownloadArr, { header: false });
                        let filename = `${"complaint"}-${format(
                            new Date(),
                            "yyyy-MM-dd-HH:mm:ss"
                        )}.csv`;
                        var csvData = new Blob([csv], {
                            type: "text/csv;charset=utf-8;",
                        });
                        var csvURL = null;
                        if (navigator.msSaveBlob) {
                            csvURL = navigator.msSaveBlob(csvData, filename);
                        } else {
                            csvURL = window.URL.createObjectURL(csvData);
                        }
                        var tempLink = document.createElement("a");
                        tempLink.href = csvURL;
                        tempLink.setAttribute("download", filename);
                        tempLink.click();
                        setOnDownloadLoading(false);
                    } else {
                        setRowData([]);
                        setTotalData(0);
                        setOnDownloadLoading(false);
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        setErrorDialog(true);
                        setOnDownloadLoading(false);
                        // console.error('err.res', err.response.data);
                    }
                });
        } catch (error) {
            setErrorDialog(true);
            setOnDownloadLoading(false);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalData, selectedMerchant]);
    const navigate = useNavigate();

    const navigateToDetail = (id) => {
        navigate(`/reports/cyber-complaints/${id}`)

    }

    return (
        <Fragment>
            {/* <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} /> */}
            {/* <Backdrops open={onDownloadLoading} title={"Downloading"} /> */}
            <Box component="div" className={"BBPTransaction"}>
                <Box component="div" className={"BBPTHead"}>

                    <Box component="div" className="BBPSearchMain">
                        <Box component="div" className="BBPSelectDiv">
                            <select
                                className="BBPSBInput"
                                value={selectedSearchCat}
                                onChange={handleCategorySearch}
                            >
                                <option value="">Search By</option>
                                <option value="rrn">Rrn</option>
                                <option value="payee">Payee Vpa</option>
                                <option value="customerVpa">Customer Vpa</option>
                                <option value="mobileNumber">Mobile Number</option>
                                <option value="refId">Ref Id</option>
                                <option value="complaintDate">Complaint Date</option>
                            </select>
                        </Box>
                    </Box>

                    {showSearchBox ? (
                        <SearchBox
                            onSearchChange={handleSearch}
                            placeholder={"Search Transaction"}
                        // searchTooltip={
                        //   "Searching from Transaction ID, Bill Number and UPI ID"
                        // }
                        />
                    ) : (
                        ""
                    )}

                    <Box
                        component="div"
                        className={"BBPTHBtn"}
                        style={{ alignItems: "center" }}
                    >
                        <AutorenewOutlinedIcon
                            style={{ color: "#175783" }}
                            onClick={handleRefresh}
                        />
                        <p id="blueP" style={{ margin: "0px 10px" }}>
                            Date as of {selectedDate}{" "}
                        </p>
                        <Box className="calenderDiv">
                            <DatePicker
                                ref={datePickerRef}
                                selected={selectedDate}
                                onChange={handleFindDate}
                                dateFormat="dd/MM/yyyy"
                                className="calenderIcon"
                                popperPlacement="top-start"
                            />
                        </Box>
                        <img
                            src={DateIcon}
                            className="imgDateIcon"
                            onClick={handleImageClick}
                        />
                        <Button
                            variant="contained"
                            className={"BBPTHBD"}
                            onClick={handleCSVDownload}
                            disabled={loading || totalData === 0}
                        >
                            Download
                        </Button>
                    </Box>
                </Box>
                <Box component="div" className={"BBPTBody"}>
                    <DevTable
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        selectedPage={selectedPage}
                        handlePagination={handlePaginate}
                        loading={loading}
                        // handleSort={handleSorting}
                        limitData={limitData}
                        totalData={totalData}
                    />
                </Box>
            </Box>
        </Fragment>
    )
}

export default Complaints