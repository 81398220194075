import React, { useState } from 'react'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import BillPayments from './billPayments';
import Payouts from './allPayouts';
import PaymentLinks from './paymentLinks';
import RightSidePanel from '../../Components/RightSidePanel'
import PayoutsTab from "./payouts"
import AllBeneficiary from './allBeneficiary';
import BeneficiaryTab from './beneficiaryTab';
import PendingBeneficiary from './pendingBeneficiary';
import AllTransactions from './allTransactions';

const Index = () => {
    let location = useLocation();

    const background = location.state ? location.state.background : location;

    const [pages] = useState([
        {
            path: "/",
            component: AllTransactions ,
            enable_route: true,
        },
        {
            path: "/statements",
            component: Payouts,
            enable_route: true,
        },
        // {
        //     path: "/payouts",
        //     component: Payouts,
        //     enable_route: true,
        // },
        {
            path: "/payment-link",
            component: PaymentLinks,
            enable_route: true,
        },
        {
            path: "/single-payout/*",
            component: PayoutsTab,
            enable_route: true,
        },
        {
            path: "/beneficiary",
            component: AllBeneficiary,
            enable_route: true,
        },
        {
            path: "/beneficiary/add",
            component: BeneficiaryTab,
            enable_route: true,
        },
        {
            path: "/beneficiary/pending",
            component: PendingBeneficiary,
            enable_route: true,
        },
    ]);
    return (
        <RightSidePanel removeBg>
            <Routes location={background}>
                <Route path={"/"} element={<Outlet />}>
                    {
                        // eslint-disable-next-line array-callback-return
                        pages.map((route) => {
                            if (route.enable_route) {
                                const {
                                    path,
                                    component: Component,
                                    children,
                                    title,
                                    permission,
                                    ...rest
                                } = route;
                                return (
                                    <Route
                                        {...rest}
                                        key={path}
                                        path={`${path}`}
                                        element={<Component />}
                                    />
                                );
                            }
                        })
                    }
                </Route>
            </Routes>
        </RightSidePanel>
    )
}

export default Index