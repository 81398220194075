import { Dialog } from '@mui/material'
import React from 'react'
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import "./index.css"
import { format, parseISO } from 'date-fns';
const ViewBeneficiary = ({ open, onClose, data, loading }) => {
    const getStatus = (value) => {
        if (value === "A") {
            return "Approved"
        } else if (value === "PA") {
            return "Pending Approval"
        } else {
            return "";
        }
    }

    function formatDate(value) {

        try {
            return format(parseISO(value), "yyyy-MM-dd HH:mm:ss")
        } catch (error) {
            return ""
        }
    }
    

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={onClose}>
            {loading ? <Box component='div' className={'BBPBLoader'} /> :
                <>
                    <Box component='div' className={'BBPAlertDialog'}>

                        <Box className="merchantDialogCont">
                            <p onClick={onClose}><CloseIcon /></p>

                        </Box>
                        <Box className="BBPMInfo">
                            {/* <p>Id : {data?.data?.id}</p> */}
                            <p>Name: {data?.name}</p>
                            {/* <br></br> */}
                            <p>Mobile : {data?.mobile}</p>
                            <p>Email : {data?.email}</p>
                            <p>Date : {formatDate(data?.createdDate)}</p>
                            <p>Account : {data?.account}</p>
                            <p>IFSC : {data?.ifsc}</p>
                            <p>City : {data?.city}</p>
                            <p>State : {data?.state}</p>
                            <p>Pincode : {data?.pincode}</p>
                            <p>Status : {getStatus(data?.status)}</p>
                            <p>BeneficiaryId : {data?.beneId}</p>
                            <p>Address: {data?.address}</p>
                            <p>Banck Code : {data?.bankCode}</p>
                            <p>Add Count : {data?.addCount}</p>
                            <p>Remark : {data?.remark}</p>

                        </Box>
                    </Box>

                </>}

        </Dialog>
    )
}

export default ViewBeneficiary