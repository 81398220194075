export const MENU_SLUG = {
    login: 'login',
    dashboard: 'dashboard',
    reports: 'reports',
    users: 'users',
    customers: 'customers',
    settings: 'settings',
    transaction:"transaction",
    beneficiary:"beneficiary"
};
