import React from 'react'
import SvgIcon from '@mui/material/SvgIcon';
const TransactionIcon = (props) => {
    return (
        // <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M2 8H20M16 3L21 8L16 13M22 18H4M8 13L3 18L8 23" stroke="#7E84A3" stroke-width="2" stroke-linecap="round" />
        // </svg>
        <SvgIcon {...props} viewBox="0 0 24 24" fontSize="inherit">
            {/* <path d="M0,0H24V24H0Z" fill="none" /> */}
            <path d="M2 8H20M16 3L21 8L16 13M22 18H4M8 13L3 18L8 23" stroke="#7E84A3" stroke-width="2" stroke-linecap="round" />
        </SvgIcon>
    )
}

export default TransactionIcon