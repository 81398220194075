// import React, { Fragment, useState } from 'react'
// import Box from '@mui/material/Box';
// import { Button, FormControl, Input, InputLabel, MenuItem, Select, Skeleton, Stack, TextField } from '@mui/material';
// const SinglePayouts = () => {
//     const [loading, setLoading] = useState(false);
//     const [paymentMethod, setPaymentMethod] = useState("UPI");
//     const handlePaymentMethodChange = (e) => {
//         setPaymentMethod(e.target.value)
//         // console.log(e.target.value)
//     }
//     return (
//         <Fragment>
//             <Box component="div" className={"BBPSPTView"} style={{ background: "#fff" }} >
//                 <Box component="div" className={"BBPSPTVForm"} >
//                     <form>
//                         <Box >

//                             <Box style={{ width: "100%" , padding:"0rem 1rem"}}>

//                                 {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}

//                                 <Box style={{ display: "flex", gap: "1rem" }}>
//                                     <Box className='BBPCPLFORMItem'>
//                                         <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
//                                             <label>Transfer Type</label>
//                                             <Select
//                                                 // labelId="demo-simple-select-label"
//                                                 // id="demo-simple-select"
//                                                 value={paymentMethod}
//                                                 // label="Age"
//                                                 sx={{ width: 200 }}
//                                                 onChange={handlePaymentMethodChange}
//                                             >
//                                                 <MenuItem value={"UPI"}>UPI</MenuItem>
//                                                 <MenuItem value={"IMPS"}>IMPS</MenuItem>
//                                                 <MenuItem value={"RTGS"}>RTGS</MenuItem>
//                                                 <MenuItem value={"NEFT"}>NEFT</MenuItem>
//                                             </Select>
//                                         </FormControl>
//                                     </Box>
//                                     {paymentMethod === "IMPS" ? "" :
//                                         <Box className='BBPCPLFORMItem'>
//                                             <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
//                                                 <label>Transaction Type</label>
//                                                 <Select
//                                                     // labelId="demo-simple-select-label"
//                                                     // id="demo-simple-select"
//                                                     // value={age}
//                                                     // label="Age"
//                                                     sx={{ width: 200 }}
//                                                 // onChange={handleChange}
//                                                 >
//                                                     <MenuItem value={10}>Merchant to Person</MenuItem>

//                                                 </Select>
//                                             </FormControl>
//                                         </Box>}
//                                 </Box>

//                             </Box>

//                             <Box className="BBPSPFGrid">
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>Beneficiary Name</label>
//                                     <TextField id="outlined-basic" variant="outlined" InputProps={{
//                                         style: {
//                                             height: 40,
//                                             width: 200 // adjust this value to your desired height
//                                         },
//                                     }} />

//                                 </Box>
//                                 {paymentMethod === "UPI" &&
//                                     <Box className='BBPCPLFORMItem'>
//                                         <label>Payer Vpa</label>
//                                         <TextField id="outlined-basic" variant="outlined" InputProps={{
//                                             style: {
//                                                 height: 40, width: 200 // adjust this value to your desired height
//                                             },
//                                         }} />

//                                     </Box>}

//                                 {paymentMethod !== "UPI" && <><Box className='BBPCPLFORMItem'>
//                                     <label>Beneficiary Account Number</label>
//                                     <TextField id="outlined-basic" variant="outlined" InputProps={{
//                                         style: {
//                                             height: 40, width: 200 // adjust this value to your desired height
//                                         },
//                                     }} />

//                                 </Box>
//                                     <Box className='BBPCPLFORMItem'>
//                                         <label>Beneficiary IFSC Code</label>
//                                         <TextField id="outlined-basic" variant="outlined" InputProps={{
//                                             style: {
//                                                 height: 40, width: 200 // adjust this value to your desired height
//                                             },
//                                         }} />

//                                     </Box></>}
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>Mobile Number</label>
//                                     <TextField id="outlined-basic" variant="outlined" InputProps={{
//                                         style: {
//                                             height: 40, width: 200 // adjust this value to your desired height
//                                         },
//                                     }} />

//                                 </Box>
//                                 <Box className='BBPCPLFORMItem'>
//                                     <label>Amount</label>
//                                     <TextField id="outlined-basic" variant="outlined" InputProps={{
//                                         style: {
//                                             height: 40, width: 200 // adjust this value to your desired height
//                                         },
//                                     }} />

//                                 </Box>
//                                 {paymentMethod === "RTGS" &&
//                                     <Box className='BBPCPLFORMItem'>
//                                         <label>Remarks</label>
//                                         <TextField id="outlined-basic" variant="outlined" InputProps={{
//                                             style: {
//                                                 height: 40, width: 200 // adjust this value to your desired height
//                                             },
//                                         }} />

//                                     </Box>
//                                 }

//                             </Box>



//                         </Box>


//                         <Box className="BBPPayoutBtnCont">
//                             <Button variant="contained"
//                                 className={""}>
//                                 Cancel
//                             </Button>
//                             <Button variant="contained"
//                                 className={""}>Submit</Button>
//                         </Box>
//                     </form>

//                 </Box>


//             </Box>
//         </Fragment>
//     )
// }

// export default SinglePayouts




import React, { Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, FormControl, MenuItem, Select, TextField } from '@mui/material';

const SinglePayouts = () => {
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("UPI");
    const [formValues, setFormValues] = useState({
        beneficiaryName: "",
        transactionType: "",
        payerVpa: "",
        accountNumber: "",
        ifscCode: "",
        mobileNumber: "",
        amount: "",
        remarks: ""
    });
    const [formErrors, setFormErrors] = useState({});

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
        setFormErrors({});
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setFormErrors({ ...formErrors, [name]: "" });
    };

    const validate = () => {
        let errors = {};
        if (!formValues.beneficiaryName) {
            errors.beneficiaryName = "Beneficiary Name is required";
        }
        if (paymentMethod !== "IMPS" && !formValues.transactionType) {
            errors.transactionType = "Transaction Type is required";
        }
        if (paymentMethod === "UPI" && !formValues.payerVpa) {
            errors.payerVpa = "Payer VPA is required for UPI";
        }
        if (paymentMethod !== "UPI") {
            if (!formValues.accountNumber) {
                errors.accountNumber = "Account Number is required";
            }
            if (!formValues.ifscCode) {
                errors.ifscCode = "IFSC Code is required";
            }
        }
        if (!formValues.mobileNumber) {
            errors.mobileNumber = "Mobile Number is required";
        }
        if (!formValues.amount) {
            errors.amount = "Amount is required";
        }
        if (paymentMethod === "RTGS" && !formValues.remarks) {
            errors.remarks = "Remarks are required for RTGS";
        }
        return errors;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validate();
        if (Object.keys(errors).length === 0) {
            // Submit form data
            console.log("Form submitted successfully", formValues);
        } else {
            setFormErrors(errors);
        }
    };

    return (
        <Fragment>
            <Box component="div" className={"BBPSPTView"} style={{ background: "#fff" }}>
                <Box component="div" className={"BBPSPTVForm"}>
                    <form onSubmit={handleSubmit}>
                        <Box>
                            <Box style={{ width: "100%", padding: "0rem 1rem" }}>
                                <Box style={{ display: "flex", gap: "1rem" }}>
                                    <Box className='BBPCPLFORMItem'>
                                        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                            <label>Transfer Type</label>
                                            <Select
                                                value={paymentMethod}
                                                sx={{ width: 200 }}
                                                onChange={handlePaymentMethodChange}
                                            >
                                                <MenuItem value={"UPI"}>UPI</MenuItem>
                                                <MenuItem value={"IMPS"}>IMPS</MenuItem>
                                                <MenuItem value={"RTGS"}>RTGS</MenuItem>
                                                <MenuItem value={"NEFT"}>NEFT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    {paymentMethod !== "IMPS" &&
                                        <Box className='BBPCPLFORMItem'>
                                            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                                <label>Transaction Type</label>
                                                <Select
                                                    name="transactionType"
                                                    value={formValues.transactionType}
                                                    onChange={handleInputChange}
                                                    error={!!formErrors.transactionType}
                                                    sx={{ width: 200 }}
                                                >
                                                    <MenuItem value={10}>Merchant to Person</MenuItem>
                                                </Select>
                                                {formErrors.transactionType && (
                                                    <p style={{ color: 'red', margin: '0', fontSize: '0.75rem' }}>
                                                        {formErrors.transactionType}
                                                    </p>
                                                )}
                                            </FormControl>
                                        </Box>
                                    }
                                </Box>
                            </Box>

                            <Box className="BBPSPFGrid">
                                <Box className='BBPCPLFORMItem'>
                                    <label>Beneficiary Name</label>
                                    <TextField
                                        name="beneficiaryName"
                                        variant="outlined"
                                        value={formValues.beneficiaryName}
                                        onChange={handleInputChange}
                                        error={!!formErrors.beneficiaryName}
                                        helperText={formErrors.beneficiaryName}
                                        InputProps={{
                                            style: { height: 40, width: 200 }
                                        }}
                                    />
                                </Box>

                                {paymentMethod === "UPI" &&
                                    <Box className='BBPCPLFORMItem'>
                                        <label>Payer Vpa</label>
                                        <TextField
                                            name="payerVpa"
                                            variant="outlined"
                                            value={formValues.payerVpa}
                                            onChange={handleInputChange}
                                            error={!!formErrors.payerVpa}
                                            helperText={formErrors.payerVpa}
                                            InputProps={{
                                                style: { height: 40, width: 200 }
                                            }}
                                        />
                                    </Box>
                                }

                                {paymentMethod !== "UPI" && <>
                                    <Box className='BBPCPLFORMItem'>
                                        <label>Beneficiary Account Number</label>
                                        <TextField
                                            name="accountNumber"
                                            variant="outlined"
                                            value={formValues.accountNumber}
                                            onChange={handleInputChange}
                                            error={!!formErrors.accountNumber}
                                            helperText={formErrors.accountNumber}
                                            InputProps={{
                                                style: { height: 40, width: 200 }
                                            }}
                                        />
                                    </Box>
                                    <Box className='BBPCPLFORMItem'>
                                        <label>Beneficiary IFSC Code</label>
                                        <TextField
                                            name="ifscCode"
                                            variant="outlined"
                                            value={formValues.ifscCode}
                                            onChange={handleInputChange}
                                            error={!!formErrors.ifscCode}
                                            helperText={formErrors.ifscCode}
                                            InputProps={{
                                                style: { height: 40, width: 200 }
                                            }}
                                        />
                                    </Box>
                                </>}

                                <Box className='BBPCPLFORMItem'>
                                    <label>Mobile Number</label>
                                    <TextField
                                        name="mobileNumber"
                                        variant="outlined"
                                        value={formValues.mobileNumber}
                                        onChange={handleInputChange}
                                        error={!!formErrors.mobileNumber}
                                        helperText={formErrors.mobileNumber}
                                        InputProps={{
                                            style: { height: 40, width: 200 }
                                        }}
                                    />
                                </Box>

                                <Box className='BBPCPLFORMItem'>
                                    <label>Amount</label>
                                    <TextField
                                        name="amount"
                                        variant="outlined"
                                        value={formValues.amount}
                                        onChange={handleInputChange}
                                        error={!!formErrors.amount}
                                        helperText={formErrors.amount}
                                        InputProps={{
                                            style: { height: 40, width: 200 }
                                        }}
                                    />
                                </Box>

                                {paymentMethod === "RTGS" &&
                                    <Box className='BBPCPLFORMItem'>
                                        <label>Remarks</label>
                                        <TextField
                                            name="remarks"
                                            variant="outlined"
                                            value={formValues.remarks}
                                            onChange={handleInputChange}
                                            error={!!formErrors.remarks}
                                            helperText={formErrors.remarks}
                                            InputProps={{
                                                style: { height: 40, width: 200 }
                                            }}
                                        />
                                    </Box>
                                }
                            </Box>
                        </Box>

                        <Box className="BBPPayoutBtnCont">
                            <Button variant="contained" className={""}>
                                Cancel
                            </Button>
                            <Button variant="contained" type="submit" className={""}>
                                Submit
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Fragment>
    );
};

export default SinglePayouts;
