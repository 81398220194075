import React, { useState, useCallback, useEffect, Fragment, useRef } from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import SomethingWentWrong from "../../Components/SomethingWentWrong";
import { useAxiosPrivate } from "../../Hooks/useAxiosPrivate";
import pdfIcon from "../../Assets/Images/pdfIcon.png";
import format from "date-fns/format";
const ComplainDetails = () => {

    const param = useParams();
   // console.log(param.id);
    const axiosPrivate = useAxiosPrivate();
    const [errorDialog, setErrorDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [rowData, setRowData] = useState();
    const fetchTransactions = useCallback(async (id) => {
        try {
            setLoading(true);
            let url = `/txn/get-cyber-complaint/${id}`;
            let options = {
                method: "GET",
                url,
            };

            await axiosPrivate(options)
                .then((response) => {
                    if (response.data.statusCode === 1) {
                        setRowData(response.data.data);
                        setLoading(false);
                    } else {
                        setRowData([]);
                        setLoading(false);
                    }
                    //   setDataStartDate(new Date(response.data.firstDate));
                })
                .catch((err) => {
                    if (err.response) {
                        setLoading(false);
                        setErrorDialog(true);
                        // console.error('err.res', err.response.data);
                    }
                });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        fetchTransactions(param.id)
    }, [])



    return (
        <Fragment>
            <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
            <Box component="div" className={"BBPTransaction"}>
                <Box component="div" className={"BBPTHead"}>
                    <p style={{ margin: "0px 0px" }}>View Cyber Complaint</p>
                </Box>
                <Box component="div" className={"BBPTBody"} style={{ borderRadius: "12px ", boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)" }} bgcolor={"white"}>
                    {loading ? "" :
                        <Box component="div" className="CCDetailGrid" >
                            <Box component="div" className="CCDetailGridItem">
                                <p>RRN</p>
                                <p id="ccdFont">{rowData?.rrn}</p>
                            </Box>
                            <Box component="div" className="CCDetailGridItem">
                                <p>Payee Vpa</p>
                                <p id="ccdFont">{rowData?.payeeVpa}</p>
                            </Box>

                            <Box component="div" className="CCDetailGridItem">
                                <p>Customer Vpa</p>
                                <p id="ccdFont">{rowData?.customerVpa}</p>
                            </Box>
                            <Box component="div" className="CCDetailGridItem">
                                <p>Mobile Number</p>
                                <p id="ccdFont">{rowData?.mobileNumber}</p>
                            </Box>
                            <Box component="div" className="CCDetailGridItem">
                                <p>Transaction Amount</p>
                                <p id="ccdFont">{rowData?.transactionAmount}</p>
                            </Box>
                            <Box component="div" className="CCDetailGridItem">
                                <p>Transaction Date </p>
                                <p id="ccdFont">{format(new Date(rowData?.transactionDate), "dd-MM-yyyy")}</p>
                            </Box>
                            <Box component="div" className="CCDetailGridItem">
                                <p>Ack / Complaint No</p>
                                <p id="ccdFont">{rowData?.ackComplaintNo}</p>
                            </Box>
                            <Box component="div" className="CCDetailGridItem">
                                <p>Complainant Name </p>
                                <p id="ccdFont">{rowData?.complainantName}</p>
                            </Box>
                            <Box component="div" className="CCDetailGridItem">
                                <p>Mobile Number</p>
                                <p id="ccdFont">{rowData?.complainantMobileNumber}</p>
                            </Box>

                            <Box component="div" className="CCDetailGridItem">
                                <p>Status</p>
                                <p id="ccdFont">{rowData?.status}</p>
                            </Box>
                            <Box component="div" className="CCDetailGridItem">
                                <p>Email ID</p>
                                <p id="ccdFont">{rowData?.complainantEmail}</p>
                            </Box>

                            <Box component="div" className="CCDetailGridItem">
                                <p>Complaint Date</p>
                                <p id="ccdFont">{rowData?.complaintDate}</p>
                            </Box><Box component="div" className="CCDetailGridItem">
                                <p>Name</p>
                                <p id="ccdFont">{rowData?.name}</p>
                            </Box>
                            <Box component="div" className="CCDetailGridItem">
                                <p>Designation</p>
                                <p id="ccdFont">{rowData?.designation}</p>
                            </Box>

                            <Box component="div" className="CCDetailGridItem">
                                <p>Ext Mobile Number</p>
                                <p id="ccdFont">{rowData?.extMobileNumber}</p>
                            </Box>
                            <Box component="div" className="CCDetailGridItem">
                                <p>Ext Email</p>
                                <p id="ccdFont">{rowData?.extEmail}</p>
                            </Box>
                            <Box component="div" className="CCDetailGridItem">
                                <p>Reason</p>
                                <p id="ccdFont">{rowData?.reason}</p>
                            </Box>

                            <Box component="div" className="CCDetailGridItem">
                                <p>Attachment</p>
                                {rowData?.file ? <img src={pdfIcon} className="pdfIconCCP" /> : ""}
                                {/* <p id="ccdFont">{rowData?.rrn}</p> */}
                            </Box>



                        </Box>}

                </Box>
            </Box>
        </Fragment>
    )
}

export default ComplainDetails