import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import Backdrops from '../../Components/Backdrops'
import Box from "@mui/material/Box";
import DevTable from '../../Components/DevTable';
import SearchBox from '../../Components/SearchBox';
import toast from 'react-hot-toast';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import fileIcon from "../../Assets/Images/uploadIcon.png";
import { Button } from '@mui/material';
import DateRangePicker from "../../Components/DateRangePicker/index"
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Hooks/useAuth';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import { endOfDay, format, startOfDay } from 'date-fns';

const PendingBeneficiary = () => {
    const auth = useAuth();
    const [mainColumns, setMainColumns] = useState({
        columns: [
            {
                name: "createdDate",
                title: "Date",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "beneId",
                title: "Beneficiary Id ",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "name",
                title: "name",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "account",
                title: "account",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "ifsc",
                title: " ifsc",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "address",
                title: "address",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "email",
                title: "email",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "mobile",
                title: "mobile",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "city",
                title: "city",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "state",
                title: "state",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "pincode",
                title: "pincode",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "status",
                title: "status",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "bankCode",
                title: "bankCode",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "bankBCode",
                title: "bankBCode",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "partnerId",
                title: "partnerId",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "remark",
                title: "remark",
                width: 150,
                sorting: false,
                direction: "",
            },

            // {
            //     name: "modifiedDate",
            //     title: "modifiedDate",
            //     width: 150,
            //     sorting: false,
            //     direction: "",
            // },

        ],
    });
    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    beneId: (
                        <Box component="div" className="BBPDTSText">
                            {item.beneId}
                        </Box>
                    ),
                    name: (
                        <Box component="div" className="BBPDTSText">
                            {item.name}
                        </Box>
                    ),

                    account: (
                        <Box component="div" className="BBPDTSText">
                            {item.account}
                        </Box>
                    ),
                    ifsc: (
                        <Box component="div" className="BBPDTSText">
                            {item.ifsc}
                        </Box>
                    ),
                    address: (
                        <Box component="div" className="BBPDTSText">
                            {item.address}
                        </Box>
                    ),
                    email: (
                        <Box component="div" className="BBPDTSText">
                            {item.email}
                        </Box>
                    ),
                    mobile: (
                        <Box component="div" className="BBPDTSText">
                            {item.mobile}
                        </Box>
                    ),
                    city: (
                        <Box component="div" className="BBPDTSText">
                            {item.city}
                        </Box>
                    ),

                    state: (
                        <Box component="div" className="BBPDTSText">
                            {item.state}
                        </Box>
                    ),
                    pincode: (
                        <Box component="div" className="BBPDTSText">
                            {item.pincode}
                        </Box>
                    ),
                    status: (
                        <Box component="div" className="BBPDTSText">
                            {item.status}
                        </Box>
                    ),
                    bankCode: (
                        <Box component="div" className="BBPDTSText">
                            {item.bankCode}
                        </Box>
                    ),
                    bankBCode: (
                        <Box component="div" className="BBPDTSText">
                            {item.bankBCode}
                        </Box>
                    ),
                    partnerId: (
                        <Box component="div" className="BBPDTSText">
                            {item.partnerId}
                        </Box>
                    ),
                    remark: (
                        <Box component="div" className="BBPDTSText">
                            {item.remark}
                        </Box>
                    ),
                    createdDate: (
                        <Box component="div" className="BBPDTSText">
                            {format(new Date(item.createdDate), "dd-MM-yyyy HH:mm:ss")}
                        </Box>
                    ),
                    modifiedDate: (
                        <Box component="div" className="BBPDTSText">
                            {item.modifiedDate}
                        </Box>
                    ),
                })
            );
        }
        return tempRowArray;
    }
    const [rowData, setRowData] = useState([]);
    const [errorDialog, setErrorDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState("0");
    const [limitData] = useState("20");
    const [apiParams] = useState({ startDate: "", endDate: "", page: "", size: "" });
    const handlePaginate = (value) => {
        setSelectedPage(String(value - 1));
        let prm = apiParams;
        prm.startDate = selectedRange.startDate;
        prm.endDate = selectedRange.endDate;
        prm.page = String(value - 1);
        prm.size = limitData
        fetchAllPendingBeneficiary(prm)
    }

    const handleSearch = () => {

    }

    const axiosPrivate = useAxiosPrivate();
    const inputFile = useRef(null);

    const handleInputCBFile = async (e) => {

    };


    const fetchAllPendingBeneficiary = useCallback(async (params) => {
        try {
            const data = {
                PHONE: auth.auth.username,
                CHECKSUM: "",
                STARTDATE: format(startOfDay(params.startDate), "yyyy-MM-dd HH:mm:ss"),
                ENDDATE: format(endOfDay(params.endDate), "yyyy-MM-dd HH:mm:ss"),
                PAGE: params.page,
                SIZE: limitData,
            }
            setLoading(true);
            let url = `/payout/api/v1/bene/pending`;
            let options = {
                method: "POST",
                url,
                data
            };
            await axiosPrivate(options)
                .then((response) => {
                    if (response.data.STATUS === "SUCCESS") {
                        console.log("")
                        setRowData(response.data.DATA.content);
                        setTotalData(response.data.DATA.totalElements);
                        setLoading(false);
                    } else {
                        setRowData([]);
                        setTotalData(0);
                        setLoading(false);
                    }
                    // setDataStartDate(new Date(response.data.firstDate));
                })
                .catch((err) => {
                    if (err.response) {
                        setLoading(false);
                        setErrorDialog(true);
                        // console.error('err.res', err.response.data);
                    }
                });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
        }
    }, [])

    function hasPartnerRole(roles) {
        return roles.includes("Partner");
    }
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    });
    useEffect(() => {
        if (!hasPartnerRole(auth.auth.role)) {
            let prm = apiParams;
            prm.startDate = selectedRange.startDate;
            prm.endDate = selectedRange.endDate;
            prm.page = selectedPage;
            prm.size = limitData;
            setSelectedPage("0")
            fetchAllPendingBeneficiary(prm);
        }
    }, [])

    const handleDateRangeChange = (range) => {
        setSelectedRange(range);
        let prm = apiParams;
        prm.startDate = range.startDate;
        prm.endDate = range.endDate;
        prm.page = '0';
        prm.size = limitData
        setSelectedPage('0');
        fetchAllPendingBeneficiary(prm);
    };


    const handleDateRangeReset = () => {
        let prm = apiParams;
        prm.startDate = new Date();
        prm.endDate = new Date();
        prm.page = '0';
        prm.size = limitData
        console.log(prm)
        setSelectedPage('0')
        setSelectedRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        });
        fetchAllPendingBeneficiary(prm);
    };
    return (
        <Fragment>
            {/* <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} /> */}
            {/* <Backdrops open={onDownloadLoading} title={"Downloading"} /> */}
            <Box component="div" className={"BBPTransaction"}>
                <Box component="div" className={"BBPTHead"}>
                    <Box component="div" className="BBPSearchMain">
                    </Box>
                    <SearchBox
                        onSearchChange={handleSearch}
                        placeholder={"Search Transaction"}
                    />
                    <Box
                        component="div"
                        className={"BBPTHBtn"}
                        style={{ alignItems: "center" }}
                    >

                        <DateRangePicker
                            buttonTitle={"Period"}
                            ranges={[selectedRange]}
                            dataStartDate={new Date('2024-09-01')} // Example start date
                            title="Select Range"
                            onChange={handleDateRangeChange}
                            onReset={handleDateRangeReset}
                        />
                        {/* <Button
                        variant="contained"
                        className={"BBPTHBD"}
                        onClick={handleCSVDownload}
                        disabled={loading || totalData === 0}
                    >
                        Download
                    </Button> */}
                    </Box>




                </Box>
                <Box component="div" className={"BBPTBody"}>
                    <DevTable
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        selectedPage={Number(selectedPage) + 1}
                        handlePagination={handlePaginate}
                        loading={loading}
                        // handleSort={handleSorting}
                        limitData={Number(limitData)}
                        totalData={totalData}
                    />
                </Box>
            </Box>
        </Fragment>
    )
}

export default PendingBeneficiary