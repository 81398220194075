import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import Backdrops from '../../Components/Backdrops'
import Box from "@mui/material/Box";
import DevTable from '../../Components/DevTable';
import SearchBox from '../../Components/SearchBox';
import toast, { ErrorIcon } from 'react-hot-toast';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import fileIcon from "../../Assets/Images/uploadIcon.png";
import { Button, Tooltip } from '@mui/material';
import DateRangePicker from "../../Components/DateRangePicker/index"
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Hooks/useAuth';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import UploadCSVDialog from '../../Components/UploadCSV';
import RefundSampleFile from "../../Assets/csv/SampleUploadBeneficiary.xlsx"
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import * as XLSX from 'xlsx';
import DateIcon from "../../Assets/Images/date.png";
import { endOfDay, format, startOfDay } from 'date-fns';
import UploadCSVDialogXslx from '../../Components/UploadXslx';
import DatePicker from "react-datepicker";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ViewBeneficiary from './viewBeneficiary';
import { arrayEquals } from "../../Utils";
const AllBeneficiary = () => {
    const { auth } = useAuth();
    const [mainColumns, setMainColumns] = useState({
        columns: [
            // {
            //     name: "createdDate",
            //     title: "Date",
            //     width: 150,
            //     sorting: false,
            //     direction: "",
            // },
            {
                name: "beneId",
                title: "Id",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "name",
                title: "Name",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "mobile",
                title: "Mobile No",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "account",
                title: " Account",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "ifsc",
                title: "IFSC",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "email",
                title: "Email",
                width: 150,
                sorting: false,
                direction: "",
            },

            {
                name: "address",
                title: "address",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "city",
                title: "City",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "state",
                title: "state",
                width: 150,
                sorting: false,
                direction: "",
            },

            {
                name: "pincode",
                title: "pincode",
                width: 150,
                sorting: false,
                direction: "",
            },

            {
                name: "status",
                title: "Status",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "bankCode",
                title: "bankCode",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "bankBCode",
                title: "bankBCode",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "remark",
                title: "remark",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "partnerId",
                title: "partnerId",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "action",
                title: "Action",
                width: 150,
                sorting: false,
                direction: "",
            },
        ],
    });
    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    createdDate: (
                        <Box component="div" className="BBPDTSText">
                            {item.createdDate}
                        </Box>
                    ),
                    beneId: (
                        <Box component="div" className="BBPDTSText">
                            {item.beneId}
                        </Box>
                    ),

                    name: (
                        <Box component="div" className="BBPDTSText">
                            {item.name}
                        </Box>
                    ),
                    account: (
                        <Box component="div" className="BBPDTSText">
                            {item.account}
                        </Box>
                    ),
                    ifsc: (
                        <Box component="div" className="BBPDTSText">
                            {item.ifsc}
                        </Box>
                    ),
                    address: (
                        <Box component="div" className="BBPDTSText">
                            {item.address}
                        </Box>
                    ),
                    email: (
                        <Box component="div" className="BBPDTSText">
                            {item.email}
                        </Box>
                    ),
                    mobile: (
                        <Box component="div" className="BBPDTSText">
                            {item.mobile}
                        </Box>
                    ),
                    city: (
                        <Box component="div" className="BBPDTSText">
                            {item.city}
                        </Box>
                    ),
                    state: (
                        <Box component="div" className="BBPDTSText">
                            {item.state}
                        </Box>
                    ),

                    pincode: (
                        <Box component="div" className="BBPDTSText">
                            {item.pincode}
                        </Box>
                    ),
                    status: (
                        <Box component="div" className="BBPDTSText">
                            {getStatus(item.status)}
                        </Box>
                    ),
                    bankCode: (
                        <Box component="div" className="BBPDTSText">
                            {item.bankCode}
                        </Box>
                    ),
                    bankBCode: (
                        <Box component="div" className="BBPDTSText">
                            {item.bankBCode}
                        </Box>
                    ),
                    partnerId: (
                        <Box component="div" className="BBPDTSText">
                            {item.partnerId}
                        </Box>
                    ),
                    remark: (
                        <Box component="div" className="BBPDTSText">
                            {item.remark}
                        </Box>
                    ),
                    action: (
                        <Box component="div" className="BBPDTSText">
                            <Box>
                                <Box style={{ display: "flex", gap: "0.5rem" }}>
                                    <span
                                        onClick={() => { handleOpenBeneficiaryDialog(item.account, item.ifsc) }}
                                    >
                                        <RemoveRedEyeOutlinedIcon style={{ color: "#175783", fontSize: "medium" }} />
                                    </span>
                                </Box>
                            </Box>
                        </Box>
                    ),
                })
            );
        }
        return tempRowArray;
    }
    const getStatus = (value) => {
        if (value === "A") {
            return "Approved"
        } else if (value === "PA") {
            return "Pending Approval"
        } else {
            return "";
        }
    }
    const [selectedUrl, setSelectedUrl] = useState('Beneficiary')
    const [currentUrl, setCurrentUrl] = useState("AllBeneficiary")
    const [rowData, setRowData] = useState([]);
    const [errorDialog, setErrorDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState("0");
    const [limitData] = useState("20");
    const [fileData, setFileData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(
        format(new Date(), "dd-MMM-yyyy")
    );
    const [apiParams] = useState({ startDate: "", endDate: "", page: "", size: "" });
    const handlePaginate = (value) => {
        console.log(value)
        console.log(String(value - 1))
        console.log(typeof (String(value - 1)))
        setSelectedPage(String(value - 1));
        let prm = apiParams;
        prm.startDate = selectedRange.startDate;
        prm.endDate = selectedRange.endDate;
        prm.page = String(value - 1);
        prm.size = limitData
        prm.url = selectedUrl;
        fetchAllBeneficiary(prm);
    }
    const onChangeApiUrl = (e) => {
        console.log(e.target.value)
        setSelectedUrl(e.target.value);
        setSelectedDate(format(new Date(), "dd-MMM-yyyy"));
        let prm = apiParams;
        prm.startDate = new Date();
        prm.endDate = new Date();
        prm.page = selectedPage;
        prm.size = limitData;
        prm.url = e.target.value
        fetchAllBeneficiary(prm);
    }

    const handleRefresh = () => {
        let prm = apiParams;
        prm.startDate = new Date();
        prm.endDate = new Date();
        prm.page = selectedPage;
        prm.size = limitData;
        setSelectedPage("0")
        setSelectedDate(format(new Date(), "dd-MMM-yyyy"));
        fetchAllBeneficiary(prm);
    }

    const axiosPrivate = useAxiosPrivate();
    const inputFile = useRef(null);

    const handleInputCBFile = async (e) => {
        console.log("kfkmkm")
        console.log(e.target.files[0])
        const file = e.target.files[0];
        try {
            if (file) {
                const reader = new FileReader();

                reader.onload = async (e) => {
                    const ab = e.target.result;
                    const workbook = XLSX.read(ab, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];

                    // Convert sheet to JSON with headers as keys
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    const headers = jsonData[0]; // First row as headers
                    const rows = jsonData.slice(1); // Remaining rows

                    // Convert array of arrays to array of objects
                    const structuredData = rows.map(row => {
                        let obj = {};
                        row.forEach((cell, index) => {
                            obj[headers[index]] = String(cell);
                        });
                        return obj;
                    });
                    let errorFile = false
                    structuredData.forEach((item) => {
                        item.PHONE = auth.username;
                        item.CHECKSUM = "";
                        item.ACCOUNT = String(item.ACCOUNT)
                        if (item.IFSC.length < 11) {
                            errorFile = true
                            toast.error("Invalid Ifsc code .")
                        }
                    })
                    if (!errorFile) {
                        console.log(structuredData)
                        setFileData(structuredData);
                        let url = `/payout/api/v1/bene/addbulk`;
                        let options = {
                            method: "POST",
                            url,
                            data: structuredData
                        };
                        await axiosPrivate(options).then((response) => {
                            if (response.data.STATUS === "SUCCESS") {
                                toast.success("Beneficiary Added Successfully .")
                            } else {
                                toast.error("Invalid Information .")
                            }
                        }).catch((err) => {
                            console.log(err)
                            toast.error("Invalid Information .")
                        })

                    }
                };

                reader.readAsArrayBuffer(file);
            }
        } catch (error) {
            console.log(error)
        }

    };
    const navigate = useNavigate();
    const navigateAddBeneficiary = () => {
        navigate("/transaction/beneficiary/add")
    }
    const navigatePendingBen = () => {
        navigate("/transaction/beneficiary/pending")
    }
    const [beneficiaryUrl] = useState('/payout/api/v1/bene/view')
    const fetchAllBeneficiary = useCallback(async (params) => {
        try {
            const data = {
                PHONE: auth.username,
                STARTDATE: format(startOfDay(params.startDate), "yyyy-MM-dd HH:mm:ss"),
                ENDDATE: format(endOfDay(params.endDate), "yyyy-MM-dd HH:mm:ss"),
                PAGE: params.page,
                SIZE: params.size,
                // ACCOUNT:"6308451263",
                // IFSC:"BOB7485",
            }
            setLoading(true);
            let url;
            if (params.url === "PendingBeneficiary") {
                url = `/payout/api/v1/bene/pending`
            } else {
                url = `/payout/api/v1/bene/view`
            }
            let options = {
                method: "POST",
                url,
                data
            };
            await axiosPrivate(options)
                .then((response) => {
                    if (response.data.STATUS === "SUCCESS") {
                        setRowData(response.data.DATA.content);
                        // console.log(response.data.DATA.content)
                        setTotalData(response.data.DATA.totalElements);
                        setLoading(false);
                    } else {
                        setRowData([]);
                        setTotalData(0);
                        setLoading(false);
                    }
                    // setDataStartDate(new Date(response.data.firstDate));
                })
                .catch((err) => {
                    if (err.response) {
                        setLoading(false);
                        setErrorDialog(true);
                        // console.error('err.res', err.response.data);
                    }
                });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
        }
    }, [])

    function hasPartnerRole(roles) {
        return roles.includes("Partner");
    }

    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    });
    const handleDateRangeChange = (e) => {
        setSelectedDate(format(e, "dd-MMM-yyyy"));
        let prm = apiParams;
        prm.startDate = e;
        prm.endDate = e;
        prm.page = '0';
        prm.size = limitData
        setSelectedPage('0');
        fetchAllBeneficiary(prm);
    };

    const handleDateRangeReset = () => {
        let prm = apiParams;
        prm.startDate = new Date();
        prm.endDate = new Date();
        prm.page = '0';
        prm.size = limitData
        prm.url = selectedUrl
        console.log(prm)
        setSelectedPage('0')
        setSelectedRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        });
        fetchAllBeneficiary(prm);
    };

    useEffect(() => {
        if (!hasPartnerRole(auth.role)) {
            let prm = apiParams;
            prm.startDate = selectedRange.startDate;
            prm.endDate = selectedRange.endDate;
            prm.page = selectedPage;
            prm.size = limitData;
            setSelectedPage("0")
            fetchAllBeneficiary(prm);
        }
    }, [])

    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const openUploadCsv = () => {
        setOpenUploadDialog(true);
    }
    const closeUploadCsv = () => {
        setOpenUploadDialog(false);
    }
    const [fileProgress, setFileProgress] = useState(true);
    const [jsonHeaderError, setJsonHeaderError] = useState({ err: false, success: false, arr: [] });
    const [uploadFile, setUploadFile] = useState();
    const [nextButton, setNextButton] = useState(false);
    const [tableDataErr, setTableDataErr] = useState(0);
    const [tableHeader, setTableHeader] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [apiSendingData, setApiSendingData] = useState([]);
    const [duplicateData, setDuplicateData] = useState(0);
    const [loadingcsv, setLoadingcsv] = useState(false);
    const [apiResponse, setApiResponse] = useState({});
    const jsonHeader = ['ACCOUNT', 'IFSC', 'NAME', 'ADDRESS', 'EMAIL',
        'MOBILE', 'CITY', 'STATE', 'PINCODE']

    const uploadJSON = useCallback(async (data) => {
        console.log(data)
        const uppercaseKeys = (arr) => {
            return arr.map(obj => {
                return Object.fromEntries(
                    Object.entries(obj).map(([key, value]) => [key.toUpperCase(), value])
                );
            });
        };
        console.log(uppercaseKeys(data))
        const updateData = uppercaseKeys(data);
        let errorFile = false
        updateData.forEach((item) => {
            item.PHONE = auth.username;
            item.CHECKSUM = "";
            // item.ACCOUNT = String(item.ACCOUNT);
            if (item.IFSC.length < 11) {
                errorFile = true
                toast.error("Invalid Ifsc code .")
            }

        })

        if (!errorFile) {
            console.log(updateData)
            setFileData(updateData);
            let url = `/payout/api/v1/bene/addbulk`;
            let options = {
                method: "POST",
                url,
                data: updateData
            };
            await axiosPrivate(options).then((response) => {
                if (response.data.STATUS === "SUCCESS") {
                    toast.success();
                    closeUploadCsv()
                } else {
                    toast.error("Invalid Information .")
                }
            }).catch((err) => {
                console.log(err)
                toast.error("Invalid Information .")
            })

        }

    }, [])

    const handleValidJson = (json) => {
        console.log(json)
        setLoadingcsv(false);
        if (json.data) {
            if (arrayEquals(jsonHeader, json.data[0])) {
                let tempData = [];
                let tempError = [];
                let tempHeader = [];
                let tempRowArray = [];

                jsonHeader.map((item) => tempHeader.push({
                    name: (item.toLowerCase()).replace(/\s/g, ''),
                    title: item,
                    width: 200,
                    sorting: false,
                    direction: ''
                }));
                console.log(tempHeader)
                setTableHeader(tempHeader);
                let allData = [];
                json.data.map((item, index) => {
                    console.log(item)
                    if (index > 0) {
                        console.log("index section")
                        let tempObj = {};
                        let error = false;
                        tempObj[tempHeader[0].name] = item[0]
                        //   .trim();
                        tempObj[tempHeader[1].name] = item[1]
                        //   .trim();
                        tempObj[tempHeader[2].name] = item[2]
                        //   .trim();
                        tempObj[tempHeader[3].name] = item[3]
                        //   .trim();
                        tempObj[tempHeader[4].name] = item[4]
                        //   .trim();
                        tempObj[tempHeader[5].name] = item[5]
                        //   .trim();
                        tempObj[tempHeader[6].name] = item[6]
                        //   .trim();
                        tempObj[tempHeader[7].name] = item[7]
                        //   .trim();
                        tempObj[tempHeader[8].name] = item[8]
                        //   .trim();
                        tempObj['error'] = error;
                        allData.push(tempObj);

                    }
                    console.log(allData)
                })

                let objectFound;
                const normalizeValue = (value) => (value !== undefined ? value : "");
                const checkDuplicateData = allData.reduce((accumulator, object) => {
                    // eslint-disable-next-line
                    if (objectFound = accumulator.find(arrItem => arrItem.account === object.account && arrItem.error === object.error)) {
                        objectFound.times++;
                        accumulator.push(object);
                    } else {
                        object.times = 1;
                        object.dup = false;
                        accumulator.push(object);
                    }
                    return accumulator;
                }, []);



                checkDuplicateData.map(item => {
                    let temObj = {};
                    let temTableObj = {};
                    let error = false;

                    console.log(item);
                    if (item.account?.length > 0 || item.account != undefined) {
                        console.log("account")
                        temObj['account'] = item.account;
                        temTableObj[tempHeader[0].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.account}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[0].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.account !== undefined || item.account?.length === 0 ? `${tempHeader[0].title} Not Be Empty` : ''
                            }
                        >
                            <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                <Box component="div" className="BBPDTSEText">{item.account !== undefined ? item.account : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip >;
                    }

                    if (item.ifsc?.length > 0) {
                        console.log("ifsc")
                        temObj['ifsc'] = item.ifsc;
                        temTableObj[tempHeader[1].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.ifsc}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[1].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.ifsc !== undefined ? `${tempHeader[1].title} Not Be Empty` : 'Only Enter "rrn" '}
                        >
                            <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                <Box component="div" className="BBPDTSEText">{item.ifsc !== undefined ? item.ifsc : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }
                    if (item.name?.length > 0) {
                        console.log("account")
                        temObj['name'] = item.name;
                        temTableObj[tempHeader[2].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.name}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[2].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.name !== undefined ? `${tempHeader[2].title} Not Be Empty` : 'Only Enter "rrn" '}
                        >
                            <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                <Box component="div" className="BBPDTSEText">{item.name !== undefined ? item.name : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }

                    if (item.address?.length > 0) {
                        console.log("add")
                        temObj['address'] = item.address;
                        temTableObj[tempHeader[3].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.address}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[3].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.address !== undefined ? `${tempHeader[3].address} Not Be Empty` : 'Only Enter "rrn" '}
                        >
                            <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                <Box component="div" className="BBPDTSEText">{item.address !== undefined ? item.address : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }


                    if (item.email?.length > 0) {
                        console.log("email")
                        temObj['email'] = item.email;
                        temTableObj[tempHeader[4].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.email}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[4].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.email !== undefined ? `${tempHeader[4].email} Not Be Empty` : 'Only Enter "rrn" '}
                        >
                            <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                <Box component="div" className="BBPDTSEText">{item.email !== undefined ? item.email : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }


                    if (item.mobile?.length > 0) {
                        console.log("mobile")
                        temObj['mobile'] = item.mobile;
                        temTableObj[tempHeader[5].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.mobile}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[5].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.mobile == undefined ? `${tempHeader[5].mobile} Not Be Empty` : 'Only Enter "rrn" '}
                        >
                            <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                <Box component="div" className="BBPDTSEText">{item.mobile !== undefined ? item.mobile : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }





                    if (item.city?.length > 0) {
                        console.log("city")
                        temObj['city'] = item.city;
                        temTableObj[tempHeader[6].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.city}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[6].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.city !== undefined ? `${tempHeader[6].city} Not Be Empty` : 'Only Enter "rrn" '}
                        >
                            <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                <Box component="div" className="BBPDTSEText">{item.city !== undefined ? item.city : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }
                    if (item.state?.length > 0) {
                        console.log("state")
                        temObj['state'] = item.state;
                        temTableObj[tempHeader[7].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.state}</Box>;
                    } else {
                        error = true;
                        temTableObj[tempHeader[7].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.state !== undefined ? `${tempHeader[7].state} Not Be Empty` : 'Only Enter "rrn" '}
                        >
                            <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                <Box component="div" className="BBPDTSEText">{item.state !== undefined ? item.state : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }
                    if (item.pincode?.length > 0) {
                        console.log("pincode")
                        temObj['pincode'] = item.pincode;
                        console.log(temObj)
                        temTableObj[tempHeader[8].name] = <Box component="div" className={`BBPDTSText ${!item.hasOwnProperty('times') && !item.error ? 'BBPDTSWarn' : ''}`}>{item.pincode}</Box>;
                        console.log(temTableObj)
                    } else {
                        error = true;
                        temTableObj[tempHeader[8].name] = <Tooltip
                            placement="top"
                            classes={{
                                popper: 'BBPTPopper',
                                tooltip: 'BBPTooltip'
                            }}
                            title={item.pincode !== undefined ? `${tempHeader[8].pincode} Not Be Empty` : 'Only Enter "rrn" '}
                        >
                            <Box component="div" className={`BBPDTSText BBPDTSErr`}>
                                <Box component="div" className="BBPDTSEText">{item.pincode !== undefined ? item.pincode : '---'}</Box>
                                <ErrorIcon fontSize="inherit" />
                            </Box>
                        </Tooltip>;
                    }

                    if (error || !item.hasOwnProperty('times')) {
                        temTableObj['err'] = true;
                    } else {
                        temTableObj['err'] = error;
                    }
                    console.log(temTableObj)
                    tempRowArray.push(temTableObj);
                    if (error) {
                        tempError.push(error);
                    } else {
                        tempData.push(temObj);
                    }
                });

                const newArrayOfObjects =
                    tempData.reduce((accumulator, object) => {
                        if (!accumulator.find(arrItem => arrItem.account === object.account && arrItem.name === object.name)) {
                            accumulator.push(object);
                        }
                        return accumulator;
                    }, []);

                if (tempError.length > 0) {
                    setTableDataErr(tempError.length);
                } else {
                    setTableDataErr(0);
                }

                setDuplicateData(tempData.length - newArrayOfObjects.length);
                setApiSendingData(newArrayOfObjects);
                console.log(newArrayOfObjects)

                if (tempRowArray.length === 0) {
                    setNextButton(true);
                    setJsonHeaderError({ err: true, success: false, arr: ['Data not found'] });
                } else {
                    setNextButton(false);
                    setJsonHeaderError({ err: false, success: true, arr: [] });
                }
                console.log(tempRowArray)
                setTableData(tempRowArray);
                setFileProgress(false);



            }
        } else {
            setJsonHeaderError({ error: false, success: false, arr: [] });
            setNextButton(true);
            setTableData([]);
        }
    }
    const handleUploadJson = () => {
        console.log(apiSendingData)
        uploadJSON(apiSendingData);
    }

    // const handleLabelClick = (event) => {
    //     event.preventDefault(); // Prevent default behavior of the label
    //     event.stopPropagation(); // Stop event propagation to prevent it from reaching the input field

    //     if (inputFile.current && !inputFile.current.files.length) {
    //         inputFile.current.click();
    //     }
    // };

    const downloadCsv = () => {
        const url = RefundSampleFile;
        const a = document.createElement('a');
        a.href = url;
        a.download = `BulkbeneficiarySample${format(new Date(), 'yyyyMMdd_HHmmss')}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); // Clean up the URL object
    };
    const datePickerRef = useRef(null);
    const handleImageClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(true); // Open the date picker programmatically
        }
    };
    const [benficiaryDetails, setBeneficiaryDetails] = useState();
    const [showBenficiaryDetails, setShowBeneficiaryDetails] = useState(false);
    const closeBenficiaryDialog = () => {
        setShowBeneficiaryDetails(false)
    }
    const handleOpenBeneficiaryDialog = async (account, ifsc) => {
        console.log(ifsc + account)
        setShowBeneficiaryDetails(true);
        try {
            setLoading(true);
            const data = {
                ACCOUNT: account,
                IFSC: ifsc,
                PHONE: auth.username
            }
            let url = `/payout/api/v1/bene/view`
            let options = {
                method: "POST",
                url,
                data
            };

            await axiosPrivate(options)
                .then((response) => {
                    if (response.data.STATUS === "SUCCESS") {
                        setBeneficiaryDetails(response.data.DATA);
                        console.log(response.data.DATA)
                    } else {
                        console.error("err.res", response.data);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    if (error.response) {
                        setLoading(false);
                        console.error("error", error);
                    }
                });

        } catch (error) {
            setShowBeneficiaryDetails(false);
        }
    }
    return (
        <Fragment>
            <UploadCSVDialogXslx
                open={openUploadDialog}
                onClose={closeUploadCsv}
                jsonHeader={jsonHeader}
                handleValidJson={handleValidJson}
                fileProgress={fileProgress}
                setFileProgress={setFileProgress}
                jsonHeaderError={jsonHeaderError}
                uploadFile={uploadFile}
                setUploadFile={setUploadFile}
                nextButton={nextButton}
                setNextButton={setNextButton}
                tableDataErr={tableDataErr}
                tableHeader={tableHeader}
                tableData={tableData}
                duplicateData={duplicateData}
                loading={loadingcsv}
                apiResponse={apiResponse}
                handleUploadJson={handleUploadJson}
                backButtonTitle={'Back to Billers'}
                CSVFile={RefundSampleFile}
            />
            <ViewBeneficiary onClose={closeBenficiaryDialog} open={showBenficiaryDetails} data={benficiaryDetails} />
            {/* <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} /> */}
            {/* <Backdrops open={onDownloadLoading} title={"Downloading"} /> */}
            <Box component="div" className={"BBPTransaction"}>
                <Box component="div" className={"BBPTHead"}>
                    <Box component="div" className="BBPSearchMain">
                    </Box>
                    {/* <SearchBox
                        onSearchChange={handleSearch}
                        placeholder={"Search Transaction"}

                    /> */}

                    <Box component="div" className="BBPSearchMain">
                        <Box
                            component="div"
                            className="BBPSelectDiv"
                            style={{ width: "14rem" }}
                        >
                            <select
                                className="BBPSBInput"
                                value={selectedUrl}
                                onChange={onChangeApiUrl}
                            >
                                <option >Beneficiary Status</option>
                                <option value={"Beneficiary"}>Approved Beneficiary</option>
                                <option value={"PendingBeneficiary"}>Pending Beneficiary</option>

                            </select>
                        </Box>
                    </Box>
                    {/* <Box component="div" className="uploadFileCont" onClick={navigatePendingBen}>
                        <label
                            // htmlFor="cbFileUpload"
                            className="labelContFu"
                        // style={{ display: "flex", cursor: "pointer", alignItems:"center" ,  }}
                        >
                            <p style={{ margin: "0rem", fontSize: "12px" }}>Pending Beneficiary's</p>
                            <img src={fileIcon} alt="File icon" />
                        </label>

                    </Box> */}
                    <Box component="div" className="uploadFileCont" onClick={navigateAddBeneficiary}>
                        <label
                            // htmlFor="cbFileUpload"
                            className="labelContFu"
                        // style={{ display: "flex", cursor: "pointer", alignItems:"center" ,  }}
                        >
                            <p style={{ margin: "0rem", fontSize: "12px" }}>Single</p>
                            <img src={fileIcon} alt="File icon" />
                        </label>

                    </Box>
                    {/* <Box component="div" className="uploadFileCont">
                        <label
                            htmlFor="cbFileUpload"
                            // onClick={handleLabelClick}
                            className="labelContFu"
                        // style={{ display: "flex", cursor: "pointer", alignItems:"center" ,  }}
                        >
                            <p style={{ margin: "0rem", fontSize: "12px" }}>Bulk  </p>
                            <img src={fileIcon} alt="File icon" />
                        </label>
                        <input
                            type="file"
                            id="cbFileUpload"
                            ref={inputFile}
                            style={{ display: "none" }}
                            onChange={handleInputCBFile}
                        />
                    </Box> */}
                    {/* <Box component="div" className="uploadFileCont" onClick={downloadCsv}>
                        <p style={{ margin: "0rem", fontSize: "14px" }}>Sample File</p>

                        <FileDownloadOutlinedIcon style={{ color: "#175781" }} />
                    </Box> */}

                    <Box component="div" className="uploadFileCont" onClick={openUploadCsv}>
                        <p style={{ margin: "0rem", fontSize: "14px" }}>Bulk </p>

                        <FileDownloadOutlinedIcon
                            style={{ color: "#175781" }} />
                    </Box>
                    <Box
                        component="div"
                        className={"BBPTHBtn"}
                        style={{ alignItems: "center" }}
                    >
                        <AutorenewOutlinedIcon
                            style={{ color: "#175783" }}
                            onClick={handleRefresh}
                        />
                        <p id="blueP" style={{ margin: "0px 10px" }}>
                            Data as of {selectedDate}{" "}
                        </p>
                        <Box className="calenderDiv">
                            <DatePicker
                                ref={datePickerRef}
                                selected={selectedDate}
                                onChange={handleDateRangeChange}
                                dateFormat="dd/MM/yyyy"
                                className="calenderIcon"
                                popperPlacement="top-start"
                            />

                        </Box>
                        <img
                            src={DateIcon}
                            className="imgDateIcon"
                            onClick={handleImageClick}
                        // style={{paddingRight:"10px"}}
                        />



                        {/* <DateRangePicker
                            buttonTitle={"Period"}
                            ranges={[selectedRange]}
                            dataStartDate={new Date('2024-09-01')} // Example start date
                            title="Select Range"
                            onChange={handleDateRangeChange}
                            onReset={handleDateRangeReset}
                        /> */}

                        {/* <Button
                            variant="contained"
                            className={"BBPTHBD"}
                            onClick={handleCSVDownload}
                            disabled={loading || totalData === 0}
                        >
                            Download
                        </Button> */}
                    </Box>




                </Box>
                <Box component="div" className={"BBPTBody"}>
                    <DevTable
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        selectedPage={Number(selectedPage) + 1}
                        handlePagination={handlePaginate}
                        loading={loading}
                        // handleSort={handleSorting}
                        limitData={Number(limitData)}
                        totalData={totalData}
                    />
                </Box>
            </Box>
        </Fragment>
    )
}

export default AllBeneficiary