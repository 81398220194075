import * as React from "react";
import SvgIcon from '@mui/material/SvgIcon';
const Broadcast = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fontSize="inherit">
      <path d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z" fill="#7E84A3"/>
      <path d="M8.69992 15.75C8.83627 15.947 8.9011 16.1848 8.88367 16.4238C8.86623 16.6628 8.76757 16.8886 8.60406 17.0638C8.44055 17.239 8.22204 17.353 7.98481 17.3869C7.74758 17.4207 7.50591 17.3724 7.29992 17.25C6.57584 16.5805 5.99807 15.7685 5.60292 14.865C5.20777 13.9616 5.00378 12.9861 5.00378 12C5.00378 11.0138 5.20777 10.0384 5.60292 9.13489C5.99807 8.2314 6.57584 7.41941 7.29992 6.74997C7.50591 6.62752 7.74758 6.57922 7.98481 6.61308C8.22204 6.64693 8.44055 6.76092 8.60406 6.93611C8.76757 7.11129 8.86623 7.33714 8.88367 7.57613C8.9011 7.81513 8.83627 8.05291 8.69992 8.24997C8.16789 8.71918 7.74178 9.29623 7.44992 9.94278C7.15805 10.5893 7.00711 11.2906 7.00711 12C7.00711 12.7093 7.15805 13.4106 7.44992 14.0572C7.74178 14.7037 8.16789 15.2808 8.69992 15.75Z" fill="#7E84A3"/>
      <path d="M19 12C19.0025 12.9869 18.7993 13.9635 18.4033 14.8674C18.0073 15.7713 17.4272 16.5828 16.7 17.25C16.494 17.3724 16.2523 17.4207 16.0151 17.3869C15.7778 17.353 15.5593 17.239 15.3958 17.0639C15.2323 16.8887 15.1337 16.6628 15.1162 16.4238C15.0988 16.1848 15.1636 15.9471 15.3 15.75C15.832 15.2808 16.2581 14.7037 16.55 14.0572C16.8418 13.4106 16.9928 12.7094 16.9928 12C16.9928 11.2906 16.8418 10.5894 16.55 9.94281C16.2581 9.29625 15.832 8.7192 15.3 8.25C15.1651 8.16984 15.0499 8.06048 14.9629 7.92996C14.8759 7.79944 14.8192 7.65108 14.797 7.49578C14.7749 7.34048 14.7878 7.18219 14.8348 7.03254C14.8819 6.88288 14.9619 6.74567 15.0689 6.63099C15.1759 6.51631 15.3073 6.42706 15.4534 6.36982C15.5994 6.31258 15.7564 6.28879 15.9129 6.30021C16.0694 6.31162 16.2213 6.35795 16.3575 6.43579C16.4937 6.51362 16.6107 6.62099 16.7 6.75C17.4272 7.41718 18.0073 8.22864 18.4033 9.13259C18.7993 10.0365 19.0025 11.0131 19 12Z" fill="#7E84A3"/>
      <path d="M23 12C23.0049 13.4408 22.7146 14.8673 22.147 16.1916C21.5795 17.5159 20.7467 18.7099 19.7 19.7C19.5074 19.8444 19.2692 19.9145 19.0291 19.8975C18.789 19.8804 18.5631 19.7773 18.3929 19.6071C18.2227 19.4369 18.1196 19.211 18.1025 18.9709C18.0854 18.7308 18.1555 18.4925 18.3 18.3C19.1532 17.4874 19.8324 16.51 20.2965 15.4271C20.7607 14.3441 21 13.1782 21 12C21 10.8218 20.7607 9.65583 20.2965 8.57288C19.8324 7.48993 19.1532 6.51254 18.3 5.69998C18.1555 5.5074 18.0854 5.2692 18.1025 5.02909C18.1196 4.78898 18.2227 4.56308 18.3929 4.39287C18.5631 4.22266 18.789 4.11956 19.0291 4.10249C19.2692 4.08543 19.5074 4.15555 19.7 4.29998C20.7467 5.29009 21.5795 6.48409 22.147 7.80839C22.7146 9.13268 23.0049 10.5592 23 12Z" fill="#7E84A3"/>
      <path d="M5.69997 18.3C5.8444 18.4925 5.91452 18.7308 5.89745 18.9709C5.88039 19.211 5.77729 19.4369 5.60707 19.6071C5.43686 19.7773 5.21097 19.8804 4.97086 19.8975C4.73075 19.9145 4.49254 19.8444 4.29997 19.7C3.25931 18.7056 2.43101 17.5106 1.86511 16.1872C1.29921 14.8637 1.00745 13.4393 1.00745 12C1.00745 10.5606 1.29921 9.13621 1.86511 7.81277C2.43101 6.48934 3.25931 5.29434 4.29997 4.29998C4.49254 4.15555 4.73075 4.08543 4.97086 4.10249C5.21097 4.11956 5.43686 4.22266 5.60707 4.39287C5.77729 4.56308 5.88039 4.78898 5.89745 5.02909C5.91452 5.2692 5.8444 5.5074 5.69997 5.69998C4.84678 6.51254 4.16753 7.48993 3.70341 8.57288C3.23928 9.65583 2.99997 10.8218 2.99997 12C2.99997 13.1782 3.23928 14.3441 3.70341 15.4271C4.16753 16.51 4.84678 17.4874 5.69997 18.3Z" fill="#7E84A3"/>
    </SvgIcon>
  );
};

export default Broadcast;
