import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useRef,
} from "react";
import { useAxiosPrivate } from "../../Hooks/useAxiosPrivate";
import { usePapaParse } from "react-papaparse";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SearchBox from "../../Components/SearchBox";
import DevTable from "../../Components/DevTable";
import DateRangePicker from "../../Components/DateRangePicker";
import SomethingWentWrong from "../../Components/SomethingWentWrong";
import SelectOption from "../../Components/FormFields/SelectOption";
import Backdrops from "../../Components/Backdrops";
import { format, sub, parse } from "date-fns";
import DatePicker from "react-datepicker";
import DateIcon from "../../Assets/Images/date.png";
import { useAuth } from "../../Hooks/useAuth";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
function parseIcsDate(icsDate) {
  if (icsDate) {
    var year = icsDate.substr(0, 4);
    var month = icsDate.substr(4, 2);
    var day = icsDate.substr(6, 2);

    var hour = icsDate.substr(8, 2);
    var minute = icsDate.substr(10, 2);
    var second = icsDate.substr(12, 2);

    let tempDate = new Date(year, month - 1, day, hour, minute, second);

    let formatDate = format(tempDate, "dd-MM-yyyy HH:mm:ss");
    return formatDate;
  } else {
    return "";
  }
}

const DigiSwarna = () => {
  const { jsonToCSV } = usePapaParse();
  const auth = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataStartDate, setDataStartDate] = useState();
  const [totalData, setTotalData] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [limitData] = useState(20);
  const [onDownloadLoading, setOnDownloadLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    format(sub(new Date(), { days: 1 }), "dd-MMM-yyyy")
  );
  const [rowData, setRowData] = useState([]);
  const [selectedSearchCat, setSelectedSearchCat] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [merchantList, setMerchantList] = useState([]);
  const [apiParams] = useState({
    pageSize: limitData,
    tranDate: format(sub(new Date(), { days: 1 }), "yyyy-MM-dd"),
    // tranDate: "2024-03-13",
  });
  const datePickerRef = useRef(null);
  const [mainColumns, setMainColumns] = useState({
    columns: [
      {
        name: "tranDate",
        title: "Txn Date Time",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "amount",
        title: "AMOUNT",
        width: 50,
        sorting: false,
        direction: "",
      },
      {
        name: "merchantMdr",
        title: "Merchant Mdr",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "settAmt",
        title: "Net Settlement",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "status",
        title: "STATUS",
        width: 50,
        sorting: false,
        direction: "",
      },
      {
        name: "txnId",
        title: "Txn Id",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "pvpa",
        title: "Payer Vpa",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "pmobile",
        title: "Mob Num",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "rrn",
        title: "RRN",
        width: 150,
        sorting: false,
        direction: "",
      },
      {
        name: "refId",
        title: "Ref Id",
        width: 150,
        sorting: false,
        direction: "",
      },
    ],
  });

  function hasPartnerRole(roles) {
    return roles.includes("Partner");
  }

  const fetchTransactions = useCallback(async (params, payee) => {
    try {
      setLoading(true);
      let urlParams = "";
      if (params) {
        Object.keys(params).forEach(function (key, index) {
          urlParams += (index === 0 ? "?" : "&") + key + "=" + params[key];
        });
      }

      let url = hasPartnerRole(auth.auth.role)
        ? payee
          ? `/txn/get-partner-fino-recon-report/${payee}${urlParams}`
          : `/txn/get-partner-fino-recon-report/:${auth.auth.payee}${urlParams}`
        : `txn/get-merchant-fino-recon-report${urlParams}`;
      // let url = `txn/get-merchant-fino-recon-report${urlParams}`;
      let options = {
        method: "POST",
        url,
      };
      await axiosPrivate(options)
        .then((response) => {
          if (response.data.statusCode === 1) {
            setRowData(response.data.data.txnList);

            setTotalData(response.data.data.totalCount);
            setLoading(false);
          } else {
            setRowData([]);
            setTotalData(0);
            setLoading(false);
          }
          setDataStartDate(new Date(response.data.firstDate));
        })
        .catch((err) => {
          if (err.response) {
            setLoading(false);
            setErrorDialog(true);
            // console.error('err.res', err.response.data);
          }
        });
    } catch (error) {
      setLoading(false);
      setErrorDialog(true);
      // console.error('error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMerchantList = useCallback(async () => {
    try {
      setLoading(true);
      let url = "user/get-partner-payees";
      let options = {
        method: "GET",
        url,
      };

      await axiosPrivate(options)
        .then((response) => {
          if (response.data.statusCode === 1) {
            setMerchantList(response.data.data);
            setLoading(false);
            // setShowNoData(false);
          } else {
            setErrorDialog(true);
            console.error("err.res", response.data);
            setLoading(false);
            // setShowNoData(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            setErrorDialog(true);
            setLoading(false);
            // setShowNoData(true);
            console.error("error", error);
          }
        });
    } catch (error) {
      setErrorDialog(true);
      setLoading(false);
      // setShowNoData(true);
      console.error("error", error);
    }
  }, []);

  const handledFilterSelect = (val) => {
    setSelectedStatus(val);
    let prm = apiParams;
    prm.status = val;
    fetchTransactions(prm);
  };
  const handleCategorySearch = (event) => {
    setSelectedSearchCat(event.target.value);
    setShowSearchBox(true);
  };
  const handleSearch = useCallback(
    (value) => {
      if (hasPartnerRole(auth.auth.role)) {
        setLoading(true);
        let prm = apiParams;
        setSelectedPage(1);
        prm.pageNumber = 1;
        if (value.length > 0) {
          prm[selectedSearchCat] = value;
        } else {
          delete prm[selectedSearchCat];
        }
        fetchTransactions(prm, selectedMerchant);
        // delete prm[selectedSearchCat];
        // setShowSearchBox(false)
      } else {
        setLoading(true);
        let prm = apiParams;
        setSelectedPage(1);
        prm.pageNumber = 1;
        if (value.length > 0) {
          prm[selectedSearchCat] = value;
        } else {
          delete prm[selectedSearchCat];
        }
        fetchTransactions(prm);
        // delete prm[selectedSearchCat];
        // setShowSearchBox(false)
      }
    },
    [apiParams, fetchTransactions, selectedSearchCat]
  );

  const handlePaginate = (value) => {
    if (hasPartnerRole(auth.auth.role)) {
      let prm = apiParams;
      prm.pageNumber = value;
      setSelectedPage(value);
      setLoading(true);
      fetchTransactions(prm, selectedMerchant);
    } else {
      let prm = apiParams;
      prm.pageNumber = value;
      setSelectedPage(value);
      setLoading(true);
      fetchTransactions(prm);
    }
  };

  const handleSorting = (name, index) => {
    let prm = apiParams;
    let tempCol = mainColumns.columns;
    if (tempCol[index].direction === "") {
      tempCol.map((column) =>
        column.name === name
          ? (column.direction = "ASC")
          : (column.direction = "")
      );
      prm.sort_by = name;
      prm.order = 1;
      prm.page = 1;
    } else if (tempCol[index].direction === "ASC") {
      tempCol.map((column) =>
        column.name === name
          ? (column.direction = "DSC")
          : (column.direction = "")
      );
      prm.sort_by = name;
      prm.order = -1;
      prm.page = 1;
    } else if (tempCol[index].direction === "DSC") {
      tempCol.map((column) => (column.direction = ""));
      prm.page = 1;
      delete prm.sort_by;
      delete prm.order;
    }
    setLoading(true);
    setMainColumns({ columns: tempCol });
    setSelectedPage(1);
    fetchTransactions(prm);
  };

  function generateRows(tempArray) {
    const tempRowArray = [];
    if (tempArray) {
      tempArray.map((item) =>
        tempRowArray.push({
          tranDate: (
            <Box component="div" className="BBPDTSText">
              {format(new Date(item.txnOrgTime), "dd-MMM-yyyy hh:mm a")}
            </Box>
          ),
          amount: (
            <Box component="div" className="BBPDTSText">
              {item.amount}
            </Box>
          ),
          merchantMdr: (
            <Box component="div" className="BBPDTSText">
              {item.merchantMdr.toFixed(2)}
            </Box>
          ),
          settAmt: (
            <Box component="div" className="BBPDTSText">
              {item.settAmt}
            </Box>
          ),
          status: (
            <Box component="div" className="BBPDTSText">
              {item.status}
            </Box>
          ),
          txnId: (
            <Box component="div" className="BBPDTSText">
              {item.txnId}
            </Box>
          ),
          pvpa: (
            <Box component="div" className="BBPDTSText">
              {item.pvpa}
            </Box>
          ),
          pmobile: (
            <Box component="div" className="BBPDTSText">
              {item.pmobile}
            </Box>
          ),
          rrn: (
            <Box component="div" className="BBPDTSText">
              {item.rrn}
            </Box>
          ),
          refId: (
            <Box component="div" className="BBPDTSText">
              {item.refId}
            </Box>
          ),
          // refundAmt: (<Box component="div" className="BBPDTSText">{item.refundAmt}</Box>),
          // refundStatus: (<Box component="div" className="BBPDTChips"><Box component="div" className={"BBPDTCChip " + item.refundStatus}>{item.refundStatus}</Box></Box>),
        })
      );
    }
    return tempRowArray;
  }

  const handleCSVDownload = useCallback(
    async (payee) => {
      try {
        setOnDownloadLoading(true);
        let urlParams = "";
        if (apiParams) {
          delete apiParams.page;
          Object.keys(apiParams).forEach(function (key, index) {
            urlParams +=
              (index === 0 ? "?" : "&") +
              key +
              "=" +
              (key === "pageSize" ? totalData : apiParams[key]);
          });
        }

        // let url = `txn/get-merchant-fino-recon-report${urlParams}`;
        let url = hasPartnerRole(auth.auth.role)
          ? payee
            ? `/txn/get-partner-fino-recon-report/${payee}${urlParams}`
            : `/txn/get-partner-fino-recon-report/:${auth.auth.payee}${urlParams}`
          : `txn/get-merchant-fino-recon-report${urlParams}`;
        let options = {
          method: "POST",
          url,
        };
        await axiosPrivate(options)
          .then((response) => {
            if (response.data.statusCode === 1) {
              // setRowData(response.data.data.txnList);
              // setTotalData(response.data.total);

              const tempDownloadArr = [];
              const tempHeader = {};
              mainColumns.columns.map(
                (item) => (tempHeader[item.name] = item.title)
              );

              response.data.data.txnList.map((item) =>
                tempDownloadArr.push({
                  tranDate: format(
                    new Date(item.txnOrgTime),
                    "dd-MMM-yyyy hh:mm:ss"
                  ),
                  amount: item.amount,
                  merchantMdr: item.merchantMdr.toFixed(2),
                  settAmt: item.settAmt,
                  status: item.status,
                  txnId: item.txnId,
                  pvpa: item.pvpa,
                  pmobile: item.pmobile,
                  rrn: item.rrn,
                  refId: item.refId,
                })
              );
              tempDownloadArr.unshift(tempHeader);
              const csv = jsonToCSV(tempDownloadArr, { header: false });
              let filename = `${"recon"}-${format(
                new Date(),
                "yyyy-MM-dd-HH:mm:ss"
              )}.csv`;
              var csvData = new Blob([csv], {
                type: "text/csv;charset=utf-8;",
              });
              var csvURL = null;
              if (navigator.msSaveBlob) {
                csvURL = navigator.msSaveBlob(csvData, filename);
              } else {
                csvURL = window.URL.createObjectURL(csvData);
              }
              var tempLink = document.createElement("a");
              tempLink.href = csvURL;
              tempLink.setAttribute("download", filename);
              tempLink.click();
              setOnDownloadLoading(false);
            } else {
              setRowData([]);
              setTotalData(0);
              setOnDownloadLoading(false);
            }
          })
          .catch((err) => {
            if (err.response) {
              setErrorDialog(true);
              setOnDownloadLoading(false);
              // console.error('err.res', err.response.data);
            }
          });
      } catch (error) {
        setErrorDialog(true);
        setOnDownloadLoading(false);
        // console.error('error', error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [totalData]
  );

  const handleDateFilter = (date) => {
    let prm = apiParams;
    prm.startDate = format(date.startDate, "yyyy-MM-dd");
    prm.endDate = format(date.endDate, "yyyy-MM-dd");
    setLoading(true);
    fetchTransactions(prm);
  };

  const handleRestDateFilter = () => {
    let prm = apiParams;
    delete prm.startDate;
    delete prm.endDate;
    setLoading(true);
    fetchTransactions(prm);
  };

  useEffect(() => {
    // eslint-disable-next-line
    if (hasPartnerRole(auth.auth.role)) {
      fetchMerchantList();
      fetchTransactions(apiParams, selectedMerchant);
    } else {
      fetchTransactions(apiParams);
    }
  }, []);
  const handleFindDate = (e) => {
    if (hasPartnerRole(auth.auth.role)) {
      setSelectedDate(format(e, "dd-MMM-yyyy"));
      setSelectedPage(1);
      let prm = apiParams;
      prm.tranDate = format(e, "yyyy-MM-dd");
      prm.pageNumber = 1;
      fetchTransactions(prm, selectedMerchant);
    } else {
      setSelectedDate(format(e, "dd-MMM-yyyy"));
      setSelectedPage(1);
      let prm = apiParams;
      prm.tranDate = format(e, "yyyy-MM-dd");
      prm.pageNumber = 1;
      fetchTransactions(prm);
    }
  };
  const handleImageClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true); // Open the date picker programmatically
    }
  };

  const handleMerchantChange = async (e) => {
    setSelectedMerchant(e.target.value);
    const parsedDate = parse(selectedDate, "dd-MMM-yyyy", new Date());
    let prm = apiParams;
    setSelectedPage(1);
    prm.pageNumber = 1;
    prm.tranDate = format(parsedDate, "yyyy-MM-dd");
    fetchTransactions(prm, e.target.value);
  };

  const handleRefresh = () => {
    if(hasPartnerRole(auth.auth.role)){
      fetchTransactions(apiParams,selectedMerchant);
    }else{
      fetchTransactions(apiParams);
    }
  };

  return (
    <Fragment>
      <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} />
      <Backdrops open={onDownloadLoading} title={"Downloading"} />
      <Box component="div" className={"BBPTransaction"}>
        <Box component="div" className={"BBPTHead"}>
          <Box component="div" className="BBPSearchMain">
            <Box component="div" className="BBPSelectDiv">
              <select
                className="BBPSBInput"
                value={selectedSearchCat}
                onChange={handleCategorySearch}
              >
                <option value="">Search By</option>
                <option value="payer">Payer</option>
                <option value="rrn">Rrn</option>
                <option value="refId">Upi RefId</option>
                <option value="txnId">TxnId</option>
                <option value="mobileNumber">Mobile No</option>
              </select>
            </Box>
          </Box>
          {showSearchBox ? (
            <SearchBox
              onSearchChange={handleSearch}
              placeholder={"Search Transaction"}
              // searchTooltip={
              //   "Searching from Transaction ID, Bill Number and UPI ID"
              // }
            />
          ) : (
            ""
          )}

          {hasPartnerRole(auth.auth.role) ? (
            <Box component="div" className="BBPSearchMain">
              <Box
                component="div"
                className="BBPSelectDiv"
                style={{ width: "12rem" }}
              >
                <select
                  className="BBPSBInput"
                  value={selectedMerchant}
                  onChange={handleMerchantChange}
                >
                  <option>Search Merchant</option>
                  {merchantList.map((record, index) => (
                    <option key={index}>{record}</option>
                  ))}

                  {/* <option>Search By</option> */}
                </select>
              </Box>
            </Box>
          ) : (
            ""
          )}

          <Box
            component="div"
            className={"BBPTHBtn"}
            style={{ alignItems: "center" }}
          >
            <AutorenewOutlinedIcon style={{ color: "#175783" }}  onClick={handleRefresh}/>
            <p id="blueP" style={{ margin: "0px 10px" }}>
              Data as of {selectedDate}{" "}
            </p>
            <Box className="calenderDiv">
              <DatePicker
                ref={datePickerRef}
                selected={selectedDate}
                onChange={handleFindDate}
                dateFormat="dd/MM/yyyy"
                className="calenderIcon"
                popperPlacement="top-start"
              />
            </Box>
            <img
              src={DateIcon}
              className="imgDateIcon"
              onClick={handleImageClick}
            />
            <Button
              variant="contained"
              className={"BBPTHBD"}
              onClick={() => {
                if (hasPartnerRole(auth.auth.role)) {
                  handleCSVDownload(selectedMerchant);
                } else {
                  handleCSVDownload();
                }
              }}
              disabled={loading || totalData === 0}
            >
              Download
            </Button>
          </Box>
        </Box>
        <Box component="div" className={"BBPTBody"}>
          <DevTable
            rows={generateRows(rowData)}
            columns={mainColumns.columns}
            selectedPage={selectedPage}
            handlePagination={handlePaginate}
            loading={loading}
            handleSort={handleSorting}
            limitData={limitData}
            totalData={totalData}
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default DigiSwarna;
