import { Box, Button, Checkbox, Dialog, Radio, TextField } from '@mui/material'
import React, { Fragment } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import "./index.css"
const PaymentLinkDialog = ({ open, onClose, }) => {

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xs'}
            open={open}
            onClose={onClose}
        >
            <Box component="div" style={{ height: "100vh" }} >
                <Box className="txnDetailDialog" >
                    <p>Create Payment Link</p>
                    <p onClick={onClose}><CloseIcon /></p>

                </Box>

                <Box component="div" style={{ padding: "0rem  2rem" }} >
                    <form>
                        <Box>
                            <Box className='BBPCPLFORMItem'>
                                <label>Customer Name</label>
                                <TextField id="outlined-basic" variant="outlined" InputProps={{
                                    style: {
                                        height: 40, // adjust this value to your desired height
                                    },
                                }} />
                            </Box>
                            <Box className='BBPCPLFORMItem'>
                                <label>Mobile Number </label>
                                <TextField id="outlined-basic" variant="outlined" InputProps={{
                                    style: {
                                        height: 40, // adjust this value to your desired height
                                    },
                                }} />
                            </Box>
                            <Box className='BBPCPLFORMItem'>
                                <label>Amount </label>
                                <TextField id="outlined-basic" variant="outlined" InputProps={{
                                    style: {
                                        height: 40, // adjust this value to your desired height
                                    },
                                }} />
                            </Box>
                            <Box className='BBPCPLFORMItem'>
                                <label>Remarks</label>
                                <TextField id="outlined-basic" variant="outlined" InputProps={{
                                    style: {
                                        height: 40, // adjust this value to your desired height
                                    },
                                }} />
                            </Box>
                            <Box className='BBPCPLFSelectItem'>
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox
                                        value="checkedA"
                                        inputProps={{ 'aria-label': 'Checkbox A' }}
                                    />
                                    <label>Send via Sms</label>
                                </Box>
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox
                                        value="checkedA"
                                        inputProps={{ 'aria-label': 'Checkbox A' }}
                                    />
                                    <label>Send via whatsapp</label>
                                </Box>
                            </Box>

                            <Box className="BBPCPLFORMBtn">
                                <Button
                                    variant="contained"
                                    className={"BBPTHBD"}>
                                    Send Payment Link
                                </Button>
                            </Box>



                        </Box>

                    </form>
                </Box>




            </Box>


        </Dialog>
    )
}

export default PaymentLinkDialog