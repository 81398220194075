import { Box } from '@mui/material';
import React, { useState } from 'react'
import { NavLink, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { useAuth } from '../../Hooks/useAuth';
import { MENU_SLUG } from '../../Constants/constants';
import SinglePayouts from './singlePayouts';

const Payouts = () => {
    let location = useLocation();
    const { auth } = useAuth();
    const [tabsList] = useState([
        {
            name: 'general-settings',
            title: 'Single Payouts',
            path: '/',
            link: `/${MENU_SLUG.transaction}/single-payout`,
            component: SinglePayouts,
            show_tab: true,
        },
        // {
        //     name: 'change-password',
        //     title: 'Change Password',
        //     path: '/password',
        //     link: `/${MENU_SLUG.settings}/password`,
        //     component: ChangePassword,
        //     show_tab: true,
        // },
       
    ]);
    return (
        <Box component="div" className={'BBPSettingsPage'}>
            <Box component="div" className={'BBPSPTab'}>
                {tabsList.map((page, index) =>
                    page.show_tab ?
                        <NavLink
                            key={index}
                            to={page.link}
                            className={({ isActive }) =>
                                [
                                    'BBPSPTBtn',
                                    isActive ? 'BBPSPTBActive' : '',
                                ]
                                    .filter(Boolean)
                                    .join(" ")
                            }
                            end={location.pathname !== `/${MENU_SLUG.settings}/general-settings` ? true : false}
                        >
                            {page.title}
                        </NavLink> : ''
                )}
            </Box>
            <Box component="div" className={'BBPSPTabView'}>
                <Routes>
                    <Route path={'/'} element={<Outlet />}>
                        { // eslint-disable-next-line 
                            tabsList.map((route) => {
                                if (route.show_tab) {
                                    const { path, component: Component, children, title, permission, ...rest } = route;
                                    return (
                                        <Route {...rest} key={path} path={`${path}`} element={<Component />} />
                                    )
                                }
                            })}
                    </Route>
                </Routes>
            </Box>
        </Box>





       
    )
}

export default Payouts