import React, { useState } from "react";
import { Route, Routes, Outlet, useLocation } from "react-router-dom";
import RightSidePanel from "../../Components/RightSidePanel";
import DigiSwarna from "./recon";
import BillPayment from "./transaction";
import { CheckPermissions } from "../../Utils/permissions";
import "./style.css";
import ChargeBack from "./chargeBack";
import Settlement from "./settlement";
import Complaints from "./complaints";
import ComplainDetails from "./compalinDetails";
import Refund from "./refund";
const Reports = () => {
  let location = useLocation();

  const background = location.state ? location.state.background : location;

  const [pages] = useState([
    {
      path: "/",
      component: BillPayment,
      enable_route: CheckPermissions("reports_permissions", "Transaction"),
    },
    {
      path: "/recon",
      component: DigiSwarna,
      enable_route: CheckPermissions("reports_permissions", "Recon"),
    },
    {
      path: "/chargeback",
      component: ChargeBack,
      enable_route:CheckPermissions("reports_permissions", "Recon"),
    },
    {
      path: "/settlement",
      component: Settlement,
      enable_route:CheckPermissions("reports_permissions", "Recon"),
    },
    {
      path: "/complaints",
      component: Complaints,
      enable_route: true,
    },
    {
      path: "/cyber-complaints/:id",
      component: ComplainDetails,
      enable_route: true,
    },
    {
      path: "/refund",
      component: Refund,
      enable_route: true,
    },
  ]);

  return (
    <RightSidePanel removeBg>
      <Routes location={background}>
        <Route path={"/"} element={<Outlet />}>
          {
            // eslint-disable-next-line array-callback-return
            pages.map((route) => {
              if (route.enable_route) {
                const {
                  path,
                  component: Component,
                  children,
                  title,
                  permission,
                  ...rest
                } = route;
                return (
                  <Route
                    {...rest}
                    key={path}
                    path={`${path}`}
                    element={<Component />}
                  />
                );
              }
            })
          }
        </Route>
      </Routes>
    </RightSidePanel>
  );
};

export default Reports;
