import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import BodyView from "./Components/BodyView";
import MenuBar from "./Components/MenuBar";
import LeftSidebar from "./Components/LeftSidebar";

import { getAllowedRoutes } from "./Utils/index";

import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Reports from "./Pages/Reports";
import Settings from "./Pages/Settings";
import { MENU_SLUG } from "./Constants/constants";
import { ROLES } from "./Constants/roles";

import RequireAuth from "./Auth/RequireAuth";
import PersistLogin from "./Auth/PersistLogin";
import { useAuth } from "./Hooks/useAuth";
import { CheckPermissions } from "./Utils/permissions";
import history from "./Utils/history";

import "./App.css";
import { checkboxClasses } from "@mui/material";
import { Toaster } from "react-hot-toast";
import Transactions from "./Pages/Transactions/index"
import BillPayment from "./Pages/Reports/transaction";
import Payouts from "./Pages/Transactions/allPayouts";
import Beneficiary from "./Pages/Beneficiary";
// import Refund from "./Pages/Reports/refund";
const App = () => {
  const { auth } = useAuth();

  const pages = [
    {
      name: "dashboard",
      title: "Dashboard",
      link: `/${MENU_SLUG.dashboard}`,
      path: `/${MENU_SLUG.dashboard}`,
      component: Dashboard,
      show_tab: true,
    },
    {
      name: "transaction",
      title: "Payouts",
      link: `/${MENU_SLUG.transaction}`,
      path: `/${MENU_SLUG.transaction}/*`,
      component: Transactions,
      show_tab: CheckPermissions("payout_permissions", "Txn Status"),
      children: [
        {
          name: "billpayment",
          title: "Txn Report",
          link: `/${MENU_SLUG.transaction}`,
          show_tab: true,
        },
        {
          name: "billpayment",
          title: "Statement",
          link: `/${MENU_SLUG.transaction}/statements`,
          show_tab:  CheckPermissions("payout_permissions", "Txn Status"),
        },
        // {
        //   name: "payouts",
        //   title: "Payouts",
        //   link: `/${MENU_SLUG.transaction}/payouts`,
        //   show_tab: true,
        // },
        // {
        //   name: "paymentLink",
        //   title: "Payment Links",
        //   link: `/${MENU_SLUG.transaction}/payment-link`,
        //   show_tab: true,
        // },
        {
          name: "paymentLink",
          title: "Payment Links",
          link: `/${MENU_SLUG.transaction}/single-payout`,
          show_tab: false,
        },
        // {
        //   name: "paymentLink",
        //   title: "Payment Page",
        //   link: `/${MENU_SLUG.transaction}/payment-page`,
        //   show_tab: true,
        // },
        {
          name: "beneficiary",
          title: "Beneficiary",
          link: `/${MENU_SLUG.transaction}/beneficiary`,
          show_tab:  CheckPermissions("payout_permissions", "View Beneficiary"),
        },
        {
          name: "add beneficary",
          title: "Beneficiary",
          link: `/${MENU_SLUG.transaction}/beneficiary/add`,
          show_tab: false,
        },
        {
          name: "pending benficiary",
          title: "Pending Beneficiary",
          link: `/${MENU_SLUG.transaction}/beneficiary/pending`,
          show_tab: false,
        }
      ]
    },
    // {
    //   name: "beneficiary",
    //   title: "Beneficiary",
    //   link: `/${MENU_SLUG.beneficiary}`,
    //   path: `/${MENU_SLUG.beneficiary}/*`,
    //   component: Beneficiary,
    //   show_tab: true,
    //   children: [
    //     {
    //       name: "billpayment",
    //       title: "Bill Payment",
    //       link: `/${MENU_SLUG.beneficiary}`,
    //       show_tab: true,
    //     },
    //     {
    //       name: "payouts",
    //       title: "Payouts",
    //       link: `/${MENU_SLUG.beneficiary}/payouts`,
    //       show_tab: true,
    //     },]
    // },
    {
      name: "reports",
      title: "Reports",
      link: CheckPermissions("dashboard_permissions", "View")
        ? CheckPermissions("reports_permissions", "Transaction")
          ? `/${MENU_SLUG.reports}`
          : CheckPermissions("reports_permissions", "Recon")
            ? `/${MENU_SLUG.reports}/recon`
            : ""
        : `/${MENU_SLUG.reports}/upi/intent/`,
      path: `/${MENU_SLUG.reports}/*`,
      component: Reports,
      show_tab:
        true,
      // CheckPermissions("reports_permissions", "Transaction") ||
      // CheckPermissions("reports_permissions", "recon"),
      children: [
        {
          name: "billpayment",
          title: "Transactions",
          link: `/${MENU_SLUG.reports}`,
          show_tab: CheckPermissions("reports_permissions", "Transaction"),
        },
        {
          name: "digiswarna",
          title: "Reconciliation",
          link: `/${MENU_SLUG.reports}/recon`,
          show_tab: CheckPermissions("reports_permissions", "Recon"),
        },
        {
          name: "recon",
          title: "ChargeBack",
          link: `/${MENU_SLUG.reports}/chargeback`,
          show_tab: CheckPermissions("reports_permissions", "Recon"),
        },
        {
          name: "settlement",
          title: "Settlement",
          link: `/${MENU_SLUG.reports}/settlement`,
          show_tab: CheckPermissions("reports_permissions", "Recon"),
        },
        {
          name: "complaint",
          title: "Complaints",
          link: `/${MENU_SLUG.reports}/complaints`,
          show_tab: true,
        },
        {
          name: "recon",
          title: "Cyber Complaint",
          link: `/${MENU_SLUG.reports}/cyber-complaints/:id`,
          show_tab: false,
        },
        {
          name: "refund",
          title: "Refund",
          link: `/${MENU_SLUG.reports}/refund`,
          show_tab: true,
        },
      ],
    },
    // {
    //   name: "users",
    //   title: "Users",
    //   link: `/${MENU_SLUG.users}`,
    //   path: `/${MENU_SLUG.users}/*`,
    //   component: Users,
    //   // show_tab:true
    //   show_tab: CheckPermissions("users_permissions", "Change Password"),
    // },
    {
      name: "settings",
      title: "Settings",
      link: `/${MENU_SLUG.settings}`,
      path: `/${MENU_SLUG.settings}/*`,
      component: Settings,
      show_tab: CheckPermissions("users_permissions", "Change Password"),
    },
  ];

  const allowedRoutes = getAllowedRoutes(pages);

  const lastHistory = history.location.pathname;

  return (
    <>
      <Routes>
        <Route element={<PersistLogin />}>
          <Route
            exact
            path={"/"}
            element={
              auth && auth.token ? (
                <Navigate
                  to={lastHistory === "/" ? "/dashboard" : lastHistory}
                  replace
                />
              ) : (
                <Box component="div" className={"BBPApp BBPLP"}>
                  <Box component="div" className={"BBPAInner"}>
                    <BodyView>
                      <Login />
                    </BodyView>
                  </Box>
                </Box>
              )
            }
          />

          <Route
            element={
              <RequireAuth allowedRoles={[ROLES.merchant, ROLES.partner]} />
            }
          >
            <Route
              path={"/"}
              element={
                <Box component="div" className={"BBPApp"}>
                  <Box component="div" className={"BBPAInner"}>
                    <MenuBar />
                    <BodyView>
                      <LeftSidebar pages={allowedRoutes} />
                      <Outlet />
                    </BodyView>
                  </Box>
                </Box>
              }
            >
              {
                // eslint-disable-next-line
                allowedRoutes.map((route) => {
                  if (route.show_tab) {
                    const {
                      path,
                      component: Component,
                      children,
                      title,
                      permission,
                      ...rest
                    } = route;
                    return (
                      <Route
                        {...rest}
                        key={path}
                        path={`${path}`}
                        element={<Component />}
                      />
                    );
                  }
                })
              }
            </Route>
          </Route>
        </Route>
        <Route
          path="*"
          element={
            auth && auth.token ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      </Routes>

      {/* <Routes>
        <Route
          path={"/"}
          element={
            <Box component="div" className={"BBPApp"}>
              <Box component="div" className={"BBPAInner"}>
                <MenuBar />
                <BodyView>
                  <LeftSidebar pages={allowedRoutes} />
                  <Outlet />
                </BodyView>
              </Box>
            </Box>
          }
        >
          {
            // eslint-disable-next-line
            allowedRoutes.map((route) => {
              if (route.show_tab) {
                const {
                  path,
                  component: Component,
                  children,
                  title,
                  permission,
                  ...rest
                } = route;
                return (
                  <Route
                    {...rest}
                    key={path}
                    path={`${path}`}
                    element={<Component />}
                  />
                );
              }
            })
          }
        </Route>
      </Routes> */}
      <Toaster />
    </>
  );
};

export default App;
