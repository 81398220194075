import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import Backdrops from '../../Components/Backdrops'
import Box from "@mui/material/Box";
import DevTable from '../../Components/DevTable';
import SearchBox from '../../Components/SearchBox';
import toast from 'react-hot-toast';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import fileIcon from "../../Assets/Images/uploadIcon.png";
import { Button } from '@mui/material';
import DateRangePicker from "../../Components/DateRangePicker/index"
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Hooks/useAuth';
import SomethingWentWrong from '../../Components/SomethingWentWrong';
import { endOfDay, format, startOfDay } from 'date-fns';
import DevTable2 from '../../Components/DevTable2';
import { usePapaParse } from 'react-papaparse';
import DateIcon from "../../Assets/Images/date.png";
import DatePicker from "react-datepicker";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
const Payouts = () => {
    const auth = useAuth();
    const { jsonToCSV } = usePapaParse();
    const [onDownloadLoading, setOnDownloadLoading] = useState(false);
    const [mainColumns, setMainColumns] = useState({
        columns: [
            {
                name: "txTime",
                title: "Date",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "id",
                title: "Request Id",
                width: 50,
                sorting: false,
                direction: "",
            },
            // {
            //     name: "eventId",
            //     title: "EventId",
            //     width: 50,
            //     sorting: false,
            //     direction: "",
            // },
            // {
            //     name: "eventType",
            //     title: " Event Type",
            //     width: 50,
            //     sorting: false,
            //     direction: "",
            // },
            {
                name: "event",
                title: "Credit / Debit",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "transactionAmount",
                title: "Transaction Amount",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "remarks",
                title: "remarks",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "closingBalance",
                title: "closingBalance",
                width: 150,
                sorting: false,
                direction: "",
            },

        ],
    });
    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    txTime: (
                        <Box component="div" className="BBPDTSText">
                            {format(new Date(item.txTime), "dd-MM-yyyy HH:mm:ss")}
                        </Box>
                    ),
                    id: (
                        <Box component="div" className="BBPDTSText">
                            {item.id}
                        </Box>
                    ),

                    eventId: (
                        <Box component="div" className="BBPDTSText">
                            {item.eventId}
                        </Box>
                    ),
                    eventType: (
                        <Box component="div" className="BBPDTSText">
                            {item.eventType}
                        </Box>
                    ),
                    event: (
                        <Box component="div" className="BBPDTSText">
                            {item.event}
                        </Box>
                    ),
                    transactionAmount: (
                        <Box component="div" className="BBPDTSText">
                            {item.transactionAmount}
                        </Box>
                    ),
                    remarks: (
                        <Box component="div" className="BBPDTSText">
                            {item.remarks}
                        </Box>
                    ),
                    closingBalance: (
                        <Box component="div" className="BBPDTSText">
                            {item.closingBalance}
                        </Box>
                    ),
                })
            );
        }
        return tempRowArray;
    }
    const datePickerRef = useRef(null);
    const handleImageClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(true); // Open the date picker programmatically
        }
    };
    const [rowData, setRowData] = useState([]);
    const [errorDialog, setErrorDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [limitData] = useState("100");
    const [startDate, setStartDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(
        format(new Date(), "dd-MMM-yyyy")
    );
    const handleRefresh = () => {
        let prm = apiParms;
        prm.startDate = new Date();
        prm.endDate = new Date();
        prm.cursor = "";
        setSelectedDate(format(new Date(), "dd-MMM-yyyy"));
        fetchAllPayouts(prm);

    }
    const [downloadStatement, setOnDownloadStatement] = useState(false);
    const handleFindDate = (e) => {
        setSelectedDate(format(e, "dd-MMM-yyyy"));

    }
    const axiosPrivate = useAxiosPrivate();
    const inputFile = useRef(null);
    const handleLabelClick = (event) => {
        event.preventDefault(); // Prevent default behavior of the label
        event.stopPropagation(); // Stop event propagation to prevent it from reaching the input field

        if (inputFile.current && !inputFile.current.files.length) {
            inputFile.current.click();
        }
    };

    const handleInputCBFile = async (e) => {
        try {
            const formData = new FormData();
            formData.append("file", e.target.files[0]);
            let url = "/txn/upload-refund-report";
            let options = {
                method: "POST",
                url,
                data: formData,
            };
            await axiosPrivate(options)
                .then((response) => {
                    if (response.data.statusCode === 1) {
                        toast.success(response.data.message);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        toast.error("Some error occured");
                    }
                });
        } catch (error) {
            toast.error("Some error occured");
        }
    };
    const handleCSVDownload = () => {
        setOnDownloadLoading(true);
        const tempDownloadArr = [];
        const tempHeader = {};
        mainColumns.columns.map(
            (item) => (tempHeader[item.name] = item.title)
        );
        if (rowData.length > 0) {
            rowData.map((item) =>
                tempDownloadArr.push({
                    txTime: format(new Date(item.txTime), "dd-MM-yyyy HH:mm:ss"),
                    id: item.id,
                    eventId: item.eventType,
                    event: item.event,
                    transactionAmount: item.transactionAmount,
                    remarks: item.remarks,
                    closingBalance: item.closingBalance
                })
            )

            tempDownloadArr.unshift(tempHeader);
            const csv = jsonToCSV(tempDownloadArr, { header: false });
            let filename = `${"PayoutStatement"}-${format(
                new Date(),
                "yyyy-MM-dd-HH:mm:ss"
            )}.csv`;
            var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            var csvURL = null;
            if (navigator.msSaveBlob) {
                csvURL = navigator.msSaveBlob(csvData, filename);
            } else {
                csvURL = window.URL.createObjectURL(csvData);
            }
            var tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", filename);
            tempLink.click();
            setOnDownloadLoading(false);
        } else {
            setOnDownloadStatement(false);
            setOnDownloadLoading(false);
        }
    }
    const navigate = useNavigate();
    const navigateSinglePayout = () => {
        navigate("/transaction/single-payout")
    }
    const [prevCursor, setPrevCursor] = useState("");
    const [nextCursor, setNextCursor] = useState("");
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [apiParms] = useState({
        startDate: "", endDate: "", size: "", checksum: "", cursor: ""
    })
    const fetchAllPayouts = useCallback(async (params) => {
        try {
            const data = {
                PHONE: auth.auth.username,
                STARTDATE: format(startOfDay(params.startDate), "yyyy-MM-dd HH:mm:ss"),
                ENDDATE: format(endOfDay(params.endDate), "yyyy-MM-dd HH:mm:ss"),
                SIZE: limitData,
                CURSOR: params.cursor,
                CHECKSUM: ""
            }
            setLoading(true);
            let url = `/payout/api/v1/report/vacc`;
            let options = {
                method: "POST",
                url,
                data
            };
            await axiosPrivate(options)
                .then((response) => {
                    if (response.data.STATUS === "SUCCESS") {
                        setRowData(response.data.DATA.batches);
                        if (!response.data.DATA.batches.length == 0) {
                            setOnDownloadStatement(true)
                        } else {
                            setOnDownloadStatement(false)
                        }
                        if (response.data.DATA.hasNext) {
                            setHasNext(true)
                            setNextCursor(response.data.DATA.nextCursor)
                            setTotalData(2);
                        } else {
                            setHasNext(false);
                            setNextCursor("")
                        }
                        if (response.data.DATA.prevCursor) {
                            setPrevCursor(response.data.DATA.prevCursor);
                            setHasPrev(true)
                        } else {
                            setPrevCursor("");
                            setHasPrev(false)
                        }
                        setLoading(false);
                    } else {
                        setRowData([]);
                        setTotalData(0);
                        setLoading(false);
                        setOnDownloadStatement(false);
                    }
                    // setDataStartDate(new Date(response.data.firstDate));
                })
                .catch((err) => {
                    if (err.response) {
                        setLoading(false);
                        setErrorDialog(true);
                        // console.error('err.res', err.response.data);
                    }
                });
        } catch (error) {
            setLoading(false);
            setErrorDialog(true);
        }
    }, [])

    function hasPartnerRole(roles) {
        return roles.includes("Partner");
    }

    useEffect(() => {
        if (!hasPartnerRole(auth.auth.role)) {
            let prm = apiParms;
            prm.startDate = selectedRange.startDate;
            prm.endDate = selectedRange.endDate;
            prm.cursor = "";
            fetchAllPayouts(prm);
        }
    }, [])

    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    });
    const handleDateRangeChange = (e) => {
        setSelectedDate(format(e, "dd-MMM-yyyy"));
        let prm = apiParms;
        prm.startDate = e;
        prm.endDate = e;
        prm.cursor = "";
        fetchAllPayouts(prm);
    };

    const handleDateRangeReset = () => {
        setSelectedRange({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        });
        let prm = apiParms;
        prm.startDate = new Date();
        prm.endDate = new Date();
        prm.cursor = "";
        fetchAllPayouts(prm);
    };

    const handlePrevious = () => {
        console.log("prev")
        let prm = apiParms;
        prm.startDate = selectedRange.startDate;
        prm.endDate = selectedRange.endDate;
        prm.cursor = prevCursor;
        console.log(prm)
        fetchAllPayouts(prm);
    }
    const handleNext = () => {
        console.log("next");
        let prm = apiParms;
        prm.startDate = selectedRange.startDate;
        prm.endDate = selectedRange.endDate;
        prm.cursor = nextCursor;
        console.log(prm)
        fetchAllPayouts(prm);
    }

    return (
        <Fragment>
            {/* <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} /> */}
            <Backdrops open={onDownloadLoading} title={"Downloading"} />
            <Box component="div" className={"BBPTransaction"}>
                <Box component="div" className={"BBPTHead"}>
                    <Box component="div" className="BBPSearchMain">
                    </Box>
                    {/* <SearchBox
                        onSearchChange={handleSearch}
                        placeholder={"Search Transaction"}

                    /> */}
                    {/* <Box component="div" className="uploadFileCont" onClick={navigateSinglePayout}>
                        <label
                            // htmlFor="cbFileUpload"
                            className="labelContFu"
                        // style={{ display: "flex", cursor: "pointer", alignItems:"center" ,  }}
                        >
                            <p style={{ margin: "0rem", fontSize: "12px" }}>Single Payout</p>
                            <img src={fileIcon} alt="File icon" />
                        </label>

                    </Box>
                    <Box component="div" className="uploadFileCont">
                        <label
                            htmlFor="cbFileUpload"
                            // onClick={handleLabelClick}
                            className="labelContFu"
                        // style={{ display: "flex", cursor: "pointer", alignItems:"center" ,  }}
                        >
                            <p style={{ margin: "0rem", fontSize: "12px" }}>Bulk Payout</p>
                            <img src={fileIcon} alt="File icon" />
                        </label>
                        <input
                            type="file"
                            id="cbFileUpload"
                            ref={inputFile}
                            style={{ display: "none" }}
                            onChange={handleInputCBFile}
                        />
                    </Box> */}
                    <Box
                        component="div"
                        className={"BBPTHBtn"}
                        style={{ alignItems: "center" }}
                    >
                        <AutorenewOutlinedIcon
                            style={{ color: "#175783" }}
                            onClick={handleRefresh}
                        />
                        <p id="blueP" style={{ margin: "0px 10px" }}>
                            Data as of {selectedDate}{" "}
                        </p>
                        <Box className="calenderDiv">
                            <DatePicker
                                ref={datePickerRef}
                                selected={selectedDate}
                                onChange={handleDateRangeChange}
                                dateFormat="dd/MM/yyyy"
                                className="calenderIcon"
                                popperPlacement="top-start"
                            />

                        </Box>
                        <img
                            src={DateIcon}
                            className="imgDateIcon"
                            onClick={handleImageClick}
                        />
                        {/* <DateRangePicker
                            buttonTitle={"Period"}
                            ranges={[selectedRange]}
                            dataStartDate={new Date('2024-08-01')} // Example start date
                            title="Select Range"
                            onChange={handleDateRangeChange}
                            onReset={handleDateRangeReset}
                        /> */}
                        <Button
                            variant="contained"
                            className={"BBPTHBD"}
                            onClick={handleCSVDownload}
                            disabled={loading || !downloadStatement}
                        >
                            Download
                        </Button>
                    </Box>
                </Box>
                <Box component="div" className={"BBPTBody"}>
                    <DevTable2
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        // selectedPage={selectedPage}
                        // handlePagination={handlePaginate}
                        loading={loading}
                        // handleSort={handleSorting}
                        // limitData={limitData}
                        // totalData={totalData}
                        hasNext={hasNext}
                        hasPrev={hasPrev}
                        onPrevious={handlePrevious}
                        onNext={handleNext}
                    />
                </Box>
            </Box>
        </Fragment>
    )
}

export default Payouts