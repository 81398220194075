import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useAuth } from '../../Hooks/useAuth';
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import { Box } from '@mui/material';
import { endOfDay, format, parse, startOfDay } from 'date-fns';
import DateIcon from "../../Assets/Images/date.png";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import DatePicker from "react-datepicker";
import DevTable from '../../Components/DevTable';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
const AllTransations = () => {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [selectMerchant, setSelectMerchant] = useState("");
    const [allMerchant, setAllMerchant] = useState([]);
    const [selectedUrl, setSelectedUrl] = useState('')
    const [currentUrl, setCurrentUrl] = useState("AllBeneficiary")
    const [rowData, setRowData] = useState([]);
    const [errorDialog, setErrorDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState("0");
    const [limitData] = useState("20");
    const [fileData, setFileData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(
        format(new Date(), "dd-MMM-yyyy")
    );

    const [mainColumns, setMainColumns] = useState({
        columns: [
            {
                name: "initDate",
                title: "Date",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "status",
                title: "status",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "amount",
                title: "amount",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "bank",
                title: "bank",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "partnerId",
                title: " partnerId",
                width: 50,
                sorting: false,
                direction: "",
            },

            {
                name: "account",
                title: "account",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "ifsc",
                title: "IFSC",
                width: 150,
                sorting: false,
                direction: "",
            },

            {
                name: "address",
                title: "address",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "Ext Id",
                title: "extId",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "note",
                title: "note",
                width: 150,
                sorting: false,
                direction: "",
            },

            {
                name: "reference",
                title: "reference",
                width: 150,
                sorting: false,
                direction: "",
            },

            {
                name: "message",
                title: "message",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "errorCode",
                title: "errorCode",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "bankBCode",
                title: "bankBCode",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "remark",
                title: "remark",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "partnerId",
                title: "partnerId",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "action",
                title: "Action",
                width: 150,
                sorting: false,
                direction: "",
            },
        ],
    });
    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    initDate: (
                        <Box component="div" className="BBPDTSText">
                            {format(new Date(item.initDate), "dd-MM-yyyy HH:mm:ss")}
                        </Box>
                    ),
                    status: (
                        <Box component="div" className="BBPDTSText">
                            {item.status}
                        </Box>
                    ),

                    errorCode: (
                        <Box component="div" classerrorCode="BBPDTSText">
                            {item.name}
                        </Box>
                    ),
                    message: (
                        <Box component="div" className="BBPDTSText">
                            {item.message}
                        </Box>
                    ),
                    amount: (
                        <Box component="div" className="BBPDTSText">
                            {item.amount}
                        </Box>
                    ),
                    bank: (
                        <Box component="div" className="BBPDTSText">
                            {item.bank}
                        </Box>
                    ),
                    partnerId: (
                        <Box component="div" className="BBPDTSText">
                            {item.partnerId}
                        </Box>
                    ),
                    reference: (
                        <Box component="div" className="BBPDTSText">
                            {item.reference}
                        </Box>
                    ),
                    account: (
                        <Box component="div" className="BBPDTSText">
                            {item.account}
                        </Box>
                    ),
                    ifsc: (
                        <Box component="div" className="BBPDTSText">
                            {item.ifsc}
                        </Box>
                    ),

                    note: (
                        <Box component="div" className="BBPDTSText">
                            {item.note}
                        </Box>
                    ),
                    extId: (
                        <Box component="div" className="BBPDTSText">
                            {item.extId}
                        </Box>
                    ),

                })
            );
        }
        return tempRowArray;
    }


    const fetchAllMerchant = useCallback(async () => {
        try {
            let url = `/payout/api/v1/getMerchants`;
            const data = {
                PHONE: auth.username,
                CHECKSUM: "",
            }
            let options = {
                method: "POST",
                url,
                data
            }
            await axiosPrivate(options).then((response) => {
                setAllMerchant(response.data.DATA)
            }).catch((error) => {
                console.log(error)
            })
        } catch (error) {
            console.log(error)
        }
    }, [])

    const onChangeMerchant = (e) => {
        setSelectMerchant(e.target.value);
        let prm = apiParams;
        prm.startDate = new Date();
        prm.endDate = new Date();
        prm.page = selectedPage;
        prm.size = limitData;
        prm.mobile = e.target.value;
        setSelectedPage("0")
        setSelectedDate(format(new Date(), "dd-MMM-yyyy"));
        fetchAllTransactions(prm);

    }
    const handleRefresh = () => {
        let prm = apiParams;
        prm.startDate = new Date();
        prm.endDate = new Date();
        prm.page = "0";
        prm.size = limitData;
        setSelectedPage("0")
        setSelectedDate(format(new Date(), "dd-MMM-yyyy"));
        fetchAllTransactions(prm)
    }
    const [apiParams] = useState({ startDate: "", endDate: "", page: "", size: "", mobile: "" });

    const fetchAllTransactions = useCallback(async (params) => {
        try {

            setLoading(true);
            let url = `/payout/api/v1/report/txn`;
            const data = {
                PHONE: auth.username,   // admin login
                MOBILE: params.mobile, // merchant login
                STARTDATE: format(startOfDay(params.startDate), "yyyy-MM-dd HH:mm:ss"),
                ENDDATE: format(endOfDay(params.endDate), "yyyy-MM-dd HH:mm:ss"),
                PAGE: params.page,
                SIZE: params.size,
                CHECKSUM: ""

            }
            if (params.status) {
                data.STATUS = params.status
            }
            let options = {
                method: "POST",
                url,
                data
            }

            await axiosPrivate(options).then((response) => {
                if (response.status == 200) {
                    if (response.data.STATUS == "SUCCESS") {
                        setRowData(response.data.DATA.content)
                        setTotalData(response.data.DATA.totalElements)
                        setLoading(false)
                    }
                }
            }).catch((error) => {
                console.log(error)
                setLoading(false)
            })
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    })

    const onChangeApiUrl = (e) => {
        // console.log(e.target.value)
        // setSelectedUrl(e.target.value);
        // setSelectedDate(format(new Date(), "dd-MMM-yyyy"));
        // let prm = apiParams;
        // prm.startDate = new Date();
        // prm.endDate = new Date();
        // prm.page = selectedPage;
        // prm.size = limitData;
        // prm.url = e.target.value;
        // prm.mobile = selectMerchant
        // fetchAllBeneficiary(prm);
    }

    useEffect(() => {
        let prm = apiParams;
        prm.startDate = new Date();
        prm.endDate = new Date();
        prm.page = "0";
        prm.size = limitData;
        setSelectedPage("0")
        fetchAllTransactions(prm)
    }, [])

    const datePickerRef = useRef(null);
    const handleImageClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(true); // Open the date picker programmatically
        }
    };

    const handleDateRangeChange = (e) => {
        setSelectedDate(format(e, "dd-MMM-yyyy"));
        console.log(e)
        let prm = apiParams;
        prm.startDate = e;
        prm.endDate = e;
        prm.page = '0';
        prm.size = limitData
        setSelectedPage('0');
        prm.mobile = selectMerchant
        fetchAllTransactions(prm);
    };
    const handlePaginate = (value) => {
        try {
            setSelectedPage(String(value - 1));
            let prm = apiParams;
            prm.startDate = parse(selectedDate, 'dd-MMM-yyyy', new Date());
            prm.endDate = parse(selectedDate, 'dd-MMM-yyyy', new Date());
            prm.mobile = selectMerchant;
            prm.page = selectedPage;
            prm.size = limitData;
            prm.page = String(value - 1);
        } catch (error) {
            console.log(error)
        }
    }
    const [selectTxnStatus, setSelectTxnStatus] = useState("");

    const onChangeTxnStatus = (e) => {
        setSelectTxnStatus(e.target.value);
        console.log(parse(selectedDate, 'dd-MMM-yyyy', new Date()))
        let prm = apiParams;
        prm.startDate = parse(selectedDate, 'dd-MMM-yyyy', new Date());
        prm.endDate = parse(selectedDate, 'dd-MMM-yyyy', new Date());
        prm.mobile = selectMerchant;
        prm.page = selectedPage;
        prm.size = limitData;
        prm.page = "0"
        setSelectedPage("0")
        prm.status = e.target.value;
        console.log(prm)
        fetchAllTransactions(prm)
    }


    return (
        <Fragment>

            {/* <ViewBeneficiary onClose={closeBenficiaryDialog} open={showBenficiaryDetails} data={benficiaryDetails} /> */}
            {/* <SomethingWentWrong open={errorDialog} setOpen={setErrorDialog} /> */}
            {/* <Backdrops open={onDownloadLoading} title={"Downloading"} /> */}
            <Box component="div" className={"BBPTransaction"}>
                <Box component="div" className={"BBPTHead"}>
                    <Box component="div" className="BBPSearchMain">
                    </Box>
                    {/* <SearchBox
                    onSearchChange={handleSearch}
                    placeholder={"Search Transaction"}

                /> */}

                    

                    <Box component="div" className="BBPSearchMain">
                        <Box
                            component="div"
                            className="BBPSelectDiv"
                            style={{ width: "14rem" }}
                        >
                            <select
                                className="BBPSBInput"
                                value={selectTxnStatus}
                                onChange={onChangeTxnStatus}
                            >
                                <option value="">Transaction Status</option>
                                <option value="TS">Success</option>
                                <option value="TF">Pending </option>
                                <option value="TI">Failed  </option>

                            </select>
                        </Box>
                    </Box>

                    {/* <Box component="div" className="BBPSearchMain">
                        <Box
                            component="div"
                            className="BBPSelectDiv"
                            style={{ width: "14rem" }}
                        >
                            <select
                                className="BBPSBInput"
                                value={selectedUrl}
                                onChange={onChangeApiUrl}
                                disabled={!selectMerchant}
                            >
                                <option >Beneficiary Status</option>
                                <option value={"Beneficiary"}>Approved Beneficiary</option>
                                <option value={"PendingBeneficiary"}>Pending Beneficiary</option>

                            </select>
                        </Box>
                    </Box> */}


                    <Box
                        component="div"
                        className={"BBPTHBtn"}
                        style={{ alignItems: "center" }}
                    >
                        <AutorenewOutlinedIcon
                            style={{ color: "#175783" }}
                            onClick={handleRefresh}
                        />
                        <p id="blueP" style={{ margin: "0px 10px" }}>
                            Data as of {selectedDate}{" "}
                        </p>
                        <Box className="calenderDiv">
                            <DatePicker
                                ref={datePickerRef}
                                selected={selectedDate}
                                onChange={handleDateRangeChange}
                                dateFormat="dd/MM/yyyy"
                                className="calenderIcon"
                                popperPlacement="top-start"
                            />

                        </Box>
                        <img
                            src={DateIcon}
                            className="imgDateIcon"
                            onClick={handleImageClick}
                        // style={{paddingRight:"10px"}}
                        />



                        {/* <DateRangePicker
                        buttonTitle={"Period"}
                        ranges={[selectedRange]}
                        dataStartDate={new Date('2024-09-01')} // Example start date
                        title="Select Range"
                        onChange={handleDateRangeChange}
                        onReset={handleDateRangeReset}
                    /> */}

                        {/* <Button
                        variant="contained"
                        className={"BBPTHBD"}
                        onClick={handleCSVDownload}
                        disabled={loading || totalData === 0}
                    >
                        Download
                    </Button> */}
                    </Box>




                </Box>
                <Box component="div" className={"BBPTBody"}>
                    <DevTable
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        selectedPage={Number(selectedPage) + 1}
                        handlePagination={handlePaginate}
                        loading={loading}
                        // handleSort={handleSorting}
                        limitData={Number(limitData)}
                        totalData={totalData}
                    />
                </Box>
            </Box>
        </Fragment>

    )
}

export default AllTransations