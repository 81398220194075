import React, { Fragment, useRef, useState } from 'react'
import DevTable from '../../Components/DevTable'
import { Box, Button } from '@mui/material'
import { useAxiosPrivate } from '../../Hooks/useAxiosPrivate';
import SearchBox from '../../Components/SearchBox';
import Backdrops from '../../Components/Backdrops';
import fileIcon from "../../Assets/Images/uploadIcon.png";
import toast from 'react-hot-toast';
import DateRangePicker from "../../Components/DateRangePicker/index"
import PaymentLinkDialog from './paymentLinkDialog';
const PaymentLinks = () => {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalData, setTotalData] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [limitData] = useState(20);
    const handlePaginate = (value) => {

    }

    const handleSearch = () => {

    }
    const axiosPrivate = useAxiosPrivate();
    const [onDownloadLoading, setOnDownloadLoading] = useState(false);
    const [mainColumns, setMainColumns] = useState({
        columns: [
            {
                name: "date",
                title: "Beneficiary Name",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "partnerId",
                title: "Account Number",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "merchantName",
                title: "IFSC Code",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "vpa",
                title: " VPA",
                width: 50,
                sorting: false,
                direction: "",
            },
            {
                name: "cycle",
                title: "Type",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "timeoutCount",
                title: "Mobile Number",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "timeoutVolume",
                title: "Amount",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "successCount",
                title: "Charge",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "successVolume",
                title: "Txn Ref No",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "totalCount",
                title: "Txn Type ",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "totalVolume",
                title: "Status",
                width: 150,
                sorting: false,
                direction: "",
            },
            {
                name: "charges",
                title: "Action",
                width: 150,
                sorting: false,
                direction: "",
            },
        ],
    });
    function generateRows(tempArray) {
        const tempRowArray = [];
        if (tempArray) {
            tempArray.map((item) =>
                tempRowArray.push({
                    date: (
                        <Box component="div" className="BBPDTSText">
                            {item.date}
                        </Box>
                    ),
                    partnerId: (
                        <Box component="div" className="BBPDTSText">
                            {item.partnerId}
                        </Box>
                    ),
                    vpa: (
                        <Box component="div" className="BBPDTSText">
                            {item.vpa}
                        </Box>
                    ),
                    merchantName: (
                        <Box component="div" className="BBPDTSText">
                            {item.merchantName}
                        </Box>
                    ),
                    cycle: (
                        <Box component="div" className="BBPDTSText">
                            {item.cycle}
                        </Box>
                    ),
                    timeoutCount: (
                        <Box component="div" className="BBPDTSText">
                            {item.timeoutCount.toLocaleString() || 0}
                        </Box>
                    ),
                    timeoutVolume: (
                        <Box component="div" className="BBPDTSText">
                            {item.timeoutVolume.toLocaleString() || 0}
                        </Box>
                    ),
                    successCount: (
                        <Box component="div" className="BBPDTSText">
                            {item.successCount.toLocaleString() || 0}
                        </Box>
                    ),
                    successVolume: (
                        <Box component="div" className="BBPDTSText">
                            {item.successVolume.toLocaleString() || 0}
                        </Box>
                    ),
                    totalCount: (
                        <Box component="div" className="BBPDTSText">
                            {item.totalCount.toLocaleString() || 0}
                        </Box>
                    ),
                    totalVolume: (
                        <Box component="div" className="BBPDTSText">
                            {item.totalVolume.toLocaleString() || 0}
                        </Box>
                    ),
                    charges: (
                        <Box component="div" className="BBPDTSText">
                            {item.charges.toLocaleString() || 0}
                        </Box>
                    ),
                    chargeBack: (
                        <Box component="div" className="BBPDTSText">
                            {item.chargeBack.toLocaleString() || 0}
                        </Box>
                    ),
                    previousDayCreditAdjustment: (
                        <Box component="div" className="BBPDTSText">
                            {item.previousDayCreditAdjustment.toLocaleString() || 0}
                        </Box>
                    ),
                    bankTransfer: (
                        <Box component="div" className="BBPDTSText">
                            {item.bankTransfer.toLocaleString() || 0}
                        </Box>
                    ),
                    netSettlement: (
                        <Box component="div" className="BBPDTSText">
                            {item.netSettlement.toLocaleString() || 0}
                        </Box>
                    ),
                    fundReleased: (
                        <Box component="div" className="BBPDTSText">
                            {item.fundReleased.toLocaleString() || 0}
                        </Box>
                    ),
                    cutOff: (
                        <Box component="div" className="BBPDTSText">
                            {item.cutOff.toLocaleString() || 0}
                        </Box>
                    ),
                    difference: (
                        <Box component="div" className="BBPDTSText">
                            {item.difference.toLocaleString() || 0}
                        </Box>
                    ),
                    remark: (
                        <Box component="div" className="BBPDTSText">
                            {item.remark}
                        </Box>
                    ),
                    utr: (
                        <Box component="div" className="BBPDTSText">
                            {item.utr}
                        </Box>
                    ),
                })
            );
        }
        return tempRowArray;
    }

    const [openPaymentLinkDialog, setPaymentDialog] = useState(false);
    const handleDialogOpen = () => {
        setPaymentDialog(true)
    }
    const handleCloseDialog = () => {
        setPaymentDialog(false)
    }

    return (
        <Fragment>
            <Backdrops open={onDownloadLoading} title={"Downloading"} />
            <PaymentLinkDialog open={openPaymentLinkDialog} onClose={handleCloseDialog} />
            <Box component="div" className={"BBPTransaction"}>
                <Box component="div" className={"BBPTHead"}>
                    <Box component="div" className="BBPSearchMain">
                    </Box>
                    <SearchBox
                        onSearchChange={handleSearch}
                        placeholder={"Search Transaction"}

                    />

                    <Box
                        component="div"
                        className={"BBPTHBtn"}
                        style={{ alignItems: "center" }}
                    >

                        <Box className="calenderDiv">

                        </Box>
                        {/* <img
                            src={DateIcon}
                            className="imgDateIcon"
                            onClick={handleImageClick}
                        /> */}


                        <Button
                            variant="contained"
                            className={"BBPTHBD"}
                        >
                            BULK UPLOAD
                        </Button>
                        <Button
                            variant="contained"
                            className={"BBPTHBD"}
                            onClick={handleDialogOpen}
                        >
                            CREATE PAYMENT LINK
                        </Button>


                    </Box>
                </Box>
                <Box component="div" className={"BBPTBody"}>
                    <DevTable
                        rows={generateRows(rowData)}
                        columns={mainColumns.columns}
                        selectedPage={selectedPage}
                        handlePagination={handlePaginate}
                        loading={loading}
                        // handleSort={handleSorting}
                        limitData={limitData}
                        totalData={totalData}
                    />
                </Box>
            </Box>
        </Fragment>
    )
}

export default PaymentLinks