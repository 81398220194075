import React, { useState, Fragment } from 'react';
import Box from '@mui/material/Box';
import BackupIcon from '@mui/icons-material/Backup';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import Link from '@mui/material/Link';
import * as XLSX from 'xlsx';
import './style.css';
import DeleteIcon from '@mui/icons-material/Delete';

const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = 'red';

const Step1 = ({ XLSXFile, jsonHeaderError, uploadFile, handleValidJson, setFileProgress, setUploadFile }) => {
    const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);

    // Handle file upload and parse XLSX
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {

            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            console.log(workbook)
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            console.log(json)
            handleValidJson({ data: json });
            setUploadFile(file);
            setFileProgress(false);
        };
        reader.readAsArrayBuffer(file);
    };

    return (
        <Box component="div" className={'BBPUCStep1'}>
            <Box component="div" className={'BBPUCSInner'}>
                <Box component="div" className={'BBPUCSHead'}>
                    <Box component="div" className={'BBPUCSHTitle'}>
                        Choose a file
                    </Box>
                    <Box component="div" className={'BBPUCSHDes'}>
                        Our system checks the values of each row and if any issues found you can easily edit it by clicking on the cell.
                    </Box>
                </Box>
                <Box component="div" className={'BBPUCSBody'}>
                    <Box
                        component="div"
                        className={`BBPUCDIUpload ${jsonHeaderError.success ? 'BBPUCDIUSuc' : ''} ${jsonHeaderError.err ? 'BBPUCDIUError' : ''}`}
                    >
                        {uploadFile ? (
                            <Fragment>
                                <Box component="div" className={'BBPUCDIFile'}>
                                    <Box component="div" className={'BBPUCDIFInfo'}>
                                        <Box component="div" className={'BBPUCDIFIName'}>{uploadFile.name}</Box>
                                        <Box component="div" className={'BBPUCDIFISize'}>
                                            ({uploadFile.size})
                                        </Box>
                                    </Box>
                                    <Box
                                        component="div"
                                        className={'BBPUCDIRemove'}
                                        onClick={() => {
                                            setFileProgress(true);
                                            handleValidJson({});
                                            setUploadFile();
                                        }}
                                        onMouseOver={(event) => {
                                            event.preventDefault();
                                            setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                                        }}
                                        onMouseOut={(event) => {
                                            event.preventDefault();
                                            setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                                        }}
                                    >
                                        <DeleteIcon color={removeHoverColor} />
                                    </Box>
                                </Box>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <input
                                    type="file"
                                    accept=".xlsx"
                                    onChange={handleFileUpload}
                                    style={{ display: 'none' }}
                                    id="fileUpload"
                                />
                                <label htmlFor="fileUpload" className={'BBPUCDIUploadLabel'}>
                                    <Box component="div" className={'BBPUCDIUIcon'}>
                                        <BackupIcon fontSize="inherit" />
                                    </Box>
                                    <Box component="div" className={'BBPUCDIUTitle'}>
                                        Drag and drop or choose a file to upload your
                                    </Box>
                                </label>
                            </Fragment>
                        )}
                    </Box>
                </Box>
                <Box component="div" className={'BBPUCSLink'}>
                    <Link href={XLSXFile} download>download demo xlsx</Link>
                </Box>
            </Box>
            {jsonHeaderError.err ?
                <Box component="div" className={'BBPUCSError'}>
                    <Box component="div" className={'BBPUCSEIcon'}>
                        <WarningTwoToneIcon fontSize="inherit" />
                    </Box>
                    <Box component="div" className={'BBPUCSEInfo'}>
                        <Box component="div" className={'BBPUCSEITitle'}>
                            Please check your column header. The first row must contain title.
                        </Box>
                        <Box component="div" className={'BBPUCSEIColums'}>
                            {jsonHeaderError.arr.map((item, index) => `${index > 0 ? ',' : ''} ${item}`)} can same or not be blank.
                        </Box>
                    </Box>
                </Box>
                : ''}
        </Box>
    );
};
export default Step1;
