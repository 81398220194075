import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import RightSidePanel from "../../Components/RightSidePanel";
import Overview from "./overview";
import LineChart from "./lineChart";
import InfoBox from "./infoBox";
import { CheckPermissions } from "../../Utils/permissions";

import "./style.css";

const UserDashboard = ({ type }) => {
  return (
    <Fragment>
      {/* <Overview /> */}
      <InfoBox type={type} />
      <LineChart
        apiName={"dashboard/totaltransactions"}
        title={"Total Transactions (Amount & No of Txn)"}
        name1={"No of Txn"}
        name2={"Amount"}
      />
    </Fragment>
  );
};

const DashboardPage = () => {
  return (
    <Fragment>
      <RightSidePanel removeBg>
        <Box component="div" className={"BBPDashboardPage"}>

          {/* {CheckPermissions("dashboard_permissions", "User") ? (
            <UserDashboard type={"User"} />
          ) : (
            <InfoBox type={"Merchant"} />
          )} */}
          {/* <UserDashboard type={"User"} /> */}
           <InfoBox type={"Merchant"}/>
        </Box>
      </RightSidePanel>
    </Fragment>
  );
};

export default DashboardPage;
